import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, CircularProgress, Select, MenuItem, Modal, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { ComposedChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { fetchEarnings } from '../api';
import { useStock } from '../StockContext';
const getTimeRangeLimit = (range) => {
    switch (range) {
        case '1y': return 4;
        case '3y': return 12;
        case '5y': return 20;
        default: return 12;
    }
};
const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
};


const EarningsComponent = ({ symbol }) => {
    const [earningsData, setEarningsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [timeRange, setTimeRange] = useState('3y');
    const [modalData, setModalData] = useState(null);
    const [metricType, setMetricType] = useState('eps');
    const { showError } = useStock();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchEarnings(symbol);
                const validData = data.filter(earning =>
                    (earning.eps != null || earning.eps_estimated != null) ||
                    (earning.revenue != null || earning.revenue_estimated != null)
                );
                const sortedData = validData
                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                    .slice(0, getTimeRangeLimit(timeRange));

                const processedData = sortedData.map(earning => ({
                    date: formatDate(earning.date),
                    actualEPS: earning.eps !== null ? earning.eps : undefined,
                    estimatedEPS: earning.eps_estimated,
                    actualRevenue: earning.revenue !== null ? earning.revenue / 1e9 : undefined,
                    estimatedRevenue: earning.revenue_estimated ? earning.revenue_estimated / 1e9 : undefined,
                    epsSurprise: earning.eps && earning.eps_estimated
                        ? ((earning.eps - earning.eps_estimated) / earning.eps_estimated * 100).toFixed(2)
                        : null,
                    revenueSurprise: earning.revenue && earning.revenue_estimated
                        ? ((earning.revenue - earning.revenue_estimated) / earning.revenue_estimated * 100).toFixed(2)
                        : null
                })).reverse();
                console.log(processedData)
                setEarningsData(processedData);
                setLoading(false);
            } catch (error) {
                console.error('Failed to fetch earnings data:', error);
                showError(`Failed to fetch data: ${error.message}`);
                setLoading(false);
            }
        };

        fetchData();
    }, [symbol, timeRange]);

    if (loading) {
        return <CircularProgress />;
    }





    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                    <p><strong>{label}</strong></p>
                    {metricType === 'eps' ? (
                        <>
                            <p>EPS: ${data.actualEPS || 'N/A'} (Est: ${data.estimatedEPS || 'N/A'})</p>
                            <p>EPS Surprise: {data.epsSurprise || 'N/A'}%</p>
                        </>
                    ) : (
                        <>
                            <p>Revenue: ${data.actualRevenue?.toFixed(2) || 'N/A'}B (Est: ${data.estimatedRevenue?.toFixed(2) || 'N/A'}B)</p>
                            <p>Revenue Surprise: {data.revenueSurprise || 'N/A'}%</p>
                        </>
                    )}
                </div>
            );
        }
        return null;
    };

    const handleBarClick = (data) => {
        setModalData(data);
    };

    const calculateAvgSurprise = () => {
        const surprises = earningsData
            .filter(data =>
                metricType === 'eps'
                    ? (data.actualEPS != null && data.estimatedEPS != null)
                    : (data.actualRevenue != null && data.estimatedRevenue != null)
            )
            .map(data =>
                metricType === 'eps'
                    ? ((data.actualEPS - data.estimatedEPS) / data.estimatedEPS * 100)
                    : ((data.actualRevenue - data.estimatedRevenue) / data.estimatedRevenue * 100)
            );

        return surprises.length > 0
            ? (surprises.reduce((sum, surprise) => sum + surprise, 0) / surprises.length).toFixed(2) + '%'
            : 'N/A';
    };

    const calculateMedianSurprise = () => {
        const surprises = earningsData
            .filter(data =>
                metricType === 'eps'
                    ? (data.actualEPS != null && data.estimatedEPS != null)
                    : (data.actualRevenue != null && data.estimatedRevenue != null)
            )
            .map(data =>
                metricType === 'eps'
                    ? ((data.actualEPS - data.estimatedEPS) / data.estimatedEPS * 100)
                    : ((data.actualRevenue - data.estimatedRevenue) / data.estimatedRevenue * 100)
            )
            .sort((a, b) => a - b);

        if (surprises.length === 0) return 'N/A';

        const mid = Math.floor(surprises.length / 2);
        const median = surprises.length % 2 !== 0
            ? surprises[mid]
            : (surprises[mid - 1] + surprises[mid]) / 2;

        return median.toFixed(2) + '%';
    };

    const calculateBeatExpectations = () => {
        const validData = earningsData.filter(data =>
            metricType === 'eps'
                ? (data.actualEPS != null && data.estimatedEPS != null)
                : (data.actualRevenue != null && data.estimatedRevenue != null)
        );

        const beats = validData.filter(data =>
            metricType === 'eps'
                ? data.actualEPS > data.estimatedEPS
                : data.actualRevenue > data.estimatedRevenue
        ).length;

        return validData.length > 0 ? `${beats}/${validData.length}` : 'N/A';
    };

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Select
                    value={timeRange}
                    onChange={(e) => setTimeRange(e.target.value)}
                >
                    <MenuItem value="1y">Last 1 Year</MenuItem>
                    <MenuItem value="3y">Last 3 Years</MenuItem>
                    <MenuItem value="5y">Last 5 Years</MenuItem>
                </Select>
                <ToggleButtonGroup
                    value={metricType}
                    exclusive
                    onChange={(e, newValue) => newValue && setMetricType(newValue)}
                >
                    <ToggleButton value="eps">EPS</ToggleButton>
                    <ToggleButton value="revenue">Revenue</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <Card>
                <CardContent>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart data={earningsData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="date" />
                            <YAxis
                                label={{ value: metricType === 'eps' ? 'EPS ($)' : 'Revenue (Billion $)', angle: -90, position: 'insideLeft' }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Bar
                                dataKey={metricType === 'eps' ? 'actualEPS' : 'actualRevenue'}
                                name={metricType === 'eps' ? 'Actual EPS' : 'Actual Revenue'}
                                fill="#8884d8"
                                onClick={handleBarClick}
                            />
                            <Line
                                dataKey={metricType === 'eps' ? 'estimatedEPS' : 'estimatedRevenue'}
                                name={metricType === 'eps' ? 'Estimated EPS' : 'Estimated Revenue'}
                                stroke="#82ca9d"
                            />
                            <ReferenceLine y={0} stroke="#000" />
                        </ComposedChart>
                    </ResponsiveContainer>
                </CardContent>
            </Card>

            <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Performance Metrics</Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                <Card sx={{ minWidth: 200, mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">Avg {metricType.toUpperCase()} Surprise</Typography>
                        <Typography variant="h4">
                            {calculateAvgSurprise()}
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ minWidth: 200, mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">Median {metricType.toUpperCase()} Surprise</Typography>
                        <Typography variant="h4">
                            {calculateMedianSurprise()}
                        </Typography>
                    </CardContent>
                </Card>
                <Card sx={{ minWidth: 200, mb: 2 }}>
                    <CardContent>
                        <Typography variant="h6">Beat Expectations</Typography>
                        <Typography variant="h4">
                            {calculateBeatExpectations()}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>

            <Modal
                open={!!modalData}
                onClose={() => setModalData(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    border: '2px solid #000',
                    boxShadow: 24,
                    p: 4,
                }}>
                    {modalData && (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Earnings Details for {modalData.date}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                <p>EPS: ${modalData.actualEPS || 'N/A'} (Estimated: ${modalData.estimatedEPS || 'N/A'})</p>
                                <p>EPS Surprise: {modalData.epsSurprise || 'N/A'}%</p>
                                <p>Revenue: ${modalData.actualRevenue?.toFixed(2) || 'N/A'}B (Estimated: ${modalData.estimatedRevenue?.toFixed(2) || 'N/A'}B)</p>
                                <p>Revenue Surprise: {modalData.revenueSurprise || 'N/A'}%</p>
                            </Typography>
                        </>
                    )}
                </Box>
            </Modal>
        </Box>
    );
};

export default EarningsComponent;