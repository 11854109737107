import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { supabase } from './supabaseClient';
import { Card, CardContent, Typography, Grid, IconButton, Box, Alert, Button, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const WatchlistComponent = () => {
    const [watchlist, setWatchlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user, isAuthenticated, showError } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            fetchWatchlist();
        } else {
            setIsLoading(false);
        }
    }, [isAuthenticated]);

    const fetchWatchlist = async () => {
        setIsLoading(true);
        try {
            const { data, error } = await supabase
                .from('watchlist')
                .select('*')
                .eq('user_id', user.id);

            if (error) {
                if (error.code === 'PGRST116') {
                    // 결과가 없는 경우
                    setWatchlist([]);
                } else {
                    // 다른 종류의 에러
                    throw error;
                }
            } else {
                setWatchlist(data);
            }
        } catch (error) {
            showError(`Failed to fetch data: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const removeFromWatchlist = async (symbol) => {
        try {
            const { error } = await supabase
                .from('watchlist')
                .delete()
                .eq('user_id', user.id)
                .eq('symbol', symbol);

            if (error) {
                throw error;
            }

            setWatchlist(watchlist.filter(item => item.symbol !== symbol));
        } catch (error) {
            showError(`Failed to fetch data: ${error.message}`);
        }
    };

    const handleCardClick = (symbol) => {
        navigate(`/stock/${symbol}`);
    };

    if (!isAuthenticated) {
        return (
            <Box sx={{ p: 3 }}>
                <Alert severity="info">
                    Please log in or sign up to view your watchlist.
                </Alert>
                <Box sx={{ mt: 2 }}>
                    <Button variant="contained" onClick={() => navigate('/login')} sx={{ mr: 1 }}>
                        Log In
                    </Button>
                    <Button variant="outlined" onClick={() => navigate('/signup')}>
                        Sign Up
                    </Button>
                </Box>
            </Box>
        );
    }

    if (isLoading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300vh' }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Box sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
                Your Watchlist
            </Typography>
            <Grid container spacing={3}>
                {watchlist.map((item) => (
                    <Grid item xs={12} sm={6} md={4} key={item.symbol}>
                        <Card
                            sx={{
                                cursor: 'pointer',
                                '&:hover': { boxShadow: 6 }
                            }}
                            onClick={() => handleCardClick(item.symbol)}
                        >
                            <CardContent>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <Typography variant="h6">
                                        {item.symbol}
                                    </Typography>
                                    <IconButton
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeFromWatchlist(item.symbol);
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                                {/* 여기에 추가적인 주식 정보를 표시할 수 있습니다 */}
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
            {watchlist.length === 0 && (
                <Typography variant="body1" sx={{ mt: 2 }}>
                    Your watchlist is empty. Add some stocks to get started!
                </Typography>
            )}
        </Box>
    );
};

export default WatchlistComponent;