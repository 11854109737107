import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Legend } from 'chart.js';
import { Typography, Box, Grid, Card, CardActionArea, CardContent, Chip } from '@mui/material';
import EarningCallModal from './EarningCallModal';
import { fetchPhraseTrands } from '../api';
import { useTranslation } from 'react-i18next';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useStock } from '../StockContext';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Legend);

const KeywordTrendsChart = ({ symbol, phrase, minYear }) => {
    const { t } = useTranslation();
    const [chartData, setChartData] = useState(null);
    const [selectedChunkIds, setSelectedChunkIds] = useState([]);
    const { setIsEarningCallOpen, showError } = useStock();
    const [selectedChunkId, setSelectedChunkId] = useState(null);
    const [selectedPointChunks, setSelectedPointChunks] = useState([]);
    const [selectedPointDate, setSelectedPointDate] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchPhraseTrands(symbol, phrase, minYear)

                const labels = data.map(item => `${item.year} Q${item.quarter}`);
                const frequencies = data.map(item => item.frequency);
                const chunkIds = data.map(item => item.chunk_ids.split(',').filter(id => id !== ''));

                setChartData({
                    labels,
                    datasets: [
                        {
                            label: phrase,
                            data: frequencies,
                            borderColor: 'rgb(75, 192, 192)',
                            tension: 0.1
                        }
                    ]
                });
                setSelectedChunkIds(chunkIds);
            } catch (error) {
                console.error('Failed to fetch keyword trends:', error);
                showError(`Failed to fetch data: ${error.message}`);
            }
        };

        fetchData();
    }, [symbol, phrase, minYear]);

    const handlePointClick = (event, elements) => {
        if (elements.length > 0) {
            const dataIndex = elements[0].index;
            const chunkIds = selectedChunkIds[dataIndex];
            setSelectedPointChunks(chunkIds);
            setSelectedPointDate(chartData.labels[dataIndex]);
        }
    };

    const handleChunkClick = (chunkId) => {
        setSelectedChunkId(chunkId);
        setIsEarningCallOpen(true);
    };



    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Keyword Trends',
            },
            tooltip: {
                enabled: false, // 호버링 시 툴팁 비활성화
            },
        },
        onClick: handlePointClick,
    };

    if (!chartData) return <div>Loading...</div>;

    return (
        <Box>
            <Line data={chartData} options={options} />
            <Typography variant="body2" mt={2}>
                Click on a point to view related earning calls.
            </Typography>
            {selectedPointChunks.length > 0 && (
                <Box mt={4}>
                    <Grid container spacing={2}>
                        {selectedPointChunks.length > 0 && (
                            <Box mt={4}>
                                <Typography variant="h6" mb={2} color="primary">
                                    {t("Related Earning Calls")}
                                </Typography>
                                <Grid container spacing={2}>
                                    {selectedPointChunks.map((chunkId, index) => (
                                        <Grid item xs={12} sm={6} md={4} key={chunkId}>
                                            <Card
                                                elevation={3}
                                                sx={{
                                                    transition: '0.3s',
                                                    '&:hover': {
                                                        transform: 'translateY(-5px)',
                                                        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                                                    },
                                                }}
                                            >
                                                <CardActionArea onClick={() => handleChunkClick(chunkId)}>
                                                    <CardContent>
                                                        <Typography variant="h6" component="div" gutterBottom>
                                                            {t("Earning Call")} {index + 1}
                                                        </Typography>
                                                        <Chip
                                                            icon={<CalendarTodayIcon />}
                                                            label={selectedPointDate}
                                                            color="primary"
                                                            variant="outlined"
                                                            size="small"
                                                        />
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                        )}
                    </Grid>
                </Box>
            )}
            <EarningCallModal
                chunkId={selectedChunkId} target_text={phrase}
            />
        </Box>
    );
};

export default KeywordTrendsChart;