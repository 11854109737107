import React, { useState, useEffect } from 'react';
import { fetchTagStastic } from './api';
import { Table, Typography, Tabs, Button, Row, Col } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useStock } from './StockContext';
import GeneralNewsCloud from './components/GeneralNewsCloud';
import LiveNewsComponent from './components/LiveNewsComponent';
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const filterExtremeOutliers = (data, key) => {
    return data.filter(item => Math.abs(item[key]) <= 10000);
};

const IdeaDiscoveryComponent = () => {
    const { t } = useTranslation();
    const [data, setData] = useState([]);
    const { searchMode, handleSearch, showError } = useStock();
    const [isLoading, setIsLoading] = useState(true);
    const [currentTab, setCurrentTab] = useState('1d_avg');

    useEffect(() => {
        fetchData();
    }, []);

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const data = await fetchTagStastic()
            const processedData = data.map(item => ({
                ...item,
                query_filter: capitalizeWords(item.query_filter)
            }));
            setData(processedData);
        } catch (error) {
            showError(`Error fetching Idea Discovery data: ${error.message}`);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckDetails = (item) => {
        const query = item.query_filter;
        handleSearch(searchMode, query)
    };

    const getStatColor = (value) => {
        if (value > 0) return '#3f8600';
        if (value < 0) return '#cf1322';
        return '#000000';
    };

    const renderTrendValue = (value) => (
        <span style={{ color: getStatColor(value) }}>
            {value > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
            {value.toFixed(2)}%
        </span>
    );

    const columns = (sortKey) => [
        {
            title: t('Rank'),
            key: 'rank',
            render: (_, __, index) => index + 1,
        },
        {
            title: t('Investment Idea'),
            dataIndex: 'query_filter',
            key: 'query_filter',
        },
        {
            title: t('Related Stock Count'),
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: t('Period Return'),
            dataIndex: sortKey,
            key: 'yield',
            render: renderTrendValue,
        },
        {
            title: t('Action'),
            key: 'action',
            render: (_, record) => (
                <Button onClick={() => handleCheckDetails(record)}>
                    {t('Check Details')}
                </Button>
            ),
        },
    ];

    const renderTable = (sortKey) => {
        const filteredData = filterExtremeOutliers(data, sortKey);
        const sortedData = [...filteredData].sort((a, b) => b[sortKey] - a[sortKey]);
        return (
            <Table
                dataSource={sortedData}
                columns={columns(sortKey)}
                rowKey="query_filter"
                pagination={{
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100'],
                }}
                loading={isLoading}
            />
        );
    };

    return (
        <div style={{ padding: '20px' }}>
            <LiveNewsComponent />

            <Title level={4} style={{ margin: 0 }}>{t("Today's Hot News Trend")}</Title>
            <GeneralNewsCloud />
            <Row justify="space-between" align="middle">
                <Col>
                    <Title level={4} style={{ margin: 0 }}>{t("Investment Idea Scoreboard")}</Title>
                </Col>
                <Col>
                    <Text strong>{t("Total Ideas")}: {data.length}</Text>
                </Col>
            </Row>

            <Tabs
                defaultActiveKey="1d_avg"
                onChange={(key) => setCurrentTab(key)}
            >
                <TabPane tab={t("1 Day Return")} key="1d_avg">
                    {renderTable('1d_avg')}
                </TabPane>
                <TabPane tab={t("5 Days Return")} key="5d_avg">
                    {renderTable('5d_avg')}
                </TabPane>
                <TabPane tab={t("1 Month Return")} key="1m_avg">
                    {renderTable('1m_avg')}
                </TabPane>
                <TabPane tab={t("3 Months Return")} key="3m_avg">
                    {renderTable('3m_avg')}
                </TabPane>
                <TabPane tab={t("6 Months Return")} key="6m_avg">
                    {renderTable('6m_avg')}
                </TabPane>
                <TabPane tab={t("1 Year Return")} key="1y_avg">
                    {renderTable('1y_avg')}
                </TabPane>
                <TabPane tab={t("3 Years Return")} key="3y_avg">
                    {renderTable('3y_avg')}
                </TabPane>
            </Tabs>
        </div>
    );
};

export default React.memo(IdeaDiscoveryComponent);