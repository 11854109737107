import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, IconButton, Box, Typography } from '@mui/material';
import axios from 'axios';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import { styled } from '@mui/material/styles';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Translation, useTranslation } from 'react-i18next';
const LockedCell = styled(TableCell)(({ theme }) => ({
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    color: 'rgba(0, 0, 0, 0.5)',
    position: 'relative',
    textAlign: 'center',
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    },
}));


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    '&.MuiTableCell-head': {
        backgroundColor: theme.palette.grey[200],
        fontWeight: 'bold',
    },
    '&.MuiTableCell-body': {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const serverUrl = process.env.REACT_APP_SERVER_URL


// const financialStructure = {
//     // Balance Sheet
//     totalAssets: ['totalCurrentAssets', 'totalNonCurrentAssets'],
//     totalCurrentAssets: ['cashAndCashEquivalents', 'shortTermInvestments', 'netReceivables', 'inventory', 'otherCurrentAssets'],
//     totalNonCurrentAssets: ['propertyPlantEquipmentNet', 'goodwill', 'intangibleAssets', 'longTermInvestments', "taxAssets", 'otherNonCurrentAssets'],

//     totalLiabilities: ['totalCurrentLiabilities', 'totalNonCurrentLiabilities'],
//     totalCurrentLiabilities: ['accountPayables', 'shortTermDebt', 'taxPayables', 'deferredRevenue', 'otherCurrentLiabilities'],
//     totalNonCurrentLiabilities: ['longTermDebt', "deferredRevenueNonCurrent", 'deferredTaxLiabilitiesNonCurrent', 'otherNonCurrentLiabilities'],

//     totalEquity: ['commonStock', 'retainedEarnings', 'accumulatedOtherComprehensiveIncomeLoss', 'othertotalStockholdersEquity'],

//     totalLiabilitiesAndEquity: ['totalLiabilities', 'totalEquity'],

//     // Income Statement
//     operatingExpenses: ['researchAndDevelopmentExpenses', 'sellingGeneralAndAdministrativeExpenses', 'otherExpenses'],
//     sellingGeneralAndAdministrativeExpenses: ["generalAndAdministrativeExpenses", "sellingAndMarketingExpenses"],
//     //incomeBeforeTax: ['operatingIncome', 'totalOtherIncomeExpensesNet'],
//     totalOtherIncomeExpensesNet: ['interestIncome', 'interestExpense'],
//     costAndExpenses: ['costOfRevenue', 'operatingExpenses'],

//     // Cash Flow Statement
//     netCashProvidedByOperatingActivities: ['netIncome', 'depreciationAndAmortization', 'deferredIncomeTax', 'stockBasedCompensation', 'accountsReceivables', 'inventory', 'accountsPayables', 'otherWorkingCapital', 'otherNonCashItems'],
//     netCashUsedForInvestingActivites: ['investmentsInPropertyPlantAndEquipment', 'acquisitionsNet', 'purchasesOfInvestments', 'salesMaturitiesOfInvestments', "otherInvestingActivites"],
//     netCashUsedProvidedByFinancingActivities: ['debtRepayment', 'commonStockIssued', 'commonStockRepurchased', 'dividendsPaid', 'otherFinancingCashFlow'],
//     netChangeInCash: ['operatingCashFlow', 'netCashUsedForInvestingActivites', 'netCashUsedProvidedByFinancingActivities', 'effectOfForexChangesOnCash'],

//     // Additional metrics
//     ebitda: ['operatingIncome', 'depreciationAndAmortization'],
//     freeCashFlow: ['operatingCashFlow', 'capitalExpenditure'],

//     // Ratios (these don't have children, but are calculated from other items)
//     currentRatio: [],
//     quickRatio: [],
//     debtToEquityRatio: [],
//     returnOnEquity: [],
//     returnOnAssets: [],
//     grossMargin: [],
//     operatingMargin: [],
//     netProfitMargin: [],
//     earningsPerShare: [],
//     priceToEarningsRatio: [],
// };

const financialStructure = {
    // Balance Sheet
    total_assets: ['current_assets', 'total_non_current_assets'],
    current_assets: ['cash_cash_equivalents_and_short_term_investments', 'cash_and_cash_equivalents', 'net_receivables', 'inventory', 'other_current_assets'],
    total_non_current_assets: ['gross_ppe', 'long_term_investments', 'net_tangible_assets'],

    total_liabilities_net_minority_interest: ['current_liabilities', 'total_non_current_liabilities_net_minority_interest'],
    //current_liabilities: ['payables_and_accrued_expenses', 'payables', 'short_term_debt'],
    //total_non_current_liabilities_net_minority_interest: ['long_term_debt_and_capital_lease_obligation', 'capital_lease_obligations'],

    stockholders_equity: ['common_stock', 'retained_earnings', 'additional_paid_in_capital'],

    //total_capitalization: ['total_debt', 'total_equity_gross_minority_interest'],

    // Income Statement
    operating_expense: ['research_and_development', 'selling_general_and_administration', 'other_operating_expenses'],
    selling_general_and_administration: ['selling_and_marketing_expense', 'general_and_administrative_expense'],
    //operating_income: ['total_operating_income_as_reported'],
    net_non_operating_interest_income_expense: ['interest_income', 'interest_expense'],
    //pretax_income: ['operating_income', 'other_income_expense'],
    //net_income: ['net_income_from_continuing_operation_net_minority_interest', 'net_income_from_continuing_and_discontinued_operation'],

    // Cash Flow Statement
    //operating_cash_flow: ['cash_flow_from_continuing_operating_activities', 'change_in_working_capital'],
    //change_in_working_capital: ['change_in_payables_and_accrued_expense', 'change_in_payable', 'change_in_account_payable', 'change_in_receivables', 'changes_in_account_receivables'],
    //investing_cash_flow: ['cash_flow_from_continuing_investing_activities', 'net_investment_purchase_and_sale', 'purchase_of_ppe', 'net_ppe_purchase_and_sale'],
    //financing_cash_flow: ['cash_flow_from_continuing_financing_activities', 'repayment_of_debt', 'issuance_of_debt', 'net_issuance_payments_of_debt', 'net_long_term_debt_issuance', 'long_term_debt_payments'],
    changes_in_cash: ['beginning_cash_position', 'end_cash_position'],

    // Additional metrics
    //ebitda: ['operating_income', 'depreciation_amortization_depletion'],
    free_cash_flow: ['operating_cash_flow', 'capital_expenditure'],
};

// const topLevelItems = {
//     balancesheet: ['totalAssets', 'totalLiabilities', 'totalEquity'],
//     income: ['revenue', 'costOfRevenue', 'grossProfit', 'operatingExpenses', 'operatingIncome', 'incomeBeforeTax', 'incomeTaxExpense', 'netIncome'],
//     cashflow: ['netCashProvidedByOperatingActivities', 'netCashUsedForInvestingActivites', 'netCashUsedProvidedByFinancingActivities', 'netChangeInCash', "freeCashFlow"]
// };


const topLevelItems = {
    'balance-sheet': ['total_assets', 'total_liabilities_net_minority_interest', 'stockholders_equity', 'total_capitalization', 'total_debt', 'working_capital', 'net_debt'],
    'income-statement': ['total_revenue', 'cost_of_revenue', 'gross_profit', 'operating_expense', 'operating_income', 'pretax_income', 'tax_provision', 'net_income', 'basic_eps', 'diluted_eps', 'ebit', 'ebitda'],
    'cash-flow': ['operating_cash_flow', 'change_in_working_capital', 'investing_cash_flow', 'financing_cash_flow', 'changes_in_cash', 'free_cash_flow']
};


const formatItemName = (name) => {
    return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const FinancialStatementRow = ({ item, data, dates, level = 0 }) => {
    const [expanded, setExpanded] = useState(false);
    const hasChildren = financialStructure[item];
    const formatValue = (value) => {
        if (value === null || value === undefined) return '-';
        return formatNumber(value);
    };
    const toggleExpand = () => setExpanded(!expanded);



    return (
        <>
            <StyledTableRow>
                <StyledTableCell>
                    <Box
                        display="flex"
                        alignItems="center"
                        pl={level * 2}
                        sx={{
                            '& .MuiIconButton-root': {
                                padding: '4px',
                            },
                        }}
                    >
                        {hasChildren ? (
                            <IconButton size="small" onClick={toggleExpand}>
                                {expanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                            </IconButton>
                        ) : (
                            <Box width={28} />
                        )}
                        <Typography
                            noWrap
                            sx={{
                                flexGrow: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {formatItemName(item)}
                        </Typography>
                    </Box>
                </StyledTableCell>
                {dates.map(date => (
                    <StyledTableCell key={date} align="right">{formatValue(data[date]?.[item])}</StyledTableCell>
                ))}

            </StyledTableRow>
            {expanded && hasChildren && financialStructure[item].map(childItem => (
                <FinancialStatementRow
                    key={childItem}
                    item={childItem}
                    data={data}
                    dates={dates}
                    level={level + 1}
                />
            ))}
        </>
    );
};


// utils.js
const formatNumber = (num) => {
    return new Intl.NumberFormat('en-US').format(num);
};




const FinancialStatement = ({ symbol, statementType }) => {
    console.log(symbol, statementType)
    // const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [period, setPeriod] = useState('annual');
    const dates = Object.keys(data).sort((a, b) => new Date(a) - new Date(b));
    const displayDates = [...dates];
    const { t } = useTranslation()

    const calculateDerivedMetrics = (data) => {
        const metrics = {};
        dates.forEach(date => {
            const yearData = data[date];
            switch (statementType) {
                case 'balance-sheet':
                    metrics[date] = {
                        current_ratio: yearData.current_assets / yearData.current_liabilities,
                        debt_to_equity_ratio: yearData.total_debt / yearData.stockholders_equity,
                        working_capital: yearData.current_assets - yearData.current_liabilities
                    };
                    break;
                case 'income-statement':
                    metrics[date] = {
                        gross_margin: (yearData.gross_profit / yearData.total_revenue),
                        operating_margin: (yearData.operating_income / yearData.total_revenue),
                        net_profit_margin: (yearData.net_income / yearData.total_revenue)
                    };
                    break;
                case 'cash-flow':
                    metrics[date] = {
                        free_cash_flow: yearData.operating_cash_flow - yearData.capital_expenditure,
                        cash_flow_coverage_ratio: yearData.operating_cash_flow / yearData.total_debt,
                        cash_flow_to_debt_ratio: yearData.operating_cash_flow / yearData.total_liabilities_net_minority_interest
                    };
                    break;
            }
        });
        return metrics;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${serverUrl}/api/finance/${statementType}`, {
                    params: { symbol, period }
                });
                // 데이터를 날짜별로 그룹화
                const groupedData = response.data.reduce((acc, item) => {
                    acc[item.date] = item;
                    return acc;
                }, {});
                setData(groupedData);
            } catch (error) {
                console.error(`Error fetching ${statementType} statement:`, error);

                throw error
            }
        };
        fetchData();
    }, [symbol, statementType, period]);

    const currency = data[dates[0]]?.reportedcurrency || '';
    // 최상위 항목 정의 (재무제표 유형에 따라 다름)

    const renderCharts = () => {
        const getChartConfig = () => {
            switch (statementType) {
                case 'balance-sheet':
                    return {
                        items: ['total_assets', 'total_liabilities_net_minority_interest', 'stockholders_equity'],
                        derivedItems: ['current_ratio', 'debt_to_equity_ratio', 'working_capital'],
                        title: t('Balance Sheet Overview')
                    };
                case 'income-statement':
                    return {
                        items: ['total_revenue', 'gross_profit', 'net_income'],
                        derivedItems: ['gross_margin', 'operating_margin', 'net_profit_margin'],
                        title: t("Income Statement Overview")
                    };
                case 'cash-flow':
                    return {
                        items: ['operating_cash_flow', 'investing_cash_flow', 'financing_cash_flow'],
                        derivedItems: ['free_cash_flow', 'cash_flow_coverage_ratio', 'cash_flow_to_debt_ratio'],
                        title: t('Cash Flow Statement Overview')
                    };
                default:
                    return { items: [], derivedItems: [], title: '' };
            }
        };

        const { items, derivedItems, title } = getChartConfig();
        const currency = data[dates[0]]?.reportedcurrency || '';
        const derivedMetrics = calculateDerivedMetrics(data);

        const CustomTooltip = ({ active, payload, label }) => {
            if (active && payload && payload.length) {
                const value = payload[0].value;
                const formattedValue = typeof value === 'number' ?
                    (value > 100 ? formatNumber(value) : value.toFixed(2)) : value;
                return (
                    <Paper elevation={3} sx={{ p: 1 }}>
                        <Typography variant="body2">{`Date: ${label}`}</Typography>
                        <Typography variant="body2">{`${formatItemName(payload[0].name)}: ${formattedValue}`}</Typography>
                        <Typography variant="caption">{`(Unit: ${payload[0].name.includes('ratio') || payload[0].name.includes('margin') ? '%' : `1,000 ${currency}`})`}</Typography>
                    </Paper>
                );
            }
            return null;
        };

        const renderChartRow = (chartItems, isderived = false) => {
            if (!chartItems || chartItems.length === 0) return null;

            return (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    {chartItems.map((item) => {
                        const chartData = dates.map(date => ({
                            date,
                            [item]: isderived ? derivedMetrics[date]?.[item] : data[date]?.[item] || 0
                        }));

                        return (
                            <Box key={item} sx={{ width: '32%', height: 200 }}>
                                <Typography variant="subtitle2" align="center">{formatItemName(item)}</Typography>
                                <ResponsiveContainer>
                                    <BarChart data={chartData}>
                                        <XAxis dataKey="date" axisLine={false} tick={{ fontSize: 8 }} />
                                        <YAxis axisLine={false} tick={{ fontSize: 8 }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey={item} fill="#1f77b4" name={formatItemName(item)} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </Box>
                        );
                    })}
                </Box>
            );
        };


        return (
            <>
                <Typography variant="h6" align="left" sx={{ mb: 2 }}>{title}</Typography>
                <Paper elevation={3} sx={{ p: 1, mb: 2 }}>
                    {renderChartRow(items)}
                    {renderChartRow(derivedItems, true)}
                </Paper>
            </>
        );
    };

    return (
        <div>

            <Select
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                style={{ marginBottom: '20px', height: '30px' }}
            >
                <MenuItem value="annual">Annual</MenuItem>
                <MenuItem value="quarter">Quarterly</MenuItem>
            </Select>
            {renderCharts()}
            <Typography variant="h6" align="left" sx={{ mt: 2, mb: 1 }}>{t("Statement Detail")}</Typography>

            <TableContainer component={Paper}>
                <Typography variant="caption" style={{ marginBottom: '10px', marginLeft: '10px', marginTop: '10px' }} >
                    ( Unit : 1,000{currency} )
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Item</TableCell>
                            {displayDates.map((date, index) => (

                                <TableCell key={date}>{date}</TableCell>

                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topLevelItems[statementType].map(item => (
                            <FinancialStatementRow
                                key={item}
                                item={item}
                                data={data}
                                dates={dates}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default FinancialStatement;