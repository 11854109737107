import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';


const languageMapping = {
    // 한국어
    'ko-KR': 'ko',
    'ko-KP': 'ko',

    // 영어
    'en-US': 'en',
    'en-GB': 'en',
    'en-AU': 'en',
    'en-CA': 'en',

    // 중국어
    'zh-CN': 'zh',
    'zh-TW': 'zh',
    'zh-HK': 'zh',

    // 일본어
    'ja-JP': 'ja',

    // 스페인어
    'es-ES': 'es',
    'es-MX': 'es',
    'es-AR': 'es',

    // 프랑스어
    'fr-FR': 'fr',
    'fr-CA': 'fr',
    'fr-BE': 'fr',

    // 독일어
    'de-DE': 'de',
    'de-AT': 'de',
    'de-CH': 'de',

    // 이탈리아어
    'it-IT': 'it',

    // 포르투갈어
    'pt-PT': 'pt',
    'pt-BR': 'pt',

    // 러시아어
    'ru-RU': 'ru',

    // 아랍어
    'ar-SA': 'ar',
    'ar-EG': 'ar',

    // 힌디어
    'hi-IN': 'hi',

    // 벵골어
    'bn-BD': 'bn',
    'bn-IN': 'bn'
};

// 언어 코드 정규화 함수
function normalizeLanguageCode(code) {
    return languageMapping[code] || code.split('-')[0];
}

i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        // 언어 감지 옵션 추가
        detection: {
            order: ['navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['localStorage', 'cookie'],
            convertDetectedLanguage: (lng) => normalizeLanguageCode(lng)
        }
    });

export default i18n; 