// ErrorSnackbar.js
import React from 'react';
import { Snackbar, Alert } from '@mui/material';

function ErrorSnackbar({ open, message, onClose }) {
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
        >
            <Alert onClose={onClose} severity="error" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default ErrorSnackbar;