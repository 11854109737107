import React, { useState, useEffect } from 'react';
import {
    Typography, Skeleton, Grid, Link,
    Pagination, Box, Chip, Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { fetchRelatedArticles } from '../api';
import { useStock } from '../StockContext';
const QueryChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.grey[200],
    color: theme.palette.text.primary,
}));

const SourceChip = styled(Chip)(({ theme }) => ({
    marginLeft: theme.spacing(1),
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.text.secondary,
    height: '20px',
    '& .MuiChip-label': {
        padding: 0,
    },
}));

const ArticleLink = styled(Link)(({ theme }) => ({
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
        textDecoration: 'underline',
    },
}));

const RelatedArticlesComponent = ({ initialQuery }) => {
    const [articlesData, setArticlesData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const articlesPerPage = 15;
    const { setAugmentedQueries } = useStock();
    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);
            try {
                const data = await fetchRelatedArticles(initialQuery);
                setArticlesData(data);
                setAugmentedQueries(data.map(article => article.query));
            }
            catch (err) {
                setError('Failed to fetch articles. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchArticles();
    }, [initialQuery]);

    const renderSkeleton = () => (
        <Box sx={{ mb: 2 }}>
            <Skeleton variant="text" width="60%" height={24} />
            <Skeleton variant="text" width="90%" height={20} />
            <Skeleton variant="text" width="40%" height={20} />
        </Box>
    );

    const getHostname = (url) => {
        try {
            return new URL(url).hostname.replace('www.', '');
        } catch (error) {
            console.error('Invalid URL:', url);
            return 'Unknown source';
        }
    };

    const renderArticle = (article, index, totalArticles) => (
        <React.Fragment key={article.link}>
            <Box sx={{ py: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
                    <ArticleLink href={article.link} target="_blank" rel="noopener noreferrer">
                        <Typography variant="subtitle1" component="div">
                            {article.title}
                        </Typography>
                    </ArticleLink>
                    <SourceChip label={getHostname(article.link)} size="small" />
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                    {article.snippet}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                    {article.date}
                </Typography>
            </Box>
            {index < totalArticles - 1 && <Divider />}
        </React.Fragment>
    );

    if (loading) {
        return (
            <Box sx={{ mt: 2 }}>
                {[...Array(5)].map((_, index) => (
                    <React.Fragment key={index}>
                        {renderSkeleton()}
                        {index < 4 && <Divider />}
                    </React.Fragment>
                ))}
            </Box>
        );
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (articlesData.length === 0) {
        return <Typography>No articles found.</Typography>;
    }

    const allArticles = articlesData.flatMap(queryData => queryData.result);

    // Remove duplicate articles based on their links
    const uniqueArticles = allArticles.reduce((acc, current) => {
        const x = acc.find(item => item.link === current.link);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);

    const sortedArticles = uniqueArticles.sort((a, b) => a.position - b.position);
    const totalPages = Math.ceil(sortedArticles.length / articlesPerPage);
    const displayedArticles = sortedArticles.slice(
        (page - 1) * articlesPerPage,
        page * articlesPerPage
    );

    return (
        <Box sx={{ mt: 2 }}>
            {/* <Typography variant="h6" gutterBottom>
        Related Articles
      </Typography>
      <Box sx={{ mb: 2 }}>
        {articlesData.map((queryData, index) => (
          <QueryChip key={index} label={queryData.query} size="small" />
        ))}
      </Box> */}
            {displayedArticles.map((article, index) =>
                renderArticle(article, index, displayedArticles.length)
            )}
            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={(_, value) => setPage(value)}
                    color="primary"
                    size="small"
                />
            </Box>
        </Box>
    );
};

export default RelatedArticlesComponent;