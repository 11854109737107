export const businessSearchExamples = ['Solid State Disk', 'AI semiconductor', 'US election related', 'obesity medications', 'Dementia-related'];
export const keywordSearchExamples = ['Shortage', 'Chase Into', 'AI', 'exclusive', 'very confident', 'high demand'];

// const categories = [
//     { name: '요약', fields: ['name', 'main_products', 'marketCap', 'peRatio', 'dividendYield', 'enterpriseValueOverEBITDA'] },
//     { name: '재무 지표', fields: ['revenuePerShare', 'netIncomePerShare', 'operatingCashFlowPerShare', 'freeCashFlowPerShare', 'cashPerShare', 'bookValuePerShare', 'tangibleBookValuePerShare', 'shareholdersEquityPerShare', 'interestDebtPerShare', 'capexPerShare'] },
//     { name: 'Valuation 관련', fields: ['peRatio', 'priceToSalesRatio', 'pocfratio', 'pfcfRatio', 'pbRatio', 'ptbRatio', 'evToSales', 'enterpriseValueOverEBITDA', 'evToOperatingCashFlow', 'evToFreeCashFlow'] },
//     { name: '수익성 관련', fields: ['earningsYield', 'freeCashFlowYield', 'roic', 'roe', 'returnOnTangibleAssets'] },
//     { name: '유동성 관련', fields: ['currentRatio', 'workingCapital', 'netCurrentAssetValue'] },
//     { name: '부채 관련', fields: ['debtToEquity', 'debtToAssets', 'netDebtToEBITDA', 'interestCoverage'] },
//     { name: '효율성', fields: ['salesGeneralAndAdministrativeToRevenue', 'researchAndDdevelopementToRevenue', 'intangiblesToTotalAssets', 'capexToOperatingCashFlow', 'capexToRevenue', 'capexToDepreciation'] },
//     { name: '배당 관련', fields: ['stockBasedCompensationToRevenue', 'dividendYield', 'payoutRatio'] },
//     { name: '기타', fields: ['tangibleAssetValue', 'investedCapital', 'averageReceivables', 'averagePayables', 'averageInventory', 'daysSalesOutstanding', 'daysPayablesOutstanding', 'daysOfInventoryOnHand', 'receivablesTurnover', 'payablesTurnover', 'inventoryTurnover'] }
// ];

// const columnTranslations = {
//     'name': '기업명',
//     'businessSimilarity': '사업 유사도',
//     'revenuePerShare': '주당 수익',
//     'netIncomePerShare': '주당 순이익',
//     'operatingCashFlowPerShare': '주당 영업현금흐름',
//     'freeCashFlowPerShare': '주당 잉여현금흐름',
//     'cashPerShare': '주당 현금',
//     'bookValuePerShare': '주당 장부가치',
//     'tangibleBookValuePerShare': '주당 유형자산 장부가치',
//     'shareholdersEquityPerShare': '주당 주주자본',
//     'interestDebtPerShare': '주당 이자부채',
//     'marketCap': '시가총액',
//     'peRatio': 'PER',
//     'priceToSalesRatio': '주가매출비율',
//     'pocfratio': '주가영업현금흐름비율',
//     'pfcfRatio': '주가잉여현금흐름비율',
//     'pbRatio': 'PBR',
//     'ptbRatio': '주가유형자산비율',
//     'evToSales': 'EV/매출',
//     'enterpriseValueOverEBITDA': 'EV/EBITDA',
//     'evToOperatingCashFlow': 'EV/영업현금흐름',
//     'evToFreeCashFlow': 'EV/잉여현금흐름',
//     'earningsYield': 'Earnings Yield',
//     'freeCashFlowYield': '잉여현금흐름 수익률',
//     'debtToEquity': '부채비율',
//     'debtToAssets': '총자산부채비율',
//     'netDebtToEBITDA': '순부채/EBITDA',
//     'currentRatio': '유동비율',
//     'interestCoverage': '이자보상배율',
//     'dividendYield': '배당수익률',
//     'payoutRatio': '배당비율',
//     'salesGeneralAndAdministrativeToRevenue': '판관비율',
//     'researchAndDdevelopementToRevenue': 'R&D/매출',
//     'intangiblesToTotalAssets': '무형자산/총자산',
//     'capexToOperatingCashFlow': '설비투자/영업현금흐름',
//     'capexToRevenue': '설비투자/매출',
//     'capexToDepreciation': '설비투자/감가상각',
//     'stockBasedCompensationToRevenue': '주식보상/매출',
//     'grahamNumber': '그레이엄 숫자',
//     'roic': '투하자본수익률',
//     'returnOnTangibleAssets': '유형자산수익률',
//     'grahamNetNet': '그레이엄 순순운전자본',
//     'workingCapital': '운전자본',
//     'tangibleAssetValue': '유형자산가치',
//     'netCurrentAssetValue': '순유동자산가치',
//     'investedCapital': '투자자본',
//     'averageReceivables': '평균 매출채권',
//     'averagePayables': '평균 매입채무',
//     'averageInventory': '평균 재고',
//     'daysSalesOutstanding': '매출채권 회전일수',
//     'daysPayablesOutstanding': '매입채무 회전일수',
//     'daysOfInventoryOnHand': '재고 보유일수',
//     'receivablesTurnover': '매출채권 회전율',
//     'payablesTurnover': '매입채무 회전율',
//     'inventoryTurnover': '재고 회전율',
//     'roe': '자기자본이익률',
//     'capexPerShare': '주당 설비투자',
//     'main_products': '주요 제품',

// };


export const categories = [
    { name: 'Searched Stocks', fields: ['symbol', 'name', 'reason'] },
    { name: 'Stock Metric', fields: ['main_products', 'market_cap', 'pe_ratio', 'price_to_book_ratio', 'dividend_yield', 'revenue_yoy'] },
    // { name: 'Financial Indicators', fields: ['revenuePerShare', 'netIncomePerShare', 'operatingCashFlowPerShare', 'freeCashFlowPerShare', 'cashPerShare', 'bookValuePerShare', 'capexPerShare'] },
    // { name: 'Valuation', fields: ['peRatio', 'priceToSalesRatio', 'pocfratio', 'pfcfRatio', 'pbRatio', 'evToSales', 'enterpriseValueOverEBITDA'] },
    // { name: 'Profitability', fields: ['earningsYield', 'freeCashFlowYield', 'roic', 'roe'] },
    // { name: 'Liquidity', fields: ['currentRatio', 'workingCapital', 'netCurrentAssetValue'] },
    // { name: 'Debt', fields: ['debtToEquity', 'debtToAssets', 'netDebtToEBITDA', 'interestCoverage'] },
    // { name: 'Efficiency', fields: ['salesGeneralAndAdministrativeToRevenue', 'researchAndDdevelopementToRevenue', 'capexToOperatingCashFlow', 'capexToRevenue'] },
    // { name: 'Dividend', fields: ['stockBasedCompensationToRevenue', 'dividendYield', 'payoutRatio'] },
    // { name: 'Others', fields: ['tangibleAssetValue', 'investedCapital', 'averageReceivables', 'averagePayables', 'averageInventory', 'daysSalesOutstanding', 'daysPayablesOutstanding', 'daysOfInventoryOnHand', 'receivablesTurnover', 'payablesTurnover', 'inventoryTurnover'] }
];

// const columnTranslations = {
//     'name': 'Company Name',
//     'businessSimilarity': 'Business Similarity',
//     'revenuePerShare': 'Revenue/Share',
//     'netIncomePerShare': 'Net Income/Share',
//     'operatingCashFlowPerShare': 'Operating Cash Flow/Share',
//     'freeCashFlowPerShare': 'Free Cash Flow/Share',
//     'cashPerShare': 'Cash/Share',
//     'bookValuePerShare': 'Book Value/Share',
//     'tangibleBookValuePerShare': 'Tangible Book Value/Share',
//     'shareholdersEquityPerShare': 'Shareholders Equity/Share',
//     'interestDebtPerShare': 'Interest Debt/Share',
//     'marketCap': 'Market Cap',
//     'peRatio': 'PER',
//     'priceToSalesRatio': 'PSR',
//     'pocfratio': 'P/OCF Ratio',
//     'pfcfRatio': 'P/FCF Ratio',
//     'pbRatio': 'PBR',
//     'ptbRatio': 'P/Tangible Book Ratio',
//     'evToSales': 'EV/Sales',
//     'enterpriseValueOverEBITDA': 'EV/EBITDA',
//     'evToOperatingCashFlow': 'EV/OCF',
//     'evToFreeCashFlow': 'EV/Free Cash Flow',
//     'earningsYield': 'Earnings Yield',
//     'freeCashFlowYield': 'Free Cash Flow Yield',
//     'debtToEquity': 'Debt/Equity',
//     'debtToAssets': 'Debt/Assets',
//     'netDebtToEBITDA': 'Net Debt/EBITDA',
//     'currentRatio': 'Current Ratio',
//     'interestCoverage': 'Interest Coverage',
//     'dividendYield': 'Dividend Yield',
//     'payoutRatio': 'Payout Ratio',
//     'salesGeneralAndAdministrativeToRevenue': 'SG&A/Revenue',
//     'researchAndDdevelopementToRevenue': 'R&D/Revenue',
//     'intangiblesToTotalAssets': 'Intangibles/Total Assets',
//     'capexToOperatingCashFlow': 'CAPEX/OCF',
//     'capexToRevenue': 'CAPEX/Revenue',
//     'capexToDepreciation': 'CAPEX/Depreciation',
//     'stockBasedCompensationToRevenue': 'Stock-Based Compensation/Revenue',
//     'grahamNumber': 'Graham Number',
//     'roic': 'Return on Invested Capital',
//     'returnOnTangibleAssets': 'Return on Tangible Assets',
//     'grahamNetNet': 'Graham Net-Net',
//     'workingCapital': 'Working Capital',
//     'tangibleAssetValue': 'Tangible Asset Value',
//     'netCurrentAssetValue': 'Net Current Asset Value',
//     'investedCapital': 'Invested Capital',
//     'averageReceivables': 'Average Receivables',
//     'averagePayables': 'Average Payables',
//     'averageInventory': 'Average Inventory',
//     'daysSalesOutstanding': 'Days Sales Outstanding',
//     'daysPayablesOutstanding': 'Days Payables Outstanding',
//     'daysOfInventoryOnHand': 'Days of Inventory on Hand',
//     'receivablesTurnover': 'Receivables Turnover',
//     'payablesTurnover': 'Payables Turnover',
//     'inventoryTurnover': 'Inventory Turnover',
//     'roe': 'Return on Equity',
//     'capexPerShare': 'CAPEX/Share',
//     'main_products': 'Main Products',
// };


export const columnTranslations = {
    main_products: 'Main Products',
    industry: 'Industry',
    current_ratio: 'Current Ratio',
    debt_to_equity: 'Debt/Equity',
    dividend_yield: 'Dividend Yield',
    market_cap: 'Market Cap (Million $)',
    pe_ratio: 'PER',
    price_to_book_ratio: 'PBR',
    quick_ratio: 'Quick Ratio',
    return_on_equity: 'Return on Equity',
    revenue_yoy: 'Revenue YoY',
};