import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Grid, Card, CardContent, Chip, CircularProgress, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TimelineIcon from '@mui/icons-material/Timeline';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { fetchDividendData } from '../api';
import { useStock } from '../StockContext';
const StyledCard = styled(Card)(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
    },
}));

const DividendComponent = ({ symbol }) => {
    const [dividendData, setDividendData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const { showError } = useStock();

    const itemsPerPage = 6;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchDividendData(symbol);
                setDividendData(data);
                setLoading(false);
            } catch (error) {
                showError(`Failed to fetch data: ${error.message}`);
                setLoading(false);
            }
        };

        fetchData();
    }, [symbol]);

    if (loading) {
        return <CircularProgress />;
    }


    if (dividendData.length === 0) {
        return (
            <Box>
                <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: '#e6f7ff' }}>
                    <Typography variant="h5" gutterBottom>Dividend Information</Typography>
                    <Typography>No dividend data available for this stock.</Typography>
                </Paper>
            </Box>
        );
    }

    const latestDividend = dividendData[0];
    const annualDividend = latestDividend.dividend * 4; // Assuming quarterly dividends

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const pageCount = Math.ceil(dividendData.length / itemsPerPage);
    const displayedDividends = dividendData.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    const calculateAnnualYield = (dividend, price) => {
        if (!price) return null;
        return ((dividend * 4 / price) * 100).toFixed(2);
    };

    return (
        <Box>
            <Paper elevation={3} sx={{ p: 3, mb: 4, backgroundColor: '#e6f7ff' }}>
                <Typography variant="h5" gutterBottom>Dividend Summary</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6" color="text.secondary" gutterBottom>
                                    <AttachMoneyIcon /> Latest Dividend
                                </Typography>
                                <Typography variant="h4" color="primary">
                                    ${latestDividend.dividend.toFixed(2)}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Annual: ${annualDividend.toFixed(2)}
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6" color="text.secondary" gutterBottom>
                                    <CalendarTodayIcon /> Next Ex-Dividend
                                </Typography>
                                <Typography variant="h6">
                                    {new Date(latestDividend.date).toLocaleDateString()}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Payment: {new Date(latestDividend.payment_date).toLocaleDateString()}
                                </Typography>
                            </CardContent>
                        </StyledCard>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <StyledCard>
                            <CardContent>
                                <Typography variant="h6" color="text.secondary" gutterBottom>
                                    <TrendingUpIcon /> Annual Dividend Yield
                                </Typography>
                                {latestDividend.close ? (
                                    <>
                                        <Typography variant="h4" color="primary">
                                            {calculateAnnualYield(latestDividend.dividend, latestDividend.close)}%
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            Based on stock price: ${latestDividend.close.toFixed(2)}
                                        </Typography>
                                    </>
                                ) : (
                                    <Typography variant="body1" color="text.secondary">
                                        Not available for future dividends
                                    </Typography>
                                )}
                            </CardContent>
                        </StyledCard>
                    </Grid>
                </Grid>
            </Paper>

            <Typography variant="h6" gutterBottom>Dividend History</Typography>
            <Grid container spacing={2}>
                {displayedDividends.map((dividend) => (
                    <Grid item xs={12} sm={6} md={4} key={dividend.id}>
                        <StyledCard>
                            <CardContent>
                                <Typography color="text.secondary" gutterBottom>
                                    Ex-Dividend Date
                                </Typography>
                                <Typography variant="h6">
                                    {new Date(dividend.date).toLocaleDateString()}
                                </Typography>
                                <Typography variant="h5" color="primary" sx={{ mt: 2 }}>
                                    ${dividend.dividend.toFixed(2)}
                                </Typography>
                                {dividend.close && (
                                    <Chip
                                        label={`Annual Yield: ${calculateAnnualYield(dividend.dividend, dividend.close)}%`}
                                        color="success"
                                        sx={{ mt: 1 }}
                                    />
                                )}
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Payment: {new Date(dividend.payment_date).toLocaleDateString()}
                                </Typography>
                                {dividend.close && (
                                    <Typography variant="body2" color="text.secondary">
                                        Stock Price: ${dividend.close.toFixed(2)}
                                    </Typography>
                                )}
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                <Pagination
                    count={pageCount}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>
        </Box>
    );
};

export default DividendComponent;