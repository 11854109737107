
import React, { createContext, useState, useContext, useCallback } from 'react';
import { fetchSearchCompany, fetchProductSalesData } from './api';
import { useNavigate } from 'react-router-dom';
import ErrorSnackbar from './components/ErrorSnackbar';

const StockContext = createContext();

export const StockProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStock, setSelectedStock] = useState(null);
    const [filters, setFilters] = useState([]);
    const [searchedQueryValue, setSearchedQueryValue] = useState('');
    const [translatedQueryValue, setTranslatedQueryValue] = useState('');
    const [searchMode, setSearchMode] = useState('business'); // 'business' 또는 'keyword'
    const [marketCapData, setMarketCapData] = useState([]);
    const [error, setError] = useState(null);
    const [selectedSymbols, setSelectedSymbols] = useState([]);
    const [isEarningCallOpen, setIsEarningCallOpen] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [chatWidth, setChatWidth] = useState(window.innerWidth * 0.3);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const [errorSnackbar, setErrorSnackbar] = useState({ open: false, message: '' });
    const [businessSubTab, setBusinessSubTab] = useState(0);
    const [augmentedQueries, setAugmentedQueries] = useState([]);
    const [completeContent, setCompleteContent] = useState('');
    const [sources, setSources] = useState([]);
    const [content, setContent] = useState('');


    const showError = (message) => {
        setErrorSnackbar({ open: true, message });
    };

    const navigate = useNavigate();

    // const handleExampleClick = (example, mode) => {
    //     if (mode === 'business') {
    //         setIdeaQuery(example);
    //         handleSearch('business', example);  // 직접 example 값을 전달
    //     } else {
    //         setKeywordQuery(example);
    //         handleSearch('keyword', example);  // 직접 example 값을 전달
    //     }
    // };


    const handleStockClick = async (stock) => {
        setSelectedStock(stock);
    };



    const handleSearch = useCallback((mode, query) => {
        navigate('/idea-analysis');
        setBusinessSubTab(0)
        setSearchedQueryValue(query);
        setSearchMode(mode);
        setAugmentedQueries([])
    }, [navigate]);


    const fetchRelatedStocks = async (searchMode, searchQuery) => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await fetchSearchCompany({
                mode: searchMode,
                query: searchQuery,
                filters: filters.length > 0 ? JSON.stringify(filters) : undefined
            });
            setData(response.items);
            setTranslatedQueryValue(response.translated_query);
        } catch (error) {
            showError(`Failed to fetch data: ${error.message}`);
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <StockContext.Provider value={{
            data, setData, loading, setLoading,
            filters, setFilters, searchedQueryValue, setSearchedQueryValue,
            searchMode, setSearchMode,
            marketCapData, setMarketCapData,
            error, setError,
            selectedSymbols, setSelectedSymbols,
            handleSearch, handleStockClick, setSelectedStock, selectedStock,
            isEarningCallOpen, setIsEarningCallOpen,
            selectedQuestion, setSelectedQuestion,
            chatWidth, setChatWidth,
            isNavCollapsed, setIsNavCollapsed,
            ErrorSnackbar, showError, setErrorSnackbar, errorSnackbar,
            businessSubTab, setBusinessSubTab, translatedQueryValue,
            fetchRelatedStocks, augmentedQueries, setAugmentedQueries, completeContent, setCompleteContent, sources, setSources, content, setContent
        }}>
            {children}
        </StockContext.Provider>
    );
};


export const useStock = () => useContext(StockContext);
