import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { CircularProgress, Modal, Paper, Grid, Divider, Select, MenuItem, List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, Pagination, Box, Link, FormGroup, FormControlLabel, Switch, SvgIcon } from '@mui/material';
import { subMonths, subYears, format } from 'date-fns';
import { fetchStockHistory } from '../api';
import { useStock } from '../StockContext';
import { useTranslation } from 'react-i18next';
import { fetchRelatedNews } from '../api';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import ReactMarkdown from 'react-markdown';


const Legend = () => {
    const legendItems = [
        { label: 'Positive Earnings', color: '#00CED1', symbol: 'diamond' },
        { label: 'Negative Earnings', color: '#8E44AD', symbol: 'diamond' },
        { label: 'Dividends', color: '#FF7F50', symbol: 'triangle' },
        { label: 'News', color: '#FFD700', symbol: 'star' },
    ];

    const getSymbolPath = (symbol) => {
        switch (symbol) {
            case 'diamond':
                return 'M8 0L16 8L8 16L0 8Z';
            case 'triangle':
                return 'M8 0L16 16H0Z';
            default:
                return 'M8 0A8 8 0 1 0 8 16A8 8 0 0 0 8 0Z'; // circle
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            mb: 1,
            gap: 2
        }}>
            {legendItems.map((item, index) => (
                <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <SvgIcon sx={{ color: item.color, mr: 1 }}>
                        <path d={getSymbolPath(item.symbol)} />
                    </SvgIcon>
                    <Typography variant="caption">{item.label}</Typography>
                </Box>
            ))}
        </Box>
    );
};


const NewsListComponent = ({ newsItems }) => {
    const [page, setPage] = useState(1);
    const itemsPerPage = 5;

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const sortedNews = newsItems.sort((a, b) => new Date(b.published_date) - new Date(a.published_date));
    const paginatedNews = sortedNews.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    return (
        <Box>
            <List>
                {paginatedNews.map((item) => (
                    <ListItem key={item.id} alignItems="flex-start">
                        <ListItemAvatar>
                            <Avatar alt={item.title} src={item.image} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                    {item.title}
                                </Link>
                            }
                            secondary={
                                <>
                                    <Typography component="span" variant="body2" color="text.primary">
                                        {item.site}
                                    </Typography>
                                    {` — ${item.symbol}`}
                                    <br />
                                    <Typography component="span" variant="body2" color="text.primary">
                                        {item.published_date.split('T')[0]}
                                    </Typography>
                                </>
                            }
                        />
                    </ListItem>
                ))}
            </List>
            <Box display="flex" justifyContent="center" mt={2}>
                <Pagination
                    count={Math.ceil(newsItems.length / itemsPerPage)}
                    page={page}
                    onChange={handleChangePage}
                />
            </Box>
        </Box>
    );
};


const StockHistoryComponent = ({ symbol }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { showError } = useStock();
    const { t } = useTranslation();
    const [timeRange, setTimeRange] = useState('3y');
    const [relatedNews, setRelatedNews] = useState([]);
    const chartRef = useRef(null);
    const [selectedFlag, setSelectedFlag] = useState(null);
    const [chartType, setChartType] = useState('candlestick');
    const [visibleEvents, setVisibleEvents] = useState({
        earnings: true,
        dividends: true,
        news: false
    });

    const handleEventClick = async (event) => {
        setSelectedEvent(event);
        setSelectedFlag(event.date);
        if (event.related_stock_news_ids) {
            try {
                const newsIds = event.related_stock_news_ids.split(',').filter(id => id.trim() !== '');
                const newsData = await fetchRelatedNews(newsIds);
                setRelatedNews(newsData);
            } catch (error) {
                console.error('Error fetching related news:', error);
                showError(`Failed to fetch related news: ${error.message}`);
            }
        } else {
            setRelatedNews([]);
        }
    };




    useEffect(() => {
        const loadStockHistory = async () => {
            try {
                let startDate;
                const today = new Date();

                switch (timeRange) {
                    case '1m':
                        startDate = subMonths(today, 1);
                        break;
                    case '3m':
                        startDate = subMonths(today, 3);
                        break;
                    case '6m':
                        startDate = subMonths(today, 6);
                        break;
                    case '1y':
                        startDate = subYears(today, 1);
                        break;
                    case '3y':
                        startDate = subYears(today, 5);
                        break;
                    default:
                        startDate = subYears(today, 1);
                }

                const formattedStartDate = format(startDate, 'yyyy-MM-dd');
                const result = await fetchStockHistory(symbol, formattedStartDate);
                setData(result.sort((a, b) => new Date(a.date) - new Date(b.date)));
                setLoading(false);
            } catch (error) {
                console.error('Error fetching stock history:', error);
                showError(`Failed to fetch data: ${error.message}`);
                setLoading(false);
            }
        };

        loadStockHistory();
    }, [symbol, timeRange, showError]);

    const calculateEPSGrowth = (currentEPS, previousEvent) => {
        if (!previousEvent || previousEvent.eps === null || previousEvent.eps === 0) return 'N/A';
        return ((currentEPS - previousEvent.eps) / Math.abs(previousEvent.eps) * 100).toFixed(2);
    };

    const calculateDividendYield = (dividend, stockPrice) => {
        return ((dividend / stockPrice) * 100).toFixed(2);
    };

    const getPreviousEarningsEvent = (currentEvent) => {
        const currentDate = new Date(currentEvent.date);
        let previousEvent = null;

        for (let i = data.length - 1; i >= 0; i--) {
            const eventDate = new Date(data[i].date);
            if (eventDate < currentDate && data[i].eps !== null) {
                previousEvent = data[i];
                break;
            }
        }

        return previousEvent;
    };

    const formatLargeNumber = (number) => {
        if (number == null || isNaN(number)) {
            return 'N/A';
        }
        if (number >= 1e12) {
            return (number / 1e12).toFixed(2) + 'T';
        } else if (number >= 1e9) {
            return (number / 1e9).toFixed(2) + 'B';
        } else if (number >= 1e6) {
            return (number / 1e6).toFixed(2) + 'M';
        } else {
            return number.toFixed(2);
        }
    };

    const eventColors = {
        earnings: {
            positive: '#00CED1', // 밝은 청록색
            negative: '#8E44AD'  // 자주색
        },
        dividends: '#FF7F50', // 주황색
        news: '#FFD700'  // 노란색
    };

    const getScatterData = useCallback(() => {
        return data.reduce((acc, item) => {
            if ((visibleEvents.earnings && item.eps !== null) ||
                (visibleEvents.dividends && item.adj_dividend !== null) ||
                (visibleEvents.news && item.related_stock_news_ids !== null)) {
                const isSelected = selectedFlag === item.date;
                acc.push({
                    x: new Date(item.date).getTime(),
                    y: item.high * 1.01,
                    marker: {
                        symbol: item.eps !== null ? 'diamond' :
                            item.adj_dividend !== null ? 'triangle' : 'star',
                        radius: isSelected ? 10 : 6,
                        fillColor: item.eps !== null ?
                            (item.eps > item.eps_estimated ? eventColors.earnings.positive : eventColors.earnings.negative) :
                            item.adj_dividend !== null ? eventColors.dividends : eventColors.news,
                        lineColor: '#000000',
                        lineWidth: 1
                    },
                    events: {
                        click: function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            handleEventClick(item);
                            return false;
                        }
                    },
                    name: item.eps !== null ? 'Earnings' :
                        item.adj_dividend !== null ? 'Dividend' : 'News'
                });
            }
            return acc;
        }, []);
    }, [data, selectedFlag, handleEventClick, visibleEvents, eventColors]);

    const handleVisibleEventsChange = (event) => {
        setVisibleEvents({
            ...visibleEvents,
            [event.target.name]: event.target.checked
        });
    };

    const getMainSeriesOptions = useCallback(() => {
        const baseOptions = {
            name: symbol,
            data: data.map(item => [
                new Date(item.date).getTime(),
                item.open,
                item.high,
                item.low,
                item.close
            ]),
            tooltip: {
                valueDecimals: 2
            }
        };

        switch (chartType) {
            case 'candlestick':
                return {
                    ...baseOptions,
                    type: 'candlestick',
                    color: 'red',
                    upColor: 'green',
                    lineColor: 'red',
                    upLineColor: 'green',
                };
            case 'ohlc':
                return {
                    ...baseOptions,
                    type: 'ohlc',
                    color: 'red',
                    upColor: 'green',
                };
            case 'line':
                return {
                    ...baseOptions,
                    type: 'line',
                    data: data.map(item => [
                        new Date(item.date).getTime(),
                        item.close
                    ]),
                };
            case 'area':
                return {
                    ...baseOptions,
                    type: 'area',
                    data: data.map(item => [
                        new Date(item.date).getTime(),
                        item.close
                    ]),
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, Highcharts.getOptions().colors[0]],
                            [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                        ]
                    },
                };
            default:
                return baseOptions;
        }
    }, [chartType, data, symbol]);

    const options = useMemo(() => ({
        credits: {
            enabled: false
        },
        boost: {
            useGPUTranslations: true,
            usePreAllocated: true
        },
        chart: {
            height: 600,
            // zoomType: 'x',
        },
        title: {
            text: `${symbol} Stock Price History and Events`
        },
        // xAxis: {
        //     type: 'datetime',
        //     labels: {
        //         format: '{value:%Y-%m-%d}',
        //     },
        //     crosshair: {
        //         enabled: true,
        //         color: 'rgba(0, 0, 0, 0.3)',
        //         width: 1,
        //         dashStyle: 'ShortDot'
        //     }
        // },
        yAxis: [{
            labels: {
                align: 'right',
                x: -3
            },
            title: {
                text: 'Price'
            },
            height: '85%',
            lineWidth: 2,
            resize: {
                enabled: true
            }
        }, {
            labels: {
                align: 'right',
                x: -3
            },
            title: {
                text: 'Volume'
            },
            top: '80%',
            height: '20%',
            offset: 0,
            lineWidth: 2
        }],

        tooltip: {
            // enabled: false  // 툴팁 비활성화
            split: false
        },
        plotOptions: {
            series: {
                // animation: false,  // 시리즈 애니메이션 비활성화
                states: {
                    inactive: {
                        opacity: 1  // 비활성 상태의 불투명도를 1로 설정 (블러 효과 제거)
                    },
                    // hover: {
                    //     enabled: false  // 호버 효과 비활성화
                    // }
                }
            }
        },
        // navigator: {
        //     enabled: false,
        // },
        series: [
            getMainSeriesOptions(),
            {
                type: 'scatter',
                name: 'Events',
                data: getScatterData(),
                tooltip: {
                    pointFormat: '{point.name}<br/><i>Click to view details</i>',
                },
                zIndex: 5
            },
            {
                type: 'column',
                name: 'Volume',
                data: data.map(item => [
                    new Date(item.date).getTime(),
                    item.volume
                ]),
                yAxis: 1,
            }
        ]
    }), [data, symbol, getScatterData, getMainSeriesOptions]);

    const handleChartTypeChange = (event) => {
        setChartType(event.target.value);
    };

    if (loading) {
        return <CircularProgress />;
    }



    const chartTypes = [
        { value: 'candlestick', label: 'Candlestick', emoji: '📊' },
        { value: 'ohlc', label: 'OHLC', emoji: '📈' },
        { value: 'line', label: 'Line', emoji: '📉' },
        { value: 'area', label: 'Area', emoji: '🏔️' },
    ];



    return (
        <Box sx={{ width: '100%', height: 500 }}>
            <Legend />

            <Box sx={{ display: 'flex', width: '100%', height: 600 }}>

                <Box sx={{ width: '70%', height: '100%' }}>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderRadius: '20px',
                        padding: '5px 15px',
                        boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                    }}>
                        <Typography sx={{ mr: 1, color: '#666' }}>Chart Type:</Typography>
                        <Select
                            value={chartType}
                            onChange={handleChartTypeChange}
                            size="small"
                            sx={{
                                minWidth: 150,
                                '& .MuiOutlinedInput-notchedOutline': { border: 'none' },
                                '& .MuiSelect-select': { paddingY: '5px' },
                            }}
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        borderRadius: '15px',
                                        boxShadow: '0 3px 10px rgba(0,0,0,0.2)',
                                    },
                                },
                            }}
                        >
                            {chartTypes.map((type) => (
                                <MenuItem key={type.value} value={type.value} sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px',
                                    padding: '10px 15px',
                                }}>
                                    <span role="img" aria-label={type.label} style={{ fontSize: '1.2rem' }}>
                                        {type.emoji}
                                    </span>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                            <Typography sx={{ color: '#666' }}>Visible Events:</Typography>
                            <FormGroup row>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={visibleEvents.earnings}
                                            onChange={handleVisibleEventsChange}
                                            name="earnings"
                                            color="primary"
                                        />
                                    }
                                    label="Earnings"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={visibleEvents.dividends}
                                            onChange={handleVisibleEventsChange}
                                            name="dividends"
                                            color="primary"
                                        />
                                    }
                                    label="Dividends"
                                />
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={visibleEvents.news}
                                            onChange={handleVisibleEventsChange}
                                            name="news"
                                            color="primary"
                                        />
                                    }
                                    label="News"
                                />
                            </FormGroup>
                        </Box>
                    </Box>

                    <HighchartsReact
                        highcharts={Highcharts}
                        constructorType={'stockChart'}
                        options={options}
                        ref={chartRef}
                    />
                </Box>
                <Divider orientation="vertical" flexItem />

                <Box sx={{ width: '30%', height: '100%', overflowY: 'auto', pl: 2 }}>
                    {/* Side panel for event details */}
                    {selectedEvent ? (
                        <div sx={{ p: 2 }}>
                            {selectedEvent && (
                                <>
                                    {selectedEvent.eps !== null && (
                                        <Paper sx={{ p: 2, mb: 2 }}>
                                            <Typography variant="h6" component="h2" gutterBottom>
                                                Earnings Report - {format(new Date(selectedEvent.date), 'yyyy-MM-dd')}
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            <Grid container spacing={2}>
                                                {selectedEvent.eps !== null && (
                                                    <Grid item xs={6}>
                                                        <Typography>EPS: ${selectedEvent.eps.toFixed(2)}</Typography>
                                                    </Grid>
                                                )}
                                                {selectedEvent.eps_estimated !== null && (
                                                    <Grid item xs={6}>
                                                        <Typography>Est. EPS: ${selectedEvent.eps_estimated.toFixed(2)}</Typography>
                                                    </Grid>
                                                )}
                                                {selectedEvent.revenue !== null && (
                                                    <Grid item xs={6}>
                                                        <Typography>Revenue: ${formatLargeNumber(selectedEvent.revenue)}</Typography>
                                                    </Grid>
                                                )}
                                                {selectedEvent.revenue_estimated !== null && (
                                                    <Grid item xs={6}>
                                                        <Typography>Est. Revenue: ${formatLargeNumber(selectedEvent.revenue_estimated)}</Typography>
                                                    </Grid>
                                                )}
                                                {selectedEvent.eps_estimated !== null && selectedEvent.eps !== null && (
                                                    <Grid item xs={6}>
                                                        <Typography color={selectedEvent.eps > selectedEvent.eps_estimated ? 'success.main' : 'error.main'}>
                                                            EPS Surprise: {((selectedEvent.eps - selectedEvent.eps_estimated) / Math.abs(selectedEvent.eps_estimated) * 100).toFixed(2)}%
                                                        </Typography>
                                                    </Grid>
                                                )}
                                                {selectedEvent.revenue_estimated !== null && selectedEvent.revenue !== null && (
                                                    <Grid item xs={6}>
                                                        <Typography color={selectedEvent.revenue > selectedEvent.revenue_estimated ? 'success.main' : 'error.main'}>
                                                            Revenue Surprise: {((selectedEvent.revenue - selectedEvent.revenue_estimated) / Math.abs(selectedEvent.revenue_estimated) * 100).toFixed(2)}%
                                                        </Typography>
                                                    </Grid>
                                                )}                                                <Grid item xs={6}>
                                                    <Typography>
                                                        EPS Growth: {calculateEPSGrowth(selectedEvent.eps, getPreviousEarningsEvent(selectedEvent))}%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )}
                                    {selectedEvent.adj_dividend !== null && (
                                        <Paper sx={{ p: 2, mb: 2 }}>
                                            <Typography variant="h6" component="h2" gutterBottom>
                                                Dividend Report - {format(new Date(selectedEvent.date), 'yyyy-MM-dd')}
                                            </Typography>
                                            <Divider sx={{ my: 2 }} />
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    <Typography>Amount: ${selectedEvent.adj_dividend.toFixed(2)}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>Payment Date: {selectedEvent.payment_date}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>Declaration Date: {selectedEvent.declaration_date}</Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Typography>
                                                        Dividend Yield: {calculateDividendYield(selectedEvent.adj_dividend, selectedEvent.close)}%
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    )}
                                    {selectedEvent.related_stock_news_ids !== null && (
                                        <Paper sx={{ p: 2, mb: 2 }}>
                                            <Typography variant="h6" component="h2" gutterBottom>
                                                Related News - {format(new Date(selectedEvent.date), 'yyyy-MM-dd')}
                                            </Typography>
                                            <Typography>Price Change Percent: {Number(selectedEvent.price_change_pct).toFixed(2)}%</Typography>
                                            <Typography>Volume Increase Percent: {Number(selectedEvent.volume_increase_pct).toFixed(2)}%</Typography>

                                            {selectedEvent.summary && (
                                                <>
                                                    <Divider sx={{ my: 2 }} />
                                                    <Typography variant="subtitle1" gutterBottom>News Summary</Typography>
                                                    <ReactMarkdown>{selectedEvent.summary}</ReactMarkdown>
                                                </>
                                            )}

                                            <Divider sx={{ my: 2 }} />
                                            {relatedNews.length > 0 ? (
                                                <NewsListComponent newsItems={relatedNews} />
                                            ) : (
                                                <Typography variant="body1">
                                                    No related news available.
                                                </Typography>
                                            )}
                                        </Paper>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            Click on a point in the chart to view event details.
                        </Typography>
                    )}
                </Box>
            </Box>
        </Box>
    )
};

export default StockHistoryComponent;