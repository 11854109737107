import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, IconButton, Box, Grid, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';

import axios from 'axios';
import { useStock } from '../StockContext';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const EarningCallModal = ({ chunkId, earningCallId, target_text }) => {
    const handleClose = () => {
        setIsEarningCallOpen(false)
    }
    const { isEarningCallOpen, setIsEarningCallOpen, showError } = useStock();
    const [earningCallData, setEarningCallData] = useState(null);
    const [searchKeyword, setSearchKeyword] = useState('');
    const contentRef = useRef(null);

    useEffect(() => {
        if (isEarningCallOpen && (chunkId || earningCallId)) {
            fetchEarningCallData();
        }
    }, [isEarningCallOpen, chunkId, earningCallId]);

    const fetchEarningCallData = async () => {
        try {
            let response;
            if (chunkId) {
                response = await axios.get(`${serverUrl}/api/earnings-call/by-chunk-id?chunk_id=${chunkId}`);
            } else if (earningCallId) {
                response = await axios.get(`${serverUrl}/api/earnings-call/by-id?earningcall_id=${earningCallId}`);
            }
            setEarningCallData(response.data);
        } catch (error) {
            showError(`Failed to fetch data: ${error.message}`);
        }
    };

    useEffect(() => {
        if (contentRef.current) {
            const highlightElement = contentRef.current.querySelector('.highlight');
            if (highlightElement) {
                highlightElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [earningCallData]);

    const renderContent = (content) => {
        const paragraphs = content.split('\n');

        if (chunkId && earningCallData.sliced_content) {
            const slicedContent = earningCallData.sliced_content.trim();
            let highlightStartIndex = -1;
            let highlightEndIndex = -1;

            // Find the exact start and end indices of the highlighted content
            const contentLower = content.toLowerCase();
            const slicedContentLower = slicedContent.toLowerCase();
            const startIndex = contentLower.indexOf(slicedContentLower);

            if (startIndex !== -1) {
                const endIndex = startIndex + slicedContent.length;
                let currentIndex = 0;

                for (let i = 0; i < paragraphs.length; i++) {
                    if (currentIndex + paragraphs[i].length > startIndex && highlightStartIndex === -1) {
                        highlightStartIndex = i;
                    }
                    if (currentIndex + paragraphs[i].length >= endIndex) {
                        highlightEndIndex = i;
                        break;
                    }
                    currentIndex += paragraphs[i].length + 1; // +1 for the newline character
                }
            }

            return paragraphs.map((paragraph, index) => {
                let highlightedParagraph = paragraph;
                const isHighlighted = index >= highlightStartIndex && index <= highlightEndIndex;

                if (isHighlighted) {
                    if (index === highlightStartIndex && index === highlightEndIndex) {
                        // If the highlight is within a single paragraph
                        const startInParagraph = Math.max(0, startIndex - content.indexOf(paragraph));
                        const endInParagraph = Math.min(paragraph.length, startInParagraph + slicedContent.length);
                        highlightedParagraph =
                            paragraph.substring(0, startInParagraph) +
                            `<span class="highlight" style="background-color: rgba(255, 220, 100, 0.3); padding: 0 2px;">` +
                            paragraph.substring(startInParagraph, endInParagraph) +
                            '</span>' +
                            paragraph.substring(endInParagraph);
                    } else {
                        highlightedParagraph = `<span class="highlight" style="background-color: rgba(255, 220, 100, 0.3); padding: 0 2px;">${paragraph}</span>`;
                    }
                }

                // Add target_text highlighting
                if (target_text && highlightedParagraph.toLowerCase().includes(target_text.toLowerCase())) {
                    const regex = new RegExp(`(${target_text})`, 'gi');
                    highlightedParagraph = highlightedParagraph.replace(regex, '<span class="target-highlight" style="background-color: rgba(100, 255, 100, 0.3); padding: 0 2px;">$1</span>');
                }

                return (
                    <Typography
                        key={index}
                        paragraph
                        sx={{
                            mb: 2,
                            p: 1.5,
                            bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper',
                            borderRadius: 1,
                            boxShadow: 1
                        }}
                        dangerouslySetInnerHTML={{ __html: highlightedParagraph }}
                    />
                );
            });
        } else {
            return paragraphs.map((paragraph, index) => {
                let highlightedParagraph = paragraph;
                if (searchKeyword) {
                    const regex = new RegExp(`(${searchKeyword})`, 'gi');
                    highlightedParagraph = paragraph.replace(regex, '<span class="highlight" style="background-color: rgba(255, 220, 100, 0.3); padding: 0 2px;">$1</span>');
                }

                return (
                    <Typography
                        key={index}
                        paragraph
                        sx={{
                            mb: 2,
                            p: 1.5,
                            bgcolor: index % 2 === 0 ? 'background.default' : 'background.paper',
                            borderRadius: 1,
                            boxShadow: 1
                        }}
                        dangerouslySetInnerHTML={{ __html: highlightedParagraph }}
                    />
                );
            });
        }
    };


    const handleSearch = (e) => {
        e.preventDefault();
        // Trigger re-render to apply new search highlight
        setEarningCallData({ ...earningCallData });
    };
    return (
        <Dialog open={isEarningCallOpen} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle sx={{ borderBottom: 1, borderColor: 'divider' }}>
                Earning Call Transcript
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ p: 3 }} ref={contentRef}>
                {earningCallData && (
                    <>
                        <Box sx={{ mb: 3, p: 2, bgcolor: 'background.paper', borderRadius: 1, boxShadow: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Symbol: <strong>{earningCallData.symbol}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Year: <strong>{earningCallData.earningcall_year}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="subtitle1">
                                        Quarter: <strong>{earningCallData.earningcall_quarter}</strong>
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <form onSubmit={handleSearch}>
                                        <TextField
                                            size="small"
                                            placeholder="Search keyword"
                                            value={searchKeyword}
                                            onChange={(e) => setSearchKeyword(e.target.value)}
                                            InputProps={{
                                                endAdornment: (
                                                    <IconButton type="submit">
                                                        <SearchIcon />
                                                    </IconButton>
                                                ),
                                            }}
                                        />
                                    </form>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            {renderContent(earningCallData.full_content)}
                        </Box>
                    </>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default EarningCallModal;