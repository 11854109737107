import React, { useState, useEffect } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TablePagination, Typography, Box, IconButton
} from '@mui/material';
import { PlayArrow, CalendarToday } from '@mui/icons-material';
import { fetchEarningCallList } from '../api';
import EarningCallModal from './EarningCallModal';
import { useStock } from '../StockContext';

const EarningCallList = ({ symbol }) => {
    const [earningCalls, setEarningCalls] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedEarningCall, setSelectedEarningCall] = useState(null);
    const { setIsEarningCallOpen, showError } = useStock();

    useEffect(() => {
        const fetchEarningCalls = async () => {
            try {
                const data = await fetchEarningCallList(symbol)
                const sortedData = data.sort((a, b) => {
                    if (a.year !== b.year) return b.year - a.year;
                    return b.quarter - a.quarter;
                });
                setEarningCalls(sortedData);
            } catch (error) {
                showError(`Failed to fetch data: ${error.message}`);

            }
        };

        fetchEarningCalls();
    }, [symbol]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRowClick = (earningCall) => {
        setSelectedEarningCall(earningCall);
        setIsEarningCallOpen(true)
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    return (
        <Paper>
            <Box p={2}>
                <Typography variant="h6" gutterBottom>
                    Earnings Call History
                </Typography>
            </Box>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Year</TableCell>
                            <TableCell>Quarter</TableCell>
                            <TableCell>Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {earningCalls.length > 0 ? (
                            earningCalls
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((call) => (
                                    <TableRow
                                        key={call.earningcall_id}
                                        onClick={() => handleRowClick(call)}
                                        style={{ cursor: 'pointer' }}
                                        hover
                                    >
                                        <TableCell>{call.year}</TableCell>
                                        <TableCell>Q{call.quarter}</TableCell>
                                        <TableCell>
                                            <Box display="flex" alignItems="center">
                                                <CalendarToday fontSize="small" style={{ marginRight: 8 }} />
                                                {formatDate(call.date)}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} align="center">
                                    No earnings call data available
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={earningCalls.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {selectedEarningCall && (
                <EarningCallModal
                    earningCallId={selectedEarningCall.earningcall_id}
                />
            )}
        </Paper>
    );
};

export default EarningCallList;