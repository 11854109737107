import React, { useState, useEffect, useRef, useMemo } from 'react';
import * as echarts from 'echarts';
import styled from 'styled-components';

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;


const formatMarketCap = (value) => {
    if (value >= 1e6) return `$${(value / 1e6).toFixed(2)}T`;
    if (value >= 1e3) return `$${(value / 1e3).toFixed(2)}B`;
    return `$${(value / 1e6).toFixed(2)}M`;
};

const colors = [
    '#E6F3FF', '#CCE7FF', '#99CFFF', '#66B7FF', '#339FFF',
    '#0087FF', '#0066CC', '#004C99', '#003366', '#001F3F'
];
const truncateText = (text, maxLength = 10) => {
    return text.length > maxLength ? text.slice(0, maxLength - 3) + '...' : text;
};

export const processData = (rawData) => {
    if (!rawData || !Array.isArray(rawData)) {
        console.error('Invalid data provided to processData');
        return [];
    }

    const sectors = [];
    const sectorMap = new Map();

    rawData.forEach(item => {
        if (item && item.sector && item.industry && item.symbol && item.market_cap != null && item.market_cap > 0) {
            if (!sectorMap.has(item.sector)) {
                sectorMap.set(item.sector, new Map());
            }
            const industryMap = sectorMap.get(item.sector);
            if (!industryMap.has(item.industry)) {
                industryMap.set(item.industry, []);
            }
            industryMap.get(item.industry).push(item);
        }
    });

    const transformValue = (value) => Math.sqrt(value);

    sectorMap.forEach((industries, sector) => {
        const sectorNode = {
            name: truncateText(sector),
            fullName: sector,
            children: [],
            value: 0,
            originalValue: 0
        };

        industries.forEach((stocks, industry) => {
            const industryNode = {
                name: truncateText(industry),
                fullName: industry,
                children: [],
                value: 0,
                originalValue: 0
            };

            stocks.forEach(stock => {
                const stockNode = {
                    name: truncateText(stock.name),
                    fullName: stock.name,
                    value: transformValue(stock.market_cap),
                    originalValue: stock.market_cap
                };
                industryNode.children.push(stockNode);
                industryNode.value += stockNode.value;
                industryNode.originalValue += stockNode.originalValue;
            });

            sectorNode.children.push(industryNode);
            sectorNode.value += industryNode.value;
            sectorNode.originalValue += industryNode.originalValue;
        });

        sectors.push(sectorNode);
    });

    // 섹터 정렬
    sectors.sort((a, b) => b.originalValue - a.originalValue);

    // 각 섹터 내의 산업을 정렬
    sectors.forEach(sector => {
        sector.children.sort((a, b) => b.originalValue - a.originalValue);
        // 각 산업 내의 주식을 정렬
        sector.children.forEach(industry => {
            industry.children.sort((a, b) => b.originalValue - a.originalValue);
        });
    });

    return sectors;
};


export const SectorIndustrySunburst = ({ data }) => {
    const chartRef = useRef(null);
    const [chart, setChart] = useState(null);
    const processedData = useMemo(() => processData(data), [data]);

    useEffect(() => {
        if (chartRef.current && processedData.length > 0) {
            const newChart = echarts.init(chartRef.current);
            setChart(newChart);

            const option = {
                tooltip: {
                    formatter: function (params) {
                        const { treePathInfo, data } = params;
                        const path = data.fullName;
                        return `${path}<br/>Market Cap: ${formatMarketCap(data.originalValue)}`;
                    }
                },
                series: {
                    type: 'sunburst',
                    data: processedData,
                    radius: [0, '95%'],
                    sort: null,
                    emphasis: {
                        focus: 'ancestor'
                    },
                    levels: [
                        {},
                        {
                            r0: '5%',
                            r: '35%',
                            itemStyle: {
                                borderWidth: 2
                            },
                            label: {
                                rotate: 'tangential'
                            }
                        },
                        {
                            r0: '35%',
                            r: '70%',
                            label: {
                                align: 'right'
                            }
                        },
                        {
                            r0: '70%',
                            r: '80%',
                            label: {
                                position: 'outside',
                                padding: 3,
                                silent: false
                            },
                            itemStyle: {
                                borderWidth: 3
                            }
                        }
                    ],
                    label: {
                        show: true,
                        formatter: function (param) {
                            return param.name;
                        },
                        fontSize: 12,
                        minAngle: 5
                    },
                    itemStyle: {
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    animationType: 'expansion',
                    animationDuration: 1000,
                    animationEasing: 'cubicInOut',
                    animationDurationUpdate: 500,
                    animationEasingUpdate: 'cubicInOut',
                }
            };

            newChart.setOption(option);

            // newChart.on('click', function (params) {
            //     if (params.dataType === 'node') {
            //         const path = params.treePathInfo.map(item => item.name);
            //         const newData = processedData.map(sector => {
            //             if (sector.name === path[0]) {
            //                 return {
            //                     ...sector,
            //                     children: sector.children.map(industry => {
            //                         if (industry.name === path[1] || path.length === 1) {
            //                             return industry;
            //                         }
            //                         return {
            //                             ...industry,
            //                             children: []
            //                         };
            //                     })
            //                 };
            //             }
            //             return {
            //                 ...sector,
            //                 children: []
            //             };
            //         });
            //         newChart.setOption({
            //             series: {
            //                 data: newData
            //             }
            //         });
            //     }
            // });
        }

        return () => {
            if (chart) {
                chart.dispose();
            }
        };
    }, [processedData]);

    useEffect(() => {
        const handleResize = () => {
            chart && chart.resize();
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [chart]);

    return (
        <ChartContainer>
            <div ref={chartRef} style={{ width: '100%', height: '100%' }} />
        </ChartContainer>
    );

};

