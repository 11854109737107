// LiveNewsComponent.js

import React, { useState, useEffect } from 'react';
import { List, Typography, Space, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { fetchLiveNews } from '../api';

const { Title, Text } = Typography;

const LiveNewsComponent = () => {
    const [news, setNews] = useState([]);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const data = await fetchLiveNews();
                setNews(data.items.slice(0, 10)); // 최신 10개 뉴스 표시
            } catch (error) {
                console.error('Error fetching live news:', error);
            }
        };
        fetchNews();
        const interval = setInterval(fetchNews, 60000);
        return () => clearInterval(interval);
    }, []);

    const renderNewsItem = (item) => (
        <List.Item style={{ padding: '8px 0' }}>
            <Space direction="vertical" size={0} style={{ width: '100%' }}>
                <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ fontSize: '14px', fontWeight: 'bold' }}>
                    {item.title}
                </a>
            </Space>
        </List.Item>
    );

    return (
        <div style={{ marginBottom: '20px', background: '#f0f2f5', padding: '12px', borderRadius: '8px' }}>
            <Row align="middle" style={{ marginBottom: '10px' }}>
                <Col>
                    <Title level={4} style={{ margin: 0 }}>
                        <span style={{ color: 'red', marginRight: '10px' }}>●</span>
                        {t('Live News')}
                    </Title>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={12}>
                    <List
                        itemLayout="vertical"
                        dataSource={news.slice(0, 5)}
                        renderItem={renderNewsItem}
                        size="small"
                    />
                </Col>
                <Col span={12}>
                    <List
                        itemLayout="vertical"
                        dataSource={news.slice(5, 10)}
                        renderItem={renderNewsItem}
                        size="small"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default LiveNewsComponent;