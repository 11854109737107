import React, { useState, useEffect, useRef } from 'react';
import { Typography, Row, Col, List, Card, Button, Pagination, Tag, Popover } from 'antd';
import * as echarts from 'echarts';
import { fetchGeneralNews, fetchRelatedCharacters } from '../api';
import 'echarts-wordcloud';
import { useStock } from '../StockContext';
const { Title, Text } = Typography;


const ITEMS_PER_PAGE = 5;

const GeneralNewsCloud = () => {
    const [newsData, setNewsData] = useState({ mainClusters: [], miscClusters: [] });
    const [selectedCluster, setSelectedCluster] = useState(null);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const chartRef = useRef(null);
    const [chartInstance, setChartInstance] = useState(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [relatedCharacters, setRelatedCharacters] = useState([]);
    const { handleSearch } = useStock();
    useEffect(() => {
        if (selectedCluster) {
            const startIndex = (page - 1) * ITEMS_PER_PAGE;
            const endIndex = startIndex + ITEMS_PER_PAGE;
            setCurrentItems(selectedCluster.items.slice(startIndex, endIndex));
            fetchRelatedCharactersForCluster(selectedCluster.clusterName);
        }
    }, [selectedCluster, page]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };
    useEffect(() => {
        fetchNews();
    }, []);


    useEffect(() => {
        if (chartRef.current && !chartInstance) {
            const chart = echarts.init(chartRef.current);
            setChartInstance(chart);
        }

        const handleResize = () => {
            if (chartInstance) {
                chartInstance.resize();
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            if (chartInstance) {
                chartInstance.dispose();
            }
        };
    }, [chartRef.current, chartInstance]);
    // useEffect(() => {
    //     if (chartRef.current && !chartInstance) {
    //         const newChart = echarts.init(chartRef.current);
    //         setChartInstance(newChart);
    //     }

    //     return () => {
    //         if (chartInstance) {
    //             chartInstance.dispose();
    //         }
    //     };
    // }, []);

    const fetchRelatedCharactersForCluster = async (clusterName) => {
        try {
            const data = await fetchRelatedCharacters(clusterName);
            setRelatedCharacters(data || []);
        } catch (error) {
            console.error('Error fetching related characters:', error);
            setRelatedCharacters([]);
        }
    };


    const handleMiscellaneousClick = () => {
        const miscItems = newsData.miscClusters.flatMap(cluster => cluster.items);
        setSelectedCluster({ clusterName: "Other News", items: miscItems });
        setPage(1);
    };

    const fetchNews = async () => {
        setLoading(true);
        try {
            const data = await fetchGeneralNews();
            const { mainClusters, miscClusters } = groupNewsByCluster(data.items);
            setNewsData({ mainClusters, miscClusters });
            if (mainClusters.length > 0) {
                setSelectedCluster(mainClusters[0]);
                fetchRelatedCharactersForCluster(mainClusters[0].clusterName);

            }
        } catch (error) {
            console.error('Error fetching news:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (chartInstance && newsData.mainClusters.length > 0) {
            chartInstance.resize();

            const wordcloudData = newsData.mainClusters.map(item => {
                let displayName = item.clusterName;
                const words = displayName.split(' ');
                if (words.length > 5) {
                    displayName = words.slice(0, 5).join(' ') + '...';
                }
                return {
                    name: displayName,
                    value: item.value,
                    fullName: item.clusterName // 전체 이름을 저장
                };
            });
            const option = {
                series: [{
                    type: 'wordCloud',
                    shape: 'circle',
                    left: 'center',
                    top: 'center',
                    width: '100%',
                    height: '100%',
                    right: null,
                    bottom: null,
                    sizeRange: [12, 50],
                    rotationRange: [0, 0],
                    rotationStep: 0,
                    gridSize: 15,
                    drawOutOfBound: false,
                    layoutAnimation: true,
                    textStyle: {
                        fontFamily: '"Noto Sans KR", "Roboto", sans-serif',
                        fontWeight: 'normal',
                        color: function () {
                            return 'rgb(' + [
                                Math.round(Math.random() * 160),
                                Math.round(Math.random() * 160),
                                Math.round(Math.random() * 160)
                            ].join(',') + ')';
                        }
                    },
                    emphasis: {
                        focus: 'self',
                        textStyle: {
                            shadowBlur: 10,
                            shadowColor: '#333'
                        }
                    },
                    data: wordcloudData
                }]
            };

            console.log(option)
            chartInstance.setOption(option);

            chartInstance.on('click', function (params) {
                handleWordClick(params.data);
            });
        }
    }, [newsData, chartInstance]);

    const groupNewsByCluster = (items) => {
        const grouped = items.reduce((acc, item) => {
            const clusterName = item.cluster_name?.trim();
            if (clusterName && clusterName !== '') {
                if (!acc[clusterName]) {
                    acc[clusterName] = [];
                }
                acc[clusterName].push(item);
            }
            return acc;
        }, {});

        const mainClusters = [];
        const miscClusters = [];

        Object.entries(grouped).forEach(([clusterName, items]) => {
            const cluster = { clusterName, items, value: items.length };

            if (clusterName.includes('Miscellaneous') || clusterName.toLowerCase().includes('etc') || items.length < 3) {
                miscClusters.push(cluster);
            } else {
                mainClusters.push(cluster);
            }
        });

        return {
            mainClusters: mainClusters.sort((a, b) => b.items.length - a.items.length),
            miscClusters
        };
    };
    const handleWordClick = (word) => {
        console.log(word);
        const cluster = newsData.mainClusters.find(item => item.clusterName === word.fullName);
        setSelectedCluster(cluster);
        setPage(1);
    };

    // const handlePageChange = (event, value) => {
    //     setPage(value);
    // };
    const handleCharacterClick = (character) => {
        handleSearch('business', character);
    };


    if (loading) {
        return <Typography>Loading news...</Typography>;
    }

    const RelatedCharactersContent = ({ characters }) => (
        <List
            size="small"
            dataSource={characters}
            renderItem={(item) => (
                <List.Item>
                    <a onClick={() => handleCharacterClick(item)}>{item}</a>
                </List.Item>
            )}
        />
    );
    return (
        <div style={{ padding: '24px' }}>
            <Row gutter={24}>
                <Col xs={24} md={16}>
                    <div ref={chartRef} style={{ width: '100%', height: '550px', border: '1px solid #d9d9d9', borderRadius: '2px' }} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                        <Button type="primary" onClick={handleMiscellaneousClick}>
                            Other News
                        </Button>
                    </div>
                </Col>
                <Col xs={24} md={8}>
                    <Card>
                        {selectedCluster && (
                            <>
                                {relatedCharacters.length > 0 && (
                                    <div style={{ marginBottom: '16px', display: 'flex', alignItems: 'center' }}>
                                        <Text strong style={{ marginRight: '8px' }}>Related Searches:</Text>
                                        <Popover
                                            content={<RelatedCharactersContent characters={relatedCharacters} />}
                                            title="Related Searches : "
                                            trigger="click"
                                        >
                                            <Button size="small">View</Button>
                                        </Popover>
                                    </div>
                                )}
                                <Title level={4}>{selectedCluster.clusterName}</Title>

                                <List
                                    dataSource={currentItems}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={<a href={item.url} target="_blank" rel="noopener noreferrer">{item.title}</a>}
                                                description={
                                                    <Text type="secondary">
                                                        {item.datasource}
                                                    </Text>
                                                }
                                            />
                                        </List.Item>
                                    )}
                                />
                                {selectedCluster.items.length > ITEMS_PER_PAGE && (
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                                        <Pagination
                                            total={selectedCluster.items.length}
                                            pageSize={ITEMS_PER_PAGE}
                                            current={page}
                                            onChange={handlePageChange}
                                            showSizeChanger={false}
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </Card>
                </Col>
            </Row>
        </div>
    );
};


export default GeneralNewsCloud;