import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Paper, CircularProgress, Box, Typography } from '@mui/material';
import { fetchOverallPhraseTrads } from '../api';
import { useStock } from '../StockContext';


ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
const OverallKeywordTrendsChart = ({ phrase, minYear }) => {
    const [chartData, setChartData] = useState(null);
    const { showError } = useStock();

    useEffect(() => {
        const fetchData = async () => {
            try {
                // 티커를 지정하지 않고 전체 데이터를 요청
                const data = await fetchOverallPhraseTrads(phrase, minYear)

                const labels = data.map(item => `${item.year} Q${item.quarter}`);
                const frequencies = data.map(item => item.frequency);

                setChartData({
                    labels,
                    datasets: [
                        {
                            label: phrase,
                            data: frequencies,
                            borderColor: 'rgb(75, 192, 192)',
                            tension: 0.1
                        }
                    ]
                });
            } catch (error) {
                console.error('Failed to fetch overall keyword trends:', error);
                showError(`Failed to fetch data: ${error.message}`);
            }
        };

        fetchData();
    }, [phrase, minYear]);

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Overall Keyword Trends',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `Frequency: ${context.parsed.y}`;
                    }
                }
            }
        },
    };


    const LoadingChart = () => {
        return (
            <Paper elevation={3} sx={{ p: 3, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 300 }}>
                <CircularProgress size={60} thickness={4} />
                <Box sx={{ mt: 2 }}>
                    <Typography variant="h6" gutterBottom>
                        Loading Chart Data
                    </Typography>
                </Box>
            </Paper>
        );
    };

    if (!chartData) return <LoadingChart />;

    return (
        <Line data={chartData} options={options} />
    );
};

export default OverallKeywordTrendsChart;