import React from 'react';
import { useTranslation } from 'react-i18next';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Language as LanguageIcon } from '@mui/icons-material';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value);
    };

    const languages = [
        { code: 'en', name: 'English', flag: '🇺🇸' },
        { code: 'ko', name: '한국어', flag: '🇰🇷' },
        { code: 'zh', name: '中文', flag: '🇨🇳' },
        { code: 'ja', name: '日本語', flag: '🇯🇵' },
        { code: 'de', name: 'Deutsch', flag: '🇩🇪' },
        { code: 'es', name: 'Español', flag: '🇪🇸' },
    ];

    return (
        <FormControl sx={{ minWidth: 120 }} size='small'>
            <InputLabel id="language-select-label" sx={{ color: '#4a90e2' }}>
                <LanguageIcon sx={{ mr: 1, color: '#4a90e2' }} />
                Language
            </InputLabel>
            <Select
                labelId="language-select-label"
                value={i18n.language}
                label="Language"
                onChange={changeLanguage}
                sx={{
                    color: '#4a90e2',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#4a90e2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#3a78c2',
                    },
                }}
            >
                {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                        {lang.flag} {lang.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LanguageSwitcher;