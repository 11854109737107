import { Route, Routes, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Navigation from './Navigation';
import WatchlistComponent from './WatchlistComponent';
import IdeaDiscoveryComponent from './IdeaDiscoveryComponent';
import SearchResultComponent from './SearchResultComponent';
import IndividualStockComponent from './IndividualStockComponent';
import Header from './Header';
import { Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import './i18n';
import UnifiedAIChat from './UnifiedAIChat';
import { useStock } from './StockContext';
import Login from './components/Login';
import Signup from './components/Singup';
import ErrorSnackbar from './components/ErrorSnackbar';
import { useTranslation } from 'react-i18next';

// Custom hook to detect screen orientation
const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(window.screen.orientation.type);

    useEffect(() => {
        const handleChange = () => {
            setOrientation(window.screen.orientation.type);
        };
        window.addEventListener('orientationchange', handleChange);
        return () => window.removeEventListener('orientationchange', handleChange);
    }, []);

    return orientation;
};

// Custom hook to detect if the device is mobile
const useIsMobile = () => {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768); // You can adjust this threshold
        };
        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    return isMobile;
};

function AppContent() {
    const { chatWidth, isNavCollapsed, setIsNavCollapsed, errorSnackbar, setErrorSnackbar } = useStock();
    const orientation = useScreenOrientation();
    const isMobile = useIsMobile();
    const [showOrientationDialog, setShowOrientationDialog] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (isMobile && orientation.includes('portrait')) {
            setShowOrientationDialog(true);
        } else {
            setShowOrientationDialog(false);
        }
    }, [isMobile, orientation]);

    const handleErrorSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setErrorSnackbar({ ...errorSnackbar, open: false });
    };

    const handleOrientationDialogClose = () => {
        setShowOrientationDialog(false);
    };

    return (
        <div style={{ display: 'flex', overflow: 'hidden' }}>
            <Navigation isCollapsed={isNavCollapsed} setIsCollapsed={setIsNavCollapsed} />
            <Header />

            <div style={{
                flexGrow: 1,
                marginLeft: isNavCollapsed ? '60px' : '220px',
                marginRight: `${chatWidth}px`,
                padding: '10px',
                minHeight: '100vh',
                boxSizing: 'border-box',
                maxWidth: `calc(100% - ${isNavCollapsed ? '60px' : '220px'} - ${chatWidth}px)`,
                transition: 'margin-left 0.3s, width 0.3s',
                overflowX: 'auto',
            }}>
                <Box sx={{ marginTop: '39px', padding: '5px', paddingTop: '20px' }}>
                    <Routes>
                        <Route path="/" element={<Navigate to="/idea-discovery" replace />} />
                        <Route path="/watchlist" element={<WatchlistComponent />} />
                        <Route path="/idea-discovery" element={<IdeaDiscoveryComponent />} />
                        <Route path="/idea-analysis" element={<SearchResultComponent />} />
                        <Route path="/stock/:symbol" element={<IndividualStockComponent />} />
                        <Route path="/feedback" element={<Navigate to="https://forms.gle/ui7G2EL5r9zKKZ9M9" replace />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/signup" element={<Signup />} />
                    </Routes>
                </Box>
                <ErrorSnackbar
                    open={errorSnackbar.open}
                    message={errorSnackbar.message}
                    onClose={handleErrorSnackbarClose}
                />
            </div>
            <UnifiedAIChat />

            <Dialog
                open={showOrientationDialog}
                onClose={handleOrientationDialogClose}
                aria-labelledby="orientation-dialog-title"
                aria-describedby="orientation-dialog-description"
            >
                <DialogTitle id="orientation-dialog-title">{"화면 방향 안내"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="orientation-dialog-description">
                        {t("Turn your device to landscape for a better user experience.")}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleOrientationDialogClose}>OK</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AppContent;
