import React, { useState, useEffect, useCallback } from 'react';
import { Box, Tabs, Tab, Divider, Typography, Paper, CircularProgress, Button, ButtonGroup, Grid, Card, CardContent, TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import TradingViewWidget from './components/TradingViewWidget';
import FinancialStatement from './components/FinancialStatement';
import NewsSearch from './components/NewsSearchComponent';
import { fetchCompanyDescription } from './api';
import { useTranslation } from 'react-i18next';
import KeywordTrendsChart from './components/KeywordTrendsChart';
import { useStock } from './StockContext';
import EarningCallList from './components/EarningCallList';
import { fetchPriceDiffSymbol } from './api';
import DividendComponent from './components/DividendComponent';
import EarningsComponent from './components/EarningsComponent';
import StockHistoryComponent from './components/StockHistoryComponent';
import { supabase } from './supabaseClient';
import { useAuth } from './AuthContext';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import { fetchCorpInfo } from './api';


const IndividualStockComponent = () => {
    const { symbol } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useState(new URLSearchParams(location.search));
    const [summary, setSummary] = useState('');
    const { selectedStock, searchedQueryValue, searchMode, setSelectedStock, showError } = useStock();
    const [priceDiff, setPriceDiff] = useState(null);
    const [isWatchlisted, setIsWatchlisted] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [innerTab, setInnerTab] = useState(0);
    const { user } = useAuth();


    const updateUrlParams = useCallback((newActiveTab, newInnerTab) => {
        const newParams = new URLSearchParams();
        if (newActiveTab !== undefined) newParams.set('tab', newActiveTab.toString());
        if (newInnerTab !== undefined) newParams.set('innerTab', newInnerTab.toString());
        setSearchParams(newParams);
    }, []);

    useEffect(() => {
        navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    }, [searchParams, navigate, location.pathname]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const stockData = await fetchCorpInfo(symbol);
                setSelectedStock(stockData);

                const priceDiffData = await fetchPriceDiffSymbol(symbol);
                setPriceDiff(priceDiffData);

                if (user) {
                    const { data, error } = await supabase
                        .from('watchlist')
                        .select('*')
                        .eq('user_id', user.id)
                        .eq('symbol', symbol)
                        .single();

                    setIsWatchlisted(!!data);
                }
            } catch (error) {
                showError(`Failed to fetch data: ${error.message}`);
            }
        };

        fetchData();
        setActiveTab(0);
        setInnerTab(0);
        updateUrlParams(0, 0);

        // 컴포넌트 언마운트 시 정리 함수
        return () => {
            // 필요한 정리 작업 수행
        };
    }, [symbol, updateUrlParams]);


    useEffect(() => {
        // URL 파라미터에서 탭 상태를 읽어옵니다.
        const tabParam = searchParams.get('tab');
        const innerTabParam = searchParams.get('innerTab');
        if (tabParam) setActiveTab(parseInt(tabParam));
        if (innerTabParam) setInnerTab(parseInt(innerTabParam));
    }, [searchParams]);

    const toggleWatchlist = async () => {
        if (!user) {
            alert('Please login to add to watchlist');
            return;
        }

        if (isWatchlisted) {
            const { error } = await supabase
                .from('watchlist')
                .delete()
                .eq('user_id', user.id)
                .eq('symbol', symbol);

            if (!error) {
                setIsWatchlisted(false);
            }
        } else {
            const { error } = await supabase
                .from('watchlist')
                .insert({ user_id: user.id, symbol: symbol });

            if (!error) {
                setIsWatchlisted(true);
            }
        }
    };

    const fetchDescription = async () => {
        if (!symbol) return;
        try {
            const data = await fetchCompanyDescription(symbol);
            setSummary(data.description);
        } catch (error) {
            showError(`Failed to fetch data: ${error.message}`);
            setSummary('Failed to load summary.');
        }
    };

    useEffect(() => {
        fetchDescription();
    }, [symbol]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
        updateUrlParams(newValue, 0);  // 메인 탭 변경 시 내부 탭 초기화
    };

    const handleInnerTabChange = (newValue) => {
        setInnerTab(newValue);
        updateUrlParams(activeTab, newValue);
    };


    if (!selectedStock) {
        return <Typography>Loading...</Typography>;
    }




    const PriceChangeItem = ({ title, value }) => {

        return (
            <Grid item xs={6} sm={4} md={3}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2" sx={{ mr: 1 }}>{title}:</Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            color: value > 0 ? 'success.main' : 'error.main',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        {value > 0
                            ? <ArrowUpwardIcon fontSize="small" sx={{ mr: 0.5 }} />
                            : <ArrowDownwardIcon fontSize="small" sx={{ mr: 0.5 }} />
                        }
                        {Math.abs(value).toFixed(2)}%
                    </Typography>
                </Box>
            </Grid>
        );
    };

    const PriceChangeSummary = ({ priceDiff }) => {
        const periods = [
            { key: 'one_day', title: '1 Day' },
            { key: 'five_days', title: '5 Days' },
            { key: 'one_month', title: '1 Month' },
            { key: 'three_months', title: '3 Months' },
            { key: 'six_months', title: '6 Months' },
            { key: 'ytd', title: 'YTD' },
            { key: 'one_year', title: '1 Year' },
            { key: 'three_years', title: '3 Years' },
            { key: 'five_years', title: '5 Years' },
            { key: 'ten_years', title: '10 Years' },
        ];

        return (
            <Card sx={{ mb: 4 }}>
                <CardContent>
                    <Grid container spacing={2}>
                        {periods.map((period) => (
                            <PriceChangeItem
                                key={period.key}
                                title={period.title}
                                value={priceDiff[period.key]}
                            />
                        ))}
                    </Grid>
                </CardContent>
            </Card>
        );
    };

    return (
        <Box sx={{ width: '100%', maxWidth: '1200px', margin: 'auto', padding: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" gutterBottom>{selectedStock.name} ({selectedStock.symbol}:{selectedStock.exchangeShortName})</Typography>
                <Button
                    onClick={toggleWatchlist}
                    startIcon={isWatchlisted ? <StarIcon /> : <StarBorderIcon />}
                >
                    {isWatchlisted ? 'Remove from Watchlist' : 'Add to Watchlist'}
                </Button>
            </Box>
            <Typography variant="h6" gutterBottom>{selectedStock.country}/{selectedStock.sector}/{selectedStock.industry}</Typography>
            <Divider sx={{ mb: 2 }} />

            <Tabs sx={{
                mb: 2,
                '& .MuiTabs-flexContainer': {
                    flexWrap: 'wrap',
                },
                '& .MuiTab-root': {
                    minWidth: { xs: '100%', sm: 'auto' },
                }
            }} value={activeTab} onChange={handleTabChange}>
                <Tab label="Summary" />
                <Tab label="Price" />
                <Tab label="Price Narrative" />
                <Tab label="Financials" />
                <Tab label="News" />
                <Tab label="Investor Relation" />
                <Tab label="Dividend" />
                <Tab label="Earning" />
            </Tabs>

            <Box sx={{ mt: 2 }}>
                {activeTab === 0 && (
                    <Box>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h6" gutterBottom>{t("Company Description")}</Typography>
                            {summary ? (

                                <Paper elevation={3} sx={{ p: 2, backgroundColor: '#f5f5f5' }}>
                                    <Typography variant="body1">{summary}</Typography>
                                </Paper>) : <CircularProgress />}

                        </Box>
                        {/* {searchMode === 'business' && (
                            <Box sx={{ mb: 4 }}>
                                <AIRelationSummary
                                    symbol={symbol}
                                    query={searchedQueryValue}
                                />
                            </Box>
                        )} */}
                        {searchMode === 'keyword' && (
                            <Box sx={{ mb: 4 }}>
                                <Typography variant="h6" gutterBottom>{t("Phrase Trends")}</Typography>
                                <KeywordTrendsChart
                                    symbol={symbol}
                                    phrase={searchedQueryValue}
                                    minYear={2018}
                                />
                            </Box>
                        )}
                    </Box>
                )}
                {activeTab === 1 && (
                    <Box>
                        <Typography variant="h6" gutterBottom>{t("Price Changes")}</Typography>
                        {priceDiff ? (
                            <PriceChangeSummary priceDiff={priceDiff} />
                        ) : (
                            <CircularProgress />
                        )}
                        <Box sx={{ width: '100%', height: '500px' }}>
                            <TradingViewWidget
                                symbol={symbol}
                                exchangeShortName={selectedStock.exchangeShortName}
                            />
                        </Box>
                    </Box>
                )}
                {activeTab === 2 && (
                    <Box>
                        <StockHistoryComponent symbol={symbol} />
                    </Box>
                )}
                {activeTab === 3 && (
                    <Box>
                        <ButtonGroup
                            variant="outlined"
                            aria-label="outlined button group"
                            sx={{ mb: 2, flexWrap: 'wrap' }}
                        >
                            <Button onClick={() => handleInnerTabChange(0)}>Income Statement</Button>
                            <Button onClick={() => handleInnerTabChange(1)}>Balance Sheet</Button>
                            <Button onClick={() => handleInnerTabChange(2)}>Cash Flow Statement</Button>
                        </ButtonGroup>
                        <Box sx={{ mt: 2 }}>
                            {innerTab === 0 && <FinancialStatement symbol={symbol} statementType="income-statement" />}
                            {innerTab === 1 && <FinancialStatement symbol={symbol} statementType="balance-sheet" />}
                            {innerTab === 2 && <FinancialStatement symbol={symbol} statementType="cash-flow" />}
                        </Box>
                    </Box>
                )}
                {activeTab === 4 && (
                    <Box>
                        <NewsSearch symbol={symbol} />
                    </Box>
                )}
                {activeTab === 5 && (
                    <Box>
                        <Tabs value={0}>
                            <Tab label="Earning Calls" />
                            <Tab disabled label="Company Events" />
                            <Tab disabled label="Conferences" />
                        </Tabs>
                        <EarningCallList symbol={symbol} />
                    </Box>
                )}
                {activeTab === 6 && (
                    <Box>
                        <DividendComponent symbol={symbol} />
                    </Box>
                )}
                {activeTab === 7 && (
                    <Box>
                        <EarningsComponent symbol={symbol} />
                    </Box>
                )}

            </Box>
        </Box >
    );
};

export default IndividualStockComponent;