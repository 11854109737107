import React, { useState, useEffect } from 'react';
import { Paper, Typography, Grid, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const FinancialOverview = ({ data }) => {
    const { t } = useTranslation();
    const [overview, setOverview] = useState({
        totalMarketCap: 0,
        companyCount: 0,
        pe_ratio: { median: 0, mean: 0, distribution: [] },
        price_to_book_ratio: { median: 0, mean: 0, distribution: [] },
        dividend_yield: { median: 0, mean: 0, distribution: [] },
        revenue_yoy: { median: 0, mean: 0, distribution: [] }
    });

    useEffect(() => {
        calculateOverview();
    }, [data]);

    const calculateOverview = () => {
        const validData = data.filter(item => item.market_cap);

        const totalMarketCap = validData.reduce((sum, item) => sum + item.market_cap, 0);
        const metrics = ['market_cap', 'pe_ratio', 'price_to_book_ratio', 'dividend_yield', 'revenue_yoy'];

        const overview = metrics.reduce((acc, metric) => {
            const values = validData.filter(item => item[metric]).map(item => item[metric]);
            const sorted = values.sort((a, b) => a - b);
            const median = calculateMedian(sorted);
            const mean = calculateMean(sorted);
            const distribution = calculateDistribution(sorted, metric);

            acc[metric] = { median, mean, distribution };
            return acc;
        }, {});

        console.log(overview)
        overview.totalMarketCap = totalMarketCap;
        overview.companyCount = data.length;

        setOverview(overview);
    };

    const calculateMedian = (arr) => {
        const mid = Math.floor(arr.length / 2);
        return arr.length % 2 !== 0 ? arr[mid] : (arr[mid - 1] + arr[mid]) / 2;
    };

    const calculateMean = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;

    const calculateDistribution = (arr, metric) => {

        // 기존의 계산 로직
        const min = Math.min(...arr);
        const max = Math.max(...arr);
        const range = max - min;
        const bucketSize = range / 10;
        console.log(metric, min, arr)
        const distribution = Array(10).fill(0);
        arr.forEach(value => {
            const index = Math.min(Math.floor((value - min) / bucketSize), 9);
            distribution[index]++;
        });

        return distribution.map((count, i) => ({
            range: `${formatNumber(min + i * bucketSize)} - ${formatNumber(min + (i + 1) * bucketSize)}`,
            count
        }));
    };

    const formatNumber = (num) => {
        if (num === undefined || num === null || isNaN(num)) {
            return 'N/A';
        }
        return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
    };

    const formatLargeNumber = (num) => {
        if (num >= 1000000) {
            return `${formatNumber(Math.round(num / 1000000))}T $`;
        } else {
            return `${formatNumber(Math.round(num / 1000))}B $`;
        }
    };

    // const TotalMarketCapBox = () => (
    //     <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
    //         <Typography variant="subtitle2">Total Market Cap</Typography>
    //         <Typography variant="body1">{formatLargeNumber(overview.totalMarketCap)}</Typography>
    //         <MetricBox title="Market Cap Distribution" metric={overview.market_cap} unit=" $" hideTitle />
    //     </Box>
    // );

    const CompaniesBox = () => {
        const percentage = ((overview.companyCount / 6554) * 100).toFixed(1);
        return (
            <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, height: '100%' }}>
                <Typography variant="subtitle2">Companies</Typography>
                <Typography variant="body1">{formatNumber(overview.companyCount)}</Typography>
                <Typography variant="body2">({percentage}% of 6554 total companies)</Typography>
            </Box>
        );
    };
    const MetricBox = ({ title = '', metric = {}, unit = '', showTotal = false }) => (
        <Box sx={{ p: 2, border: '1px solid #ddd', borderRadius: 2, height: '100%' }}>
            <Typography variant="subtitle2">{title}</Typography>
            {showTotal && (
                <Typography variant="body1">{formatLargeNumber(overview.totalMarketCap)}</Typography>
            )}
            {metric && metric.median !== undefined && (
                <>
                    <Typography variant="body2">Median: {title === 'Total Market Cap' ? formatLargeNumber(metric.median) : `${formatNumber(metric.median)}${unit}`}</Typography>
                    <Typography variant="body2">Mean: {title === 'Total Market Cap' ? formatLargeNumber(metric.mean) : `${formatNumber(metric.mean)}${unit}`}</Typography>
                    {/* {metric.distribution && metric.distribution.length > 0 && (
                        <ResponsiveContainer width="100%" height={100} style={{ marginTop: '16px' }}>
                            <BarChart data={metric.distribution}>
                                <XAxis dataKey="range" hide />
                                <YAxis
                                    axisLine={false}
                                    tickLine={false}
                                    tick={{ fontSize: 10 }}
                                    tickCount={5}
                                />
                                <Tooltip />
                                <CartesianGrid vertical={false} />
                                <Bar dataKey="count" fill="#8884d8" />
                            </BarChart>
                        </ResponsiveContainer>
                    )} */}
                </>
            )}
        </Box>
    );

    return (
        <Paper elevation={3} sx={{ p: 2, mb: 3 }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} mb={4}>
                    <MetricBox title="Total Market Cap" metric={overview.market_cap} unit="M $" showTotal={true} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={4}>
                    <CompaniesBox />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={4}>
                    <MetricBox title="PER" metric={overview.pe_ratio} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={4}>
                    <MetricBox title="PBR" metric={overview.price_to_book_ratio} />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={4}>
                    <MetricBox title="Dividend Yield" metric={overview.dividend_yield} unit="%" />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mb={4}>
                    <MetricBox title="Revenue YOY" metric={overview.revenue_yoy} unit="%" />
                </Grid>
            </Grid>
            <Typography variant="caption" sx={{ mt: 2, display: 'block' }}>
                {t("data_description")}
            </Typography>
        </Paper>
    );
};


export default FinancialOverview;