import React, { useState, useEffect, useCallback } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    TextField, Box, Tooltip, IconButton, Typography, Pagination, Select, MenuItem, Tabs, Tab, Chip, Link, Card, CardContent, Divider, Grid
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import ReactECharts from 'echarts-for-react';
import { fetchProductSalesData, fetchPriceDiffSymbols } from '../api';
import { useTranslation } from 'react-i18next';
import { Checkbox, Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import * as XLSX from 'xlsx';
import { categories, columnTranslations, businessSearchExamples, keywordSearchExamples } from '../constants';
import { useStock } from '../StockContext';
import { fetchRelatedChunks, fetchRelatedNews, fetchTagStastic, fetchMarketCapData } from '../api';
import EarningCallModal from './EarningCallModal';
import SearchIcon from '@mui/icons-material/Search';
import { supabase } from '../supabaseClient';
import { useAuth } from '../AuthContext';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Alert from '@mui/material/Alert';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MarketCapChart from './MarketCapChart';
import FinancialOverview from './FinancialOverview';

const getMarketCapInfo = (marketCap) => {
    if (marketCap < 50) return { label: 'Nano Cap', color: 'default' };
    if (marketCap < 300) return { label: 'Micro Cap', color: 'primary' };
    if (marketCap < 2000) return { label: 'Small Cap', color: 'secondary' };
    if (marketCap < 10000) return { label: 'Mid Cap', color: 'success' };
    if (marketCap < 200000) return { label: 'Large Cap', color: 'warning' };
    return { label: 'Mega Cap', color: 'error' };
};

const formatMarketCap = (marketCap) => {
    if (marketCap >= 1000) {
        return `$${(marketCap / 1000).toFixed(2)}B`;
    }
    return `$${marketCap}M`;
};


const PriceOverviewCard = ({ tagStatistics }) => {
    const formatReturn = (value) => {
        const formattedValue = value.toFixed(2);
        const isPositive = value > 0;
        return (
            <Typography
                variant="h6"
                color={isPositive ? 'success.main' : 'error.main'}
                sx={{ display: 'flex', alignItems: 'center' }}
            >
                {isPositive ? <TrendingUpIcon sx={{ mr: 0.5 }} /> : <TrendingDownIcon sx={{ mr: 0.5 }} />}
                {formattedValue}%
            </Typography>
        );
    };

    const returnPeriods = [
        { label: '1D Avg. Return', value: '1d_avg' },
        { label: '5D Avg. Return', value: '5d_avg' },
        { label: '1M Avg. Return', value: '1m_avg' },
        { label: '3M Avg. Return', value: '3m_avg' },
        { label: '6M Avg. Return', value: '6m_avg' },
        { label: '1Y Avg. Return', value: '1y_avg' },
        { label: '3Y Avg. Return', value: '3y_avg' },
    ];

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle2">Number of stocks</Typography>
                        <Typography variant="h6">{tagStatistics.count}</Typography>
                    </Grid>
                    {returnPeriods.map((period) => (
                        <Grid item xs={12} sm={6} md={3} key={period.value}>
                            <Typography variant="subtitle2">{period.label}</Typography>
                            {formatReturn(tagStatistics[period.value])}
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};


const LargeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    '& .MuiTooltip-tooltip': {
        maxWidth: '500px',
        fontSize: '14px',
        padding: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
});

const HighlightableTableRow = styled(TableRow)(({ theme, hasTooltip }) => ({
    cursor: hasTooltip ? 'pointer' : 'default',
    '&:hover': {
        backgroundColor: hasTooltip ? theme.palette.action.hover : 'inherit',
    },
}));


const HighlightedCompanyName = styled('span')(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.action.hover,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.action.selected,
    },
}));



const compactSelectStyle = {
    minWidth: '100px',
    fontSize: '0.75rem',
    '& .MuiSelect-select': {
        padding: '2px 0px 2px 8px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
    },
};

const commonCellStyle = {
    padding: '4px 8px',
    fontSize: '0.75rem',
    textAlign: 'center',
    verticalAlign: 'middle'
};

const headerCellStyle = {
    padding: '4px 8px',
    textAlign: 'center',
    verticalAlign: 'middle'
};



const StockTableComponent = ({
    data,
    searchMode,
    handleStockClick,
    searchedQueryValue,
    setSelectedSymbols,
    selectedSymbols
}) => {
    const { t } = useTranslation();
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [tickerFilter, setTickerFilter] = useState('');
    const [nameFilter, setNameFilter] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [paginatedData, setPaginatedData] = useState([]);
    const [productSalesData, setProductSalesData] = useState({});
    const [industryFilter, setIndustryFilter] = useState('');
    const [industries, setIndustries] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const {
        loading, translatedQueryValue,
        keywordQuery, handleSearch, setMarketCapData,
        marketCapData, setSelectedStock, showError, businessSubTab, setBusinessSubTab, setIsEarningCallOpen
    } = useStock();    // const [activeTooltip, setActiveTooltip] = useState(null);
    const [relatedChunks, setRelatedChunks] = useState({});
    const [relatedNews, setRelatedNews] = useState({});
    const [selectedChunkId, setSelectedChunkId] = useState(null);
    const [watchlist, setWatchlist] = useState({});
    const { user } = useAuth();
    const [tagStatistics, setTagStatistics] = useState(null);
    const [priceData, setPriceData] = useState({});

    useEffect(() => {
        const fetchPriceData = async () => {
            if (paginatedData.length > 0) {
                const symbols = paginatedData.map(item => item.symbol);
                try {
                    const fetchedData = await fetchPriceDiffSymbols(symbols);
                    const priceDataMap = fetchedData.reduce((acc, item) => {
                        acc[item.symbol] = item;
                        return acc;
                    }, {});
                    setPriceData(prevData => ({ ...prevData, ...priceDataMap }));
                } catch (error) {
                    console.error('Error fetching price data:', error);
                    showError(`Failed to fetch price data: ${error.message}`);
                }
            }
        };
        fetchPriceData();
    }, [paginatedData]);

    useEffect(() => {
        const fetchTagStats = async () => {
            if (searchedQueryValue) {
                try {
                    const stats = await fetchTagStastic(searchedQueryValue);
                    setTagStatistics(stats[0]);
                } catch (error) {
                    showError(`Error fetching tag statistics: ${error.message}`);

                }
            }
        };
        fetchTagStats();
    }, [data, searchedQueryValue]);

    const renderStocksSummary = () => {
        return (
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Stocks Summary</Typography>
                    <FinancialOverview data={data} />
                    {/* <Typography variant="h6" gutterBottom>Industry Value Chain</Typography> */}
                    {/* <SectorIndustrySunburst data={data} /> */}
                </CardContent>
            </Card>
        );
    };

    const renderPriceSummary = () => {
        return (
            <Card sx={{ mb: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>Price Overview</Typography>
                    {tagStatistics && <PriceOverviewCard tagStatistics={tagStatistics} />}
                    <Typography variant="h6" gutterBottom>Historical Market Cap</Typography>
                    {marketCapData.length > 0 ?
                        <MarketCapChart marketCapData={marketCapData} /> :
                        <Alert>Could not find any related MarketCap Data.</Alert>
                    }
                </CardContent>
            </Card>
        );
    };


    useEffect(() => {
        const fetchData = async () => {
            if (data.length <= 1000) {
                try {
                    const md = await fetchMarketCapData(data);
                    setMarketCapData(md);
                } catch (error) {
                    showError(`Error fetching market cap data: ${error.message}`);
                }
            }
        };
        const uniqueIndustries = [...new Set(data.map(item => item.industry))];
        setIndustries(uniqueIndustries);
        fetchData();
    }, [data]);



    useEffect(() => {
        const fetchTagStats = async () => {
            if (searchedQueryValue) {
                try {
                    const stats = await fetchTagStastic(searchedQueryValue);
                    setTagStatistics(stats[0]);
                } catch (error) {
                    showError(`Error fetching tag statistics: ${error.message}`);
                }
            }
        };
        fetchTagStats();
    }, [data, searchedQueryValue]);

    const toggleWatchlist = async (symbol) => {
        if (!user) {
            showError('Please log in to use the watchlist feature');
            return;
        }

        try {
            if (watchlist[symbol]) {
                // Remove from watchlist
                await supabase
                    .from('watchlist')
                    .delete()
                    .eq('user_id', user.id)
                    .eq('symbol', symbol);
            } else {
                // Add to watchlist
                await supabase
                    .from('watchlist')
                    .insert({ user_id: user.id, symbol });
            }

            // Update local state
            setWatchlist(prev => ({
                ...prev,
                [symbol]: !prev[symbol]
            }));
        } catch (error) {
            showError(`Failed to update watchlist: ${error.message}`);
        }
    };

    useEffect(() => {
        const fetchWatchlist = async () => {
            if (user) {
                try {
                    const { data, error } = await supabase
                        .from('watchlist')
                        .select('symbol')
                        .eq('user_id', user.id);

                    if (error) throw error;

                    const watchlistObj = data.reduce((acc, item) => {
                        acc[item.symbol] = true;
                        return acc;
                    }, {});

                    setWatchlist(watchlistObj);
                } catch (error) {
                    showError(`Failed to fetch watchlist: ${error.message}`);
                }
            }
        };

        fetchWatchlist();
    }, [user]);

    useEffect(() => {
        const fetchRelatedData = async () => {
            const allChunkIds = new Set();
            const allNewsIds = new Set();

            paginatedData.forEach(row => {
                if (row.relate_chunk_ids) {
                    row.relate_chunk_ids.split(',').forEach(id => allChunkIds.add(id.trim()));
                }
                if (row.relate_news_ids) {
                    row.relate_news_ids.split(',').forEach(id => allNewsIds.add(id.trim()));
                }
            });

            if (allChunkIds.size > 0) {
                try {
                    const chunks = await fetchRelatedChunks(Array.from(allChunkIds));
                    if (Array.isArray(chunks)) {
                        const chunksMap = chunks.reduce((acc, chunk) => {
                            acc[chunk.symbol] = acc[chunk.symbol] || [];
                            acc[chunk.symbol].push(chunk);
                            return acc;
                        }, {});
                        setRelatedChunks(chunksMap);
                    } else {
                        console.error('Unexpected chunks data structure:', chunks);
                    }
                } catch (error) {
                    console.error('Error fetching chunks:', error);
                }
            }

            if (allNewsIds.size > 0) {
                try {
                    const news = await fetchRelatedNews(Array.from(allNewsIds));
                    if (Array.isArray(news)) {
                        const newsMap = news.reduce((acc, newsItem) => {
                            acc[newsItem.symbol] = acc[newsItem.symbol] || [];
                            acc[newsItem.symbol].push(newsItem);
                            return acc;
                        }, {});
                        setRelatedNews(newsMap);
                    } else {
                        console.error('Unexpected news data structure:', news);
                    }
                } catch (error) {
                    console.error('Error fetching news:', error);
                }
            }
        };

        fetchRelatedData();
    }, [paginatedData]);



    useEffect(() => {
        applyTableFiltersAndSort();
    }, [data, tickerFilter, nameFilter, industryFilter, sortColumn, sortDirection]);

    useEffect(() => {
        applyPagination();
    }, [filteredData, page, perPage]);

    useEffect(() => {
        fetchProductSales();
    }, [paginatedData]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const downloadExcel = useCallback((columns) => {
        const headers = ['Symbol', 'Company Name', 'Industry', 'Reason', ...columns];
        if (searchMode === 'business' && searchedQueryValue) {
            headers.unshift('Relevance Rank');
        }
        if (searchMode === 'keyword' && searchedQueryValue) {
            headers.push('Mention Frequency');
        }

        const excelData = filteredData.map(row => {
            let rowData = {
                'Symbol': row.symbol,
                'Company Name': row.name,
                'Industry': row.industry,
                'Reason': row.reason || '',
            };
            columns.forEach(col => {
                rowData[t(col)] = row[col];
            });
            if (searchMode === 'business' && searchedQueryValue) {
                rowData['Relevance Rank'] = row.final_rank;
            }
            if (searchMode === 'keyword' && searchedQueryValue) {
                rowData['Mention Frequency'] = row.frequency;
            }
            return rowData;
        });

        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Stock Data");

        // Generate Excel file
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

        // Download file
        const fileName = 'stock_data.xlsx';
        if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }, [filteredData, searchMode, searchedQueryValue]);




    const fetchProductSales = async () => {
        const symbols = paginatedData.map(item => item.symbol);
        if (symbols.length > 0) {
            try {
                const data = await fetchProductSalesData(symbols);
                setProductSalesData(data);
            } catch (error) {
                console.error('Error fetching product sales data:', error);
                showError(`Failed to fetch data: ${error.message}`);

            }
        }
    };




    const applyPagination = () => {
        const startIndex = (page - 1) * perPage;
        const endIndex = startIndex + perPage;
        setPaginatedData(filteredData.slice(startIndex, endIndex));
    };

    const applyTableFiltersAndSort = () => {
        let result = [...data];

        // Apply filters
        if (tickerFilter) {
            result = result.filter(item =>
                item.symbol.toLowerCase().includes(tickerFilter.toLowerCase())
            );
        }
        if (nameFilter) {
            result = result.filter(item =>
                item.name.toLowerCase().includes(nameFilter.toLowerCase())
            );
        }
        if (industryFilter) {
            result = result.filter(item => item.industry === industryFilter);
        }
        // Apply sorting
        if (sortColumn) {
            result.sort((a, b) => {
                if (sortColumn === 'businessSimilarity') {
                    return sortDirection === 'asc'
                        ? (a.final_score || 0) - (b.final_score || 0)
                        : (b.final_score || 0) - (a.final_score || 0);
                }
                if (a[sortColumn] < b[sortColumn]) return sortDirection === 'asc' ? -1 : 1;
                if (a[sortColumn] > b[sortColumn]) return sortDirection === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setFilteredData(result);
    };


    const sortChunks = (chunks) => {
        return chunks.sort((a, b) => {
            if (a.year !== b.year) {
                return b.year - a.year;
            }
            return b.quarter - a.quarter;
        });
    };


    const handleSort = (column) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };


    const handlePageChange = (event, value) => {
        setPage(value);
    };


    const formatNumber = (num) => {
        if (num === undefined || num === null || isNaN(num)) {
            return '-';
        }
        return num.toLocaleString(undefined, { maximumFractionDigits: 2 });
    };

    const renderMainProducts = (productsString) => {
        try {
            const products = productsString
                .trim()
                .replace(/^\[|\]$/g, '')
                .split(',')
                .map(product => product.trim().replace(/^['"]|['"]$/g, ''))
                .filter(product => product.length > 0);

            return (
                <ul style={{
                    margin: 0,
                    paddingInlineStart: '20px',
                    listStylePosition: 'inside',
                    textAlign: 'left'
                }}>
                    {products.map((product, index) => (
                        <li key={index}>{product}</li>
                    ))}
                </ul>
            );
        } catch (error) {
            console.error('Error parsing main_products:', error);
            showError(`Failed to fetch data: ${error.message}`);
            return productsString;
        }
    };


    const renderProductSalesChart = (symbol) => {
        const formatNumber = (num) => {
            if (num >= 1e12) return (num / 1e12).toFixed(1) + 'T';
            if (num >= 1e9) return (num / 1e9).toFixed(1) + 'B';
            if (num >= 1e6) return (num / 1e6).toFixed(1) + 'M';
            if (num >= 1e3) return (num / 1e3).toFixed(1) + 'K';
            return num.toString();
        };
        const data = productSalesData[symbol];
        if (!data) return null;

        const chartData = Object.entries(data).map(([name, value]) => ({ name, value }));

        const option = {
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    return `${params.name}: $${formatNumber(params.value)}`;
                }
            },
            series: [
                {
                    type: 'pie',
                    radius: ['40%', '70%'],
                    height: 60,
                    width: 60,
                    label: {
                        show: false
                    },
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    },
                    data: chartData
                }
            ]
        };
        return (
            <ReactECharts
                option={option}
                style={{ height: '60px', width: '60px' }}
                opts={{ renderer: 'svg' }}
            />
        );
    };

    const renderRelevanceGauge = (score) => {
        const option = {
            series: [{
                type: 'gauge',
                startAngle: 180,
                endAngle: 0,
                min: 60,
                max: 100,
                splitNumber: 3,
                axisLine: {
                    lineStyle: {
                        width: 8,
                        color: [
                            [0.25, '#ff4500'],
                            [0.5, '#ffa500'],
                            [0.75, '#90ee90'],
                            [1, '#00ced1']
                        ]
                    }
                },
                pointer: {
                    icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                    length: '12%',
                    width: 20,
                    offsetCenter: [0, '-60%'],
                    itemStyle: {
                        color: 'auto'
                    }
                },
                axisTick: {
                    length: 12,
                    lineStyle: {
                        color: 'auto',
                        width: 2
                    }
                },
                splitLine: {
                    length: 20,
                    lineStyle: {
                        color: 'auto',
                        width: 5
                    }
                },
                axisLabel: {
                    color: '#464646',
                    fontSize: 15,
                    distance: -60,
                    formatter: function (value) {
                        if (value === 60) return 'Low';
                        if (value === 80) return 'High';
                        if (value === 100) return 'Very High';
                        return '';
                    }
                },
                title: {
                    offsetCenter: [0, '-20%'],
                    fontSize: 15
                },
                detail: {
                    fontSize: 30,
                    offsetCenter: [0, '0%'],
                    valueAnimation: true,
                    formatter: function (value) {
                        return Math.round(value);
                    },
                    color: 'auto'
                },
                data: [{
                    value: score,
                    name: 'Relevance'
                }]
            }]
        };

        return (
            <ReactECharts
                option={option}
                style={{ height: '200px', width: '200px' }}
            />
        );
    };


    const extendedCategories = [
        ...categories,
        { name: "Stocks Summary", component: renderStocksSummary },
        ...(searchMode === 'business' ? [{ name: "Price Summary", component: renderPriceSummary }] : [])
    ];


    const handleEarningCallClick = (chunkId, event) => {
        console.log(chunkId)
        event.stopPropagation(); // 이벤트 버블링 방지
        setSelectedChunkId(chunkId);
        setIsEarningCallOpen(true)
    };

    const handleCloseModal = () => {
        setSelectedChunkId(null);
        setIsEarningCallOpen(false)
    };


    const highlightSearchTerm = (text, searchTerm) => {
        if (!searchTerm) return text;
        const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
        return parts.map((part, index) =>
            part.toLowerCase() === searchTerm.toLowerCase() ?
                <mark key={index} style={{ backgroundColor: 'yellow' }}>{part}</mark> : part
        );
    };

    const BusinessSummaryCard = ({ row, relatedChunks, relatedNews, watchlist, toggleWatchlist, handleEarningCallClick, searchedQueryValue, highlightSearchTerm, priceData }) => {
        const renderPriceChange = (value) => {
            if (!value) return null;
            const isPositive = value > 0;
            return (
                <Typography
                    variant="body2"
                    color={isPositive ? 'success.main' : 'error.main'}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    {isPositive ? <TrendingUpIcon fontSize="small" sx={{ mr: 0.5 }} /> : <TrendingDownIcon fontSize="small" sx={{ mr: 0.5 }} />}
                    {value.toFixed(2)}%
                </Typography>
            );
        };
        const marketCapInfo = getMarketCapInfo(row.market_cap);
        const symbolPriceData = priceData[row.symbol];

        return (
            <Card sx={{ mb: 2, cursor: 'pointer' }} onClick={() => handleStockClick(row)}>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">
                            {highlightSearchTerm(row.name, searchedQueryValue)} ({row.symbol})
                        </Typography>
                        <Tooltip title={watchlist[row.symbol] ? "Remove from Watchlist" : "Add to Watchlist"}>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleWatchlist(row.symbol);
                                }}
                            >
                                {watchlist[row.symbol] ? <StarIcon color="primary" /> : <StarBorderIcon />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                        {row.industry}
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={9}>
                            <Typography variant="body1" paragraph>
                                {highlightSearchTerm(row.reason || 'No reason provided', searchedQueryValue)}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Box sx={{ borderLeft: '1px solid #e0e0e0', pl: 2, height: '100%' }}>
                                <Box mb={2}>
                                    <Typography variant="subtitle2">Market Cap:</Typography>
                                    <Chip
                                        label={`${marketCapInfo.label} (${formatMarketCap(row.market_cap)})`}
                                        color={marketCapInfo.color}
                                        size="small"
                                    />
                                </Box>
                                {symbolPriceData && (
                                    <Box>
                                        <Typography variant="subtitle2">Price Changes:</Typography>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body2">1D:</Typography>
                                            {renderPriceChange(symbolPriceData.one_day)}
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body2">1M:</Typography>
                                            {renderPriceChange(symbolPriceData.one_month)}
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body2">3M:</Typography>
                                            {renderPriceChange(symbolPriceData.three_months)}
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body2">6M:</Typography>
                                            {renderPriceChange(symbolPriceData.six_months)}
                                        </Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body2">YTD:</Typography>
                                            {renderPriceChange(symbolPriceData.ytd)}
                                        </Box>
                                    </Box>
                                )}

                            </Box>

                        </Grid>
                    </Grid>
                    <Typography variant="subtitle2" color="textSecondary">
                        References:
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                        {relatedChunks[row.symbol] && sortChunks(relatedChunks[row.symbol]).map((chunk, idx) => (
                            <Chip
                                key={idx}
                                label={`🎙️ ${chunk.year}Q${chunk.quarter}`}
                                size="small"
                                color="secondary"
                                variant="outlined"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleEarningCallClick(chunk.id, e);
                                }}
                            />
                        ))}
                        {relatedNews[row.symbol] && relatedNews[row.symbol].map((news, idx) => (
                            <Chip
                                key={idx}
                                label={`📰 ${news.title.substring(0, 30)}...`}
                                size="small"
                                color="primary"
                                variant="outlined"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(news.url, '_blank');
                                }}
                            />
                        ))}
                    </Box>
                </CardContent>
            </Card>
        );
    };
    return (
        <>
            <Tabs value={activeTab} onChange={handleTabChange}>
                {extendedCategories.map((category, index) => (
                    <Tab key={index} label={category.name} />
                ))}
            </Tabs>

            {extendedCategories[activeTab] && (
                <>
                    {activeTab < categories.length ? (
                        <>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                                <Button

                                    variant="contained"
                                    size="small"
                                    startIcon={<DownloadIcon />}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        downloadExcel(categories[activeTab].fields);
                                    }}
                                    sx={{
                                        fontSize: '0.75rem',
                                        padding: '4px 8px',
                                        minWidth: 'unset'
                                    }}
                                >
                                    {t('download_excel')}
                                </Button>
                            </Box>
                            {searchMode === 'business' && searchedQueryValue && activeTab === 0 ? (
                                // Business Summary rendering
                                <>
                                    <Box sx={{ my: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography variant="h6">
                                            <SearchIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                            Search Summaries for "{searchedQueryValue}" ({filteredData.length} results)
                                        </Typography>
                                    </Box>

                                    {paginatedData.map((row, index) => (
                                        <BusinessSummaryCard
                                            key={index}
                                            row={row}
                                            relatedChunks={relatedChunks}
                                            relatedNews={relatedNews}
                                            watchlist={watchlist}
                                            toggleWatchlist={toggleWatchlist}
                                            handleEarningCallClick={handleEarningCallClick}
                                            searchedQueryValue={searchedQueryValue}
                                            highlightSearchTerm={highlightSearchTerm}
                                            priceData={priceData}
                                        />
                                    ))}
                                </>
                            ) : (
                                <TableContainer component={Paper}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Watchlist</TableCell>

                                                {searchMode === 'business' && searchedQueryValue && (
                                                    <>
                                                        <TableCell
                                                            sx={{ ...commonCellStyle }}
                                                            onClick={() => handleSort('final_rank')}
                                                        >
                                                            <Tooltip
                                                                title={
                                                                    <Typography style={{ fontSize: '0.9rem', padding: '8px' }}>
                                                                        {t('relevance_rank_description')}
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                            >
                                                                <InfoIcon fontSize="inherit" sx={{ ml: 0.5, fontSize: '0.8rem' }} />
                                                            </Tooltip>
                                                            {sortColumn === 'final_rank' && (sortDirection === 'asc' ? '↑' : '↓')}
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                {t('relevance_rank')}
                                                            </Box>
                                                        </TableCell>

                                                    </>
                                                )}
                                                <TableCell sx={{ ...commonCellStyle }}>
                                                    {t('symbol')}
                                                    <TextField
                                                        value={tickerFilter}
                                                        onChange={(e) => setTickerFilter(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        margin="none"
                                                        sx={{
                                                            '& .MuiInputBase-root': { height: '24px' },
                                                            '& .MuiInputBase-input': { padding: '2px 4px', fontSize: '0.75rem' }
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell sx={{ ...commonCellStyle }}>
                                                    {t('companyName')}
                                                    <TextField
                                                        value={nameFilter}
                                                        onChange={(e) => setNameFilter(e.target.value)}
                                                        size="small"
                                                        fullWidth
                                                        margin="none"
                                                        sx={{
                                                            '& .MuiInputBase-root': { height: '24px' },
                                                            '& .MuiInputBase-input': { padding: '2px 4px', fontSize: '0.75rem' }
                                                        }}
                                                    />
                                                </TableCell>


                                                <TableCell sx={{ ...commonCellStyle }}>
                                                    {t('industry')}
                                                    <Select
                                                        value={industryFilter}
                                                        onChange={(e) => setIndustryFilter(e.target.value)}
                                                        displayEmpty
                                                        fullWidth
                                                        size="small"
                                                        sx={compactSelectStyle}
                                                    >
                                                        <MenuItem value="">All</MenuItem>
                                                        {industries.map((industry) => (
                                                            <MenuItem key={industry} value={industry}>
                                                                {industry}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>


                                                {searchMode === 'keyword' && searchedQueryValue && (
                                                    <>
                                                        <TableCell sx={{ ...commonCellStyle }}>
                                                            <Tooltip
                                                                title={
                                                                    <Typography style={{ fontSize: '0.9rem', padding: '8px' }}>
                                                                        {t('mention_frequency_description')}

                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                            >
                                                                <InfoIcon fontSize="inherit" sx={{ ml: 0.5, fontSize: '0.8rem' }} />
                                                            </Tooltip>
                                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                {t('mention_frequency')}
                                                            </Box>
                                                        </TableCell>
                                                    </>
                                                )}
                                                {categories[activeTab].fields.map(field => (
                                                    <TableCell
                                                        key={field}
                                                        onClick={() => handleSort(field)}
                                                        sx={{ ...commonCellStyle }}
                                                    >
                                                        {t(field)} {sortColumn === field && (sortDirection === 'asc' ? '↑' : '↓')}
                                                    </TableCell>
                                                ))}
                                                <TableCell sx={{ ...commonCellStyle }}>{t("product_sales")}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paginatedData.map((row, rowIndex) => (
                                                <HighlightableTableRow
                                                    key={rowIndex}
                                                    hasTooltip={!!row.longBusinessSummary}
                                                    onClick={() => handleStockClick(row)}
                                                >

                                                    <TableCell>
                                                        <Tooltip title={watchlist[row.symbol] ? "Remove from Watchlist" : "Add to Watchlist"}>
                                                            <IconButton
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    toggleWatchlist(row.symbol);
                                                                }}
                                                                size="small"
                                                            >
                                                                {watchlist[row.symbol] ? <StarIcon color="primary" /> : <StarBorderIcon />}
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                    {searchMode === 'business' && searchedQueryValue && (
                                                        <TableCell sx={{ ...commonCellStyle }}>
                                                            <Tooltip title={
                                                                <div>
                                                                    {renderRelevanceGauge(row.normalized_final_score)}
                                                                    <Typography align="center">
                                                                        {row.normalized_final_score < 80 ? 'Somewhat Relevant' :
                                                                            row.normalized_final_score < 90 ? 'Highly Relevant' :
                                                                                'Very Highly Relevant'}
                                                                    </Typography>
                                                                </div>
                                                            }>
                                                                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                                                                    <span>{row.final_rank !== undefined ? `#${row.final_rank}` : 'N/A'}</span>
                                                                </Box>
                                                            </Tooltip>
                                                        </TableCell>

                                                    )}
                                                    <TableCell sx={{ ...commonCellStyle }}>{row.symbol}</TableCell>

                                                    <TableCell sx={{ ...commonCellStyle }}>
                                                        {searchMode === 'business' && searchedQueryValue ? (
                                                            <Tooltip
                                                                title={
                                                                    <Typography style={{ fontSize: '0.9rem', padding: '8px' }}>
                                                                        {row.reason || 'No reason provided'}
                                                                    </Typography>
                                                                }
                                                                placement="top"
                                                            >
                                                                <HighlightedCompanyName>
                                                                    {row.name}
                                                                </HighlightedCompanyName>
                                                            </Tooltip>
                                                        ) : (
                                                            row.name
                                                        )}
                                                    </TableCell>
                                                    <TableCell sx={{ ...commonCellStyle }}>{row.industry}</TableCell>


                                                    {searchMode === 'keyword' && searchedQueryValue && (
                                                        <>
                                                            <TableCell sx={{ ...commonCellStyle }}>
                                                                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                                                                    <span>{row.frequency !== undefined ? `${row.frequency}` : 'N/A'}</span>
                                                                </Box>
                                                            </TableCell>
                                                        </>
                                                    )}
                                                    {categories[activeTab].fields.map(field => (
                                                        <TableCell key={field} sx={{ ...commonCellStyle, textAlign: field === 'main_products' ? 'left' : 'center' }}>
                                                            {field === 'main_products'
                                                                ? (row[field] ? renderMainProducts(row[field]) : '-')
                                                                : typeof row[field] === 'number'
                                                                    ? (row[field] !== undefined && row[field] !== null ? formatNumber(row[field]) : '-')
                                                                    : (row[field] || '-')}
                                                        </TableCell>
                                                    ))}
                                                    <TableCell sx={{ ...commonCellStyle }}>
                                                        {productSalesData[row.symbol] ?
                                                            renderProductSalesChart(row.symbol, productSalesData[row.symbol]) :
                                                            <div>-</div>
                                                        }
                                                    </TableCell>
                                                </HighlightableTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>

                                </TableContainer>)}
                            <Pagination
                                count={Math.ceil(filteredData.length / perPage)}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                                style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
                            />
                        </>) : (extendedCategories[activeTab].component()
                    )}

                </>
            )}
            {selectedChunkId && (
                <EarningCallModal
                    chunkId={selectedChunkId}
                    onClose={handleCloseModal}
                />
            )}
        </>
    );
};

export default StockTableComponent;