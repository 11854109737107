import React, { useState, useEffect, useCallback, useRef, memo, useMemo } from 'react';
import { Box, TextField, Button, Avatar, CircularProgress, Typography, Chip, Link, IconButton, Paper, Drawer, Tooltip } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CloseIcon from '@mui/icons-material/Close';
import ChatIcon from '@mui/icons-material/Chat';
import ArticleIcon from '@mui/icons-material/Article';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import { fetchKeywordAskStream, fetchAskCompanyStream } from './api';
import { useStock } from './StockContext';
import { t } from 'i18next';
import EarningCallModal from './components/EarningCallModal';
import debounce from 'lodash/debounce';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled } from '@mui/material/styles';
import i18next from 'i18next';
const serverUrl = process.env.REACT_APP_SERVER_URL;


const LargeTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        maxWidth: 500,
        fontSize: '1rem',
        border: '1px solid #dadde9',
        padding: '16px',
        borderRadius: '8px',
        whiteSpace: 'pre-line',  // 줄바꿈을 유지하기 위해 추가
    },
}));

const UnifiedAIChat = () => {
    const [conversation, setConversation] = useState([]);
    const [input, setInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false);
    const { setIsEarningCallOpen, selectedStock, selectedSymbols, selectedQuestion, setSelectedQuestion, chatWidth, setChatWidth, showError } = useStock();
    const [selectedChunkId, setSelectedChunkId] = useState(null);
    const referencesRef = useRef([]);
    const completedResponseRef = useRef('');
    const [targetStocks, setTargetStocks] = useState([]);
    const inputRef = useRef(null);
    const chatsizeRef = useRef(window.innerWidth * 0.3)
    const parentScrollOffsetX = useRef(0)
    const parentScrollOffsetY = useRef(0)

    const handleResize = useCallback((event, { size }) => {
        setChatWidth(size.width);
    }, []);


    const CustomHandle = ({ ...props }) => (
        <div
            {...props}
            className="custom-handle"
            style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 0,
                width: '10px', // 더 넓은 드래그 영역
                cursor: 'ew-resize',
                backgroundColor: 'transparent', // 투명 배경
            }}
        />
    );

    const handleResizeStop = useCallback((event, { size }) => {
        setChatWidth(size.width);
        chatsizeRef.current = size.width
    }, []);


    const addToTargetStocks = useCallback((symbol) => {
        const upperSymbol = symbol.toUpperCase();
        setTargetStocks(prev => {
            if (!prev.includes(upperSymbol)) {
                return [...prev, upperSymbol];
            }
            return prev;
        });
    }, []);

    useEffect(() => {
        const newSymbols = [...selectedSymbols, ...(selectedStock ? [selectedStock.symbol] : [])];
        newSymbols.forEach(addToTargetStocks);
    }, [selectedSymbols, selectedStock, addToTargetStocks]);


    useEffect(() => {
        if (!isChatOpen) {
            setChatWidth(window.innerWidth * 0); // Reset width when closed
        } else {
            setChatWidth(chatsizeRef.current); // Reset width when opened
        }
    }, [isChatOpen, setChatWidth]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        setInput(value);

        // Check if a new stock symbol was added
        const match = value.match(/@(\w+)\s$/);
        if (match) {
            const newSymbol = match[1];
            addToTargetStocks(newSymbol);
            // Remove the @symbol from the input
            setInput(value.replace(/@\w+\s$/, ''));
        }
    };

    const removeStock = (symbolToRemove) => {
        setTargetStocks(prev => prev.filter(symbol => symbol !== symbolToRemove));
    };


    useEffect(() => {
        if (selectedQuestion) {
            setInput(selectedQuestion);
            setSelectedQuestion('');
        }
    }, [selectedQuestion, setSelectedQuestion]);

    useEffect(() => {
        try {
            const savedConversation = localStorage.getItem('aiChatConversation');
            if (savedConversation) {
                setConversation(JSON.parse(savedConversation));
            }
        } catch (error) {
            showError(`Error loading conversation from localStorage: ${error.message}`);
        }
    }, []);

    const saveConversationToLocalStorage = useCallback(
        debounce((newConversation) => {
            try {
                localStorage.setItem('aiChatConversation', JSON.stringify(newConversation));
            } catch (error) {
                showError(`Error Saving conversation from localStorage: ${error.message}`);
            }
        }, 300),
        []
    );

    const clearConversation = useCallback(() => {
        setConversation([]);
        localStorage.removeItem('aiChatConversation');
    }, []);

    const handleEarningCallOpen = useCallback((chunkId) => {
        setSelectedChunkId(chunkId);
        setIsEarningCallOpen(true);
    }, [setIsEarningCallOpen]);

    const createOnChunkHandler = (userConversation) => {
        let isInitialized = false;

        const sortAndDeduplicateReferences = (references) => {
            const uniqueNewsTitles = new Set();
            return references
                .filter(ref => {
                    if (ref.quarter) return true;
                    if (uniqueNewsTitles.has(ref.title)) return false;
                    uniqueNewsTitles.add(ref.title);
                    return true;
                })
                .sort((a, b) => {
                    if (a.quarter && !b.quarter) return -1;
                    if (!a.quarter && b.quarter) return 1;
                    if (a.quarter && b.quarter) {
                        if (a.symbol !== b.symbol) return a.symbol.localeCompare(b.symbol);
                        if (a.year !== b.year) return b.year - a.year;
                        return b.quarter - a.quarter;
                    }
                    return a.title.localeCompare(b.title);
                });
        };

        return (chunk, isFirstChunk) => {
            setConversation(prevConversation => {
                let updatedConversation = [...prevConversation];

                if (!isInitialized) {
                    updatedConversation = [...userConversation, { type: 'ai', text: '', references: [] }];
                    isInitialized = true;
                }

                const lastMessage = updatedConversation[updatedConversation.length - 1];

                if (isFirstChunk) {
                    if (chunk.type === 'links') {
                        referencesRef.current = sortAndDeduplicateReferences(chunk.data);
                    }
                } else {
                    if (chunk.type === 'content') {
                        lastMessage.text += chunk.data;
                        completedResponseRef.current = lastMessage.text;
                    }
                }

                lastMessage.references = referencesRef.current;

                return updatedConversation;
            });
        };
    };

    const handleAskQuestionStream = useCallback(async () => {
        if (!input.trim()) return;
        setIsLoading(true);
        const currentLanguage = i18next.language;

        const fullInput = targetStocks.map(symbol => `@${symbol}`).join(' ') + ' ' + input.trim();
        const userConversation = [...conversation, { type: 'user', text: fullInput }];
        setConversation(userConversation);
        saveConversationToLocalStorage(userConversation);
        setInput('');

        try {
            referencesRef.current = [];
            const onChunk = createOnChunkHandler(userConversation);

            if (targetStocks.length > 0) {
                await fetchAskCompanyStream(fullInput, targetStocks, onChunk);
            } else {
                await fetchKeywordAskStream(fullInput, onChunk);
            }

            const questionsResponse = await fetch(`${serverUrl}/api/ai/generate-questions`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ report_str: completedResponseRef.current, language: currentLanguage }),
            });
            const questionsData = await questionsResponse.json();

            const newConversation = [
                ...userConversation,
                { type: 'ai', text: completedResponseRef.current, references: referencesRef.current },
                {
                    type: 'ai',
                    text: t("additional_question_description"),
                    additionalQuestions: questionsData.question_list
                }
            ];

            setConversation(newConversation);
            saveConversationToLocalStorage(newConversation);
        } catch (error) {
            showError(`Error Asking Question: ${error.message}`);
            setConversation(prev => [...prev, {
                type: 'ai',
                text: 'Error occurred while fetching the answer.'
            }]);
        } finally {
            setIsLoading(false);
            setInput('');
        }
    }, [input, conversation, targetStocks, saveConversationToLocalStorage]);

    const Message = memo(({ msg, handleEarningCallOpen }) => (
        <Box sx={{ display: 'flex', justifyContent: msg.type === 'user' ? 'flex-end' : 'flex-start', mb: 2 }}>
            {msg.type === 'ai' && <Avatar sx={{ bgcolor: 'primary.main', mr: 1 }}><SmartToyIcon /></Avatar>}
            <Box sx={{
                maxWidth: '70%',
                p: 2,
                borderRadius: 2,
                bgcolor: msg.type === 'user' ? 'primary.light' : 'grey.200'
            }}>
                {msg.references && (
                    <Box sx={{ mt: 1 }}>
                        {msg.references.map((ref, idx) => (
                            ref.quarter ? (
                                <Chip
                                    key={idx}
                                    icon={<ArticleIcon />}
                                    label={`EarningCall: ${ref.quarter}Q${ref.year}(${ref.symbol})`}
                                    size="small"
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleEarningCallOpen(ref.chunk_id)}
                                    sx={{ mr: 0.5, mt: 0.5 }}
                                />
                            ) : (
                                <Chip
                                    key={idx}
                                    icon={<NewspaperIcon />}
                                    label={`News: ${ref.title}`}
                                    size="small"
                                    color="primary"
                                    sx={{ mr: 0.5, mt: 0.5 }}
                                    onClick={() => window.open(ref.url, '_blank')}
                                    component={Link}
                                    href={ref.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    clickable
                                />
                            )
                        ))}
                    </Box>
                )}
                <ReactMarkdown>{msg.text}</ReactMarkdown>
                {msg.additionalQuestions && (
                    <Box sx={{ mt: 1 }}>
                        {msg.additionalQuestions.map((question, idx) => (
                            <Button
                                key={idx}
                                variant="outlined"
                                size="small"
                                onClick={() => setInput(question)}
                                sx={{ mr: 0.5, mt: 0.5 }}
                            >
                                {question}
                            </Button>
                        ))}
                    </Box>
                )}
            </Box>
        </Box>
    ));

    const memoizedConversation = useMemo(() => conversation, [conversation]);


    const usageInstructions = `사용법:
- 특정 기업에 대한 질문: @TICKER (예: @AAPL)
- 여러 기업에 대한 질문: @TICKER1 @TICKER2 (예: @AAPL @GOOGL)
- 전방위 질문: 티커 없이 질문 입력
`;


    return (
        <>
            <Button
                variant="contained"
                startIcon={isChatOpen ? <CloseIcon /> : <ChatIcon />}
                onClick={() => setIsChatOpen(!isChatOpen)}
                sx={{ position: 'fixed', right: '8px', top: '14px', zIndex: 1300 }}
            >
                {isChatOpen ? 'Close Chat' : 'Open AI Chat'}
            </Button>

            {isChatOpen && (
                <ResizableBox
                    width={chatWidth}
                    height={window.innerHeight}
                    minConstraints={[300, window.innerHeight]}
                    maxConstraints={[window.innerWidth * 0.8, window.innerHeight]}
                    resizeHandles={['w']}
                    handleSize={[20, 100]} // [width, height] 핸들의 크기를 조절합니다.
                    onResize={handleResize}
                    onResizeStop={handleResizeStop}
                    axis="x"
                    style={{
                        position: 'fixed',
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: 'white',
                        boxShadow: '-2px 0 5px rgba(0,0,0,0.1)',
                        zIndex: 1200,
                    }}
                >
                    <Box sx={{ height: '97%', display: 'flex', flexDirection: 'column', p: 2 }}>
                        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
                            <Typography variant="h6" component="div" sx={{ mb: 2 }}>
                                Chat with AI
                            </Typography>

                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={clearConversation}
                                sx={{ mb: 2 }}
                            >
                                Clear Conversation
                            </Button>

                            <Box sx={{ flexGrow: 1, overflowY: 'auto', mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 2 }}>
                                {memoizedConversation.map((msg, index) => (
                                    <Message key={index} msg={msg} handleEarningCallOpen={handleEarningCallOpen} />
                                ))}
                                {isLoading && (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Avatar sx={{ bgcolor: 'primary.main', mr: 1 }}><SmartToyIcon /></Avatar>
                                        <CircularProgress size={20} />
                                        <Typography variant="body2" sx={{ ml: 1 }}>AI is thinking...</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 1 }}>
                                {targetStocks.map((symbol) => (
                                    <Chip
                                        key={symbol}
                                        label={`@${symbol.toUpperCase()}`}
                                        onDelete={() => removeStock(symbol)}
                                        deleteIcon={<CancelIcon />}
                                        sx={{ mr: 0.5, mb: 0.5 }}
                                    />
                                ))}
                            </Box>
                            <LargeTooltip title={t("howtouseAI")} arrow placement="top-start">

                                <TextField
                                    fullWidth
                                    value={input}
                                    onChange={handleInputChange}
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            handleAskQuestionStream();
                                        }
                                    }}
                                    placeholder="Please Ask a question!"
                                    variant="outlined"
                                    margin="normal"
                                    inputRef={inputRef}
                                    autoComplete="off"
                                />
                            </LargeTooltip>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleAskQuestionStream}
                                disabled={isLoading}
                                sx={{ mt: 2 }}
                            >
                                {isLoading ? 'Thinking...' : 'Ask AI'}
                            </Button>
                        </Box>
                    </Box>
                </ResizableBox>
            )}


            {selectedChunkId && (
                <EarningCallModal
                    chunkId={selectedChunkId}
                />)}        </>
    );
};

export default React.memo(UnifiedAIChat);