// App.js
import { BrowserRouter as Router } from 'react-router-dom';
import { StockProvider } from './StockContext';
import AppContent from './AppContent';
import { AuthProvider } from './AuthContext';
function App() {
  return (
    <Router>
      <StockProvider>
        <AuthProvider>
          <AppContent />
        </AuthProvider>
      </StockProvider>
    </Router>
  );
}

export default App;