import React, { useEffect, useState } from 'react';
import { useStock } from './StockContext';
import { useNavigate } from 'react-router-dom';
import {
    Paper, FormControl,
    Tabs, Tab, Box, CircularProgress, Typography, Popover, Card, CardContent, Grid, Button, ButtonGroup,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import FinancialOverview from './components/FinancialOverview';
import OverallKeywordTrendsChart from './components/OverallKeywordTrendsChart';
import MarketCapChart from './components/MarketCapChart';
import { SectorIndustrySunburst } from './components/SectorIndustrySunburst';
import StockTableComponent from './components/StockTableComponent';
import { useTranslation } from 'react-i18next';
import { fetchProductSalesData, fetchMarketCapData, fetchTagStastic } from './api';
import SearchIcon from '@mui/icons-material/Search';
import RelatedArticlesComponent from './components/RelatedArticles';
import AIAnswerComponent from './components/AIAnswerComponent';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';




const LoadingComponent = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(timer);
                    return 100;
                }
                return prevProgress + 100 / (30 * 10); // 35초 동안 진행, 0.1초마다 업데이트
            });
        }, 100); // 0.1초마다 업데이트

        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" value={progress} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(progress)}%`}
                    </Typography>
                </Box>
            </Box>
            <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }}>
                Filtering related companies.... Please wait a little longer.
            </Typography>
        </Box>
    );
};


const SearchResultComponent = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const {
        data, loading, searchedQueryValue, translatedQueryValue,
        handleSearch, setMarketCapData,
        marketCapData, searchMode, selectedSymbols, setSelectedSymbols, setSelectedStock, showError, businessSubTab, setBusinessSubTab,
        fetchRelatedStocks, augmentedQueries
    } = useStock();
    const [componentHeight, setComponentHeight] = useState('40vh');
    const [mainTab, setMainTab] = useState(0);
    const [] = useState(0);
    const [tagStatistics, setTagStatistics] = useState(null);
    const [leftWidth, setLeftWidth] = useState(50);

    const handleResize = (event, { size }) => {
        const newLeftWidth = (size.width / window.innerWidth) * 100;
        setLeftWidth(newLeftWidth);
    };

    useEffect(() => {
        const calculateHeight = () => {
            let visibleComponents = 0;
            if (data.length > 0 && data.length <= 1000) {
                visibleComponents += 1; // Historical Market Cap
                visibleComponents += 1; // Industry Value Chain
            }
            if (searchMode === 'keyword' && searchedQueryValue) {
                visibleComponents += 1; // Overall Keyword Trends
            }
            setComponentHeight('40vh');
        };
        calculateHeight();
    }, [data, searchMode, searchedQueryValue]);


    useEffect(() => {
        const fetchData = async () => {
            if (data.length <= 1000) {
                try {
                    const md = await fetchMarketCapData(data);
                    setMarketCapData(md);
                } catch (error) {
                    showError(`Error fetching market cap data: ${error.message}`);
                }
            }
        };

        fetchData();
    }, [data]);

    useEffect(() => {
        const fetchTagStats = async () => {
            if (searchedQueryValue) {
                try {
                    const stats = await fetchTagStastic(searchedQueryValue);
                    setTagStatistics(stats[0]);
                } catch (error) {
                    showError(`Error fetching tag statistics: ${error.message}`);

                }
            }
        };
        fetchTagStats();
    }, [data, searchedQueryValue]);


    const handleStockClick = (stock) => {
        setSelectedStock(stock)
        navigate(`/stock/${stock.symbol}`);
    };

    useEffect(() => {
        const newSearchMode = mainTab === 0 ? 'business' : 'keyword';
        if (searchedQueryValue) {
            handleSearch(newSearchMode, searchedQueryValue);
            fetchRelatedStocks(newSearchMode, searchedQueryValue);
        }
    }, [mainTab, searchedQueryValue]);

    const handleMainTabChange = (event, newValue) => {
        setMainTab(newValue);
    };

    const handleBusinessSubTabChange = (newValue) => {
        setBusinessSubTab(newValue);
    };

    if (!searchedQueryValue && !loading) {
        return (
            <Alert severity="info" sx={{ m: 2 }}>
                {t("Please use the search function at the top or find an attractive investment idea in the Idea Discovery section first.")}
            </Alert>
        );
    }

    return (
        <div style={{
            margin: '20px',
            overflowX: 'auto',  // 가로 스크롤 추가
            maxWidth: '100%',   // 최대 너비 설정
        }}>
            <>
                {searchedQueryValue && (
                    <Paper
                        elevation={3}
                        sx={{
                            mb: 3,
                            background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                            color: 'white',
                            p: 2,
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <SearchIcon sx={{ fontSize: 40, mr: 2 }} />
                        <Box>
                            <Typography variant="overline" sx={{ fontWeight: 'bold', letterSpacing: 1.5 }}>
                                {t("Current Analysis")}
                            </Typography>
                            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                                "{searchedQueryValue}"
                            </Typography>
                        </Box>
                    </Paper>
                )}
                <Tabs value={mainTab} onChange={handleMainTabChange} sx={{
                    mb: 2,
                    '& .MuiTabs-flexContainer': {
                        flexWrap: 'wrap',
                    },
                    '& .MuiTab-root': {
                        minWidth: { xs: '100%', sm: 'auto' },
                    }
                }}>
                    <Tab label={t("business_search")} />
                    <Tab label={t("exact_search")} />
                </Tabs>

                {mainTab === 0 && (
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{ mb: 2, flexWrap: 'wrap' }}
                    >
                        <Button
                            onClick={() => handleBusinessSubTabChange(0)}
                            variant={businessSubTab === 0 ? "contained" : "outlined"}
                        >
                            Related Articles
                        </Button>
                        <Button
                            onClick={() => handleBusinessSubTabChange(3)}
                            variant={businessSubTab === 3 ? "contained" : "outlined"}
                        >
                            Related Stocks
                        </Button>
                    </ButtonGroup>
                )}


                {mainTab === 1 && (
                    <ButtonGroup
                        variant="outlined"
                        aria-label="outlined button group"
                        sx={{ mb: 2, flexWrap: 'wrap' }}
                    >
                        <Button
                            onClick={() => setBusinessSubTab(0)}
                            variant={businessSubTab === 0 ? "contained" : "outlined"}
                        >
                            Related Stocks
                        </Button>
                    </ButtonGroup>
                )}


                {mainTab === 0 && (
                    <>

                        {businessSubTab === 0 && (
                            <>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', letterSpacing: 1.5 }}> Search Result </Typography>
                                        <RelatedArticlesComponent initialQuery={searchedQueryValue} />
                                    </Grid>
                                    <Grid item xs={12} md={6} sx={{
                                    }}>
                                        <Typography variant="h5" sx={{ fontWeight: 'bold', letterSpacing: 1.5, paddingBottom: 3 }}> Analyst AI Overview </Typography>

                                        {augmentedQueries && augmentedQueries.length !== 0 ? (
                                            <AIAnswerComponent original_query={searchedQueryValue} search_queries={augmentedQueries} />
                                        ) : (
                                            <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                                                <CircularProgress />
                                                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                                                    Waiting for Search Result....
                                                </Typography>
                                            </Box>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        )}
                        {businessSubTab === 3 && (
                            <>
                                {loading ? (
                                    <LoadingComponent />
                                ) : (
                                    <>
                                        <Alert severity='info' sx={{ m: 2 }}> Currently Only US Trading Stock Available.</Alert>
                                        <StockTableComponent
                                            data={data}
                                            searchMode={searchMode}
                                            handleStockClick={handleStockClick}
                                            searchedQueryValue={searchedQueryValue}
                                            fetchProductSalesData={fetchProductSalesData}
                                            setSelectedSymbols={setSelectedSymbols}
                                            selectedSymbols={selectedSymbols}
                                        />
                                    </>
                                )}
                            </>
                        )}

                    </>
                )}
                {mainTab === 1 && (
                    // Keyword search content
                    <>
                        {businessSubTab === 0 && (
                            <>
                                {loading ? (
                                    <LoadingComponent />
                                ) : (
                                    <>
                                        <StockTableComponent
                                            data={data}
                                            searchMode={searchMode}
                                            handleStockClick={handleStockClick}
                                            searchedQueryValue={searchedQueryValue}
                                            fetchProductSalesData={fetchProductSalesData}
                                            setSelectedSymbols={setSelectedSymbols}
                                            selectedSymbols={selectedSymbols}
                                        />
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </>
        </div>
    );

}

export default React.memo(SearchResultComponent)