import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useStock } from '../StockContext';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [nickname, setNickname] = useState('');
    const navigate = useNavigate();
    const { signup } = useAuth();
    const { showError } = useStock();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signup(email, password, nickname);
            // alert('Please check your email to confirm your account.');
            navigate('/');
        } catch (error) {
            console.error('Signup failed:', error);
            showError(`SignUp Failed : ${error.message}`);

        }
    };

    return (
        <Box sx={{ maxWidth: 300, margin: 'auto', mt: 5 }}>
            <Typography variant="h4" component="h1" gutterBottom>
                Sign Up
            </Typography>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <TextField
                    label="Nickname"
                    type="text"
                    fullWidth
                    margin="normal"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    required
                />
                <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
                    Sign Up
                </Button>
            </form>
        </Box>
    );
};

export default Signup;