// api.js
import axios from 'axios';
import i18next from 'i18next';


const serverUrl = process.env.REACT_APP_SERVER_URL;

export const fetchColumns = async () => {
    try {
        const response = await axios.get(`${serverUrl}/api/search/available-columns`);
        return response.data;
    } catch (error) {
        console.error('Error fetching columns:', error);
        throw error;
    }
};

export const fetchIndexData = async (index) => {
    try {
        const response = await axios.get(`${serverUrl}/api/marketcap/historical-index`, {
            params: {
                start_date: '2010-01-01', // 시작 날짜 설정
                index: index
            }
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching ${index} data:`, error);
        throw error;
    }
};

export const fetchAskCompany = async (input, symbols) => {
    const currentLanguage = i18next.language;
    try {
        const response = await axios.post(`${serverUrl}/api/ai/company-qa`, {
            question: input,
            tickers: symbols,
            language: currentLanguage,
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching ${input}, ${symbols} data:`, error);
        throw error;
    }
};

export const fetchCompanyDescription = async (symbol) => {
    const currentLanguage = i18next.language;
    try {
        const response = await fetch(`${serverUrl}/api/stock/business-description?symbol=${symbol}&language=${currentLanguage}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error fetching, ${symbol} data:`, error);
        throw error;
    }
};


export const fetchPhraseTrands = async (symbol, phrase, minYear) => {
    try {
        const response = await fetch(`${serverUrl}/api/earnings-call/phrase-frequency?ticker=${symbol}&phrase=${phrase}&min_year=${minYear}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error fetching, ${symbol}, ${phrase}, ${minYear} data:`, error);
        throw error;
    }
};

export const fetchOverallPhraseTrads = async (phrase, minYear) => {
    try {
        const response = await fetch(`${serverUrl}/api/earnings-call/phrase-frequency?phrase=${phrase}&min_year=${minYear}`);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error(`Error fetching overall ${phrase}, ${minYear} data:`, error);
        throw error;
    }
};

export const fetchRelevanceSummary = async (symbol, query, summary) => {
    const currentLanguage = i18next.language;
    try {
        const response = await axios.post(`${serverUrl}/relevance_summary`, {
            ticker: symbol,
            query: query,
            language: currentLanguage
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching ${symbol}, ${query} ${summary} data:`, error);
        throw error;
    }
};


export const fetchSearchCompany = async (params) => {
    const currentLanguage = i18next.language;
    try {
        params.language = currentLanguage;
        const response = await axios.get(`${serverUrl}/api/search/companies`, {
            params: params
        });
        return response.data;
    } catch (error) {
        console.error(`Error fetching ${params} data:`, error);
        throw error;
    }
};

export const fetchKeywordAsk = async (input) => {
    const currentLanguage = i18next.language;
    try {
        const response = await fetch(`${serverUrl}/api/ai/keyword-qa`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ question: input, language: currentLanguage }),
        });
        const data = await response.json();
        return data
    } catch (error) {
        console.error(`Error fetching ${input} data:`, error);
        throw error;
    }
};



export const fetchMarketCapData = async (data) => {
    const symbols = data.map(item => item.symbol);
    try {
        const response = await axios.post(`${serverUrl}/api/marketcap/historical-symbols`, {
            symbols: symbols,
            start_date: "2010-01-01"
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching market cap data:', error);
        throw error;
    }
};


export const fetchStockHistory = async (symbol, startDate) => {
    const response = await fetch(`${serverUrl}/api/price/history?symbol=${symbol}&start_date=${startDate}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    const result = await response.json();
    return result;
};




export const fetchProductSalesData = async (symbols) => {
    try {
        const response = await axios.post(`${serverUrl}/api/stock/product-sales`, { symbols });
        return response.data;
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};


export const fetchSimilarSearchQuery = async (IdeaQuery) => {
    try {
        const response = await fetch(`${serverUrl}/api/search/similar-queries?target_query=${encodeURIComponent(IdeaQuery)}`);
        if (response.ok) {
            const data = await response.json();
            return data
        }
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};


export const fetchPriceDiffSymbols = async (symbols) => {
    try {
        const response = await axios.get(`${serverUrl}/api/price/change?symbols=${symbols.join(',')}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching price diff data:', error);
        throw error;
    }
};

export const fetchPriceDiffSymbol = async (symbol) => {
    try {
        const response = await axios.get(`${serverUrl}/api/price/change?symbols=${symbol}`);
        return response.data[0];
    } catch (error) {
        console.error('Error fetching price diff data:', error);
        throw error;
    }
}

export const fetchRelatedArticles = async (query) => {
    try {
        const response = await axios.get(`${serverUrl}/api/search/articles?query=${encodeURIComponent(query)}`);
        const data = await response.data;
        return data
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};

export const fetchDividendData = async (symbol) => {
    try {
        const response = await axios.get(`${serverUrl}/api/stock/dividend?symbol=${symbol}`);
        const data = await response.data;
        return data
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};


export const fetchCorpInfo = async (symbol) => {
    try {
        const response = await axios.get(`${serverUrl}/api/stock/company-info?symbol=${symbol}`);
        const data = await response.data[0];
        return data
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};

export const fetchEarnings = async (symbol) => {
    try {
        const response = await axios.get(`${serverUrl}/api/stock/earnings?symbol=${symbol}`);
        const data = await response.data;
        return data
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};

export const fetchTagStastic = async (tag) => {
    try {
        let url = `${serverUrl}/api/price/tag-statistics`;
        if (tag) {
            const encodedTag = encodeURIComponent(tag);
            url += `?tag=${encodedTag}`;
        }

        const response = await axios.get(url);
        if (response.status === 200) {
            return response.data;
        } else {
            throw new Error('Response was not ok');
        }
    } catch (error) {
        console.error('Error fetching tag statistics:', error);
        throw error;
    }
};


export const fetchEarningCallList = async (symbol) => {
    try {
        const response = await fetch(`${serverUrl}/api/earnings-call/list?symbol=${symbol}`);
        if (response.ok) {
            const data = await response.json();
            return data
        }
    } catch (error) {
        console.error('Error fetching product sales data:', error);
        throw error;
    }
};

export const fetchSimilarCorpList = async (query) => {
    const response = await fetch(`${serverUrl}/api/search/similar-companies?target_query=${query}`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};


export const fetchRelatedChunks = async (chunkIds) => {
    try {
        const params = new URLSearchParams();
        chunkIds.forEach(id => params.append('ids', id));
        const response = await axios.get(`${serverUrl}/api/earnings-call/chunks`, { params });
        return response.data.items;
    } catch (error) {
        console.error('Error fetching related chunks:', error);
        throw error;
    }
};




export const fetchRelatedCharacters = async (topic) => {
    try {
        const response = await axios.get(`${serverUrl}/api/news/topic-related?topic=${topic}`);
        return response.data.chracteristics;
    } catch (error) {
        console.error('Error fetching related chunks:', error);
        throw error;
    }
};

export const fetchRelatedNews = async (NewsIds) => {
    try {
        const params = new URLSearchParams();
        NewsIds.forEach(id => params.append('ids', id));
        const response = await axios.get(`${serverUrl}/api/news/by-id`, { params });
        return response.data.items;
    } catch (error) {
        console.error('Error fetching related chunks:', error);
        throw error;
    }
};

export const fetchStockNews = async (symbol, start_date, end_date) => {
    try {
        const response = await axios.get(`${serverUrl}/api/news/stock-specific`, {
            params: { symbol, start_date: start_date, end_date: end_date }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching related chunks:', error);
        throw error;
    }
};



export const fetchKeywordAskStream = async (input, onChunk) => {
    const currentLanguage = i18next.language;
    try {
        const response = await fetch(`${serverUrl}/api/ai/keyword-qa`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ question: input, language: currentLanguage, stream: true }),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let isFirstChunk = true;

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const chunk = decoder.decode(value);
            const lines = chunk.split('\n\n');
            for (const line of lines) {
                if (line.trim() !== '') {
                    const parsedChunk = JSON.parse(line);
                    if (isFirstChunk) {
                        onChunk(parsedChunk, true);
                        isFirstChunk = false;
                    } else {
                        onChunk(parsedChunk, false);
                    }
                }
            }
        }
    } catch (error) {
        console.error(`Error fetching ${input} data:`, error);
        throw error;
    }
};

export const fetchAskCompanyStream = async (input, symbols, onChunk) => {
    const currentLanguage = i18next.language;
    try {
        const response = await fetch(`${serverUrl}/api/ai/company-qa`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                question: input,
                tickers: symbols,
                language: currentLanguage,
                stream: true
            }),
        });

        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        let isFirstChunk = true;

        while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            const chunk = decoder.decode(value);
            const lines = chunk.split('\n\n');
            for (const line of lines) {
                if (line.trim() !== '') {
                    const parsedChunk = JSON.parse(line);
                    if (isFirstChunk) {
                        onChunk(parsedChunk, true);
                        isFirstChunk = false;
                    } else {
                        onChunk(parsedChunk, false);
                    }
                }
            }
        }
    } catch (error) {
        console.error(`Error fetching ${input}, ${symbols} data:`, error);
        throw error;
    }
};

export const fetchGeneralNews = async () => {
    const response = await fetch(`${serverUrl}/api/news/general`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};


export const fetchLiveNews = async () => {
    const response = await fetch(`${serverUrl}/api/news/live`);
    if (!response.ok) {
        throw new Error('Network response was not ok');
    }
    return response.json();
};

