// MarketCapChart.js
import React, { useState, useEffect, useMemo } from 'react';
import { Box, FormControl, Select, MenuItem, Button } from '@mui/material';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import { fetchIndexData } from '../api';

const MarketCapChart = ({ marketCapData }) => {
    const [selectedIndices, setSelectedIndices] = useState(['NASDAQ']);
    const [indexData, setIndexData] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            for (const index of selectedIndices) {
                if (!indexData[index]) {
                    const data = await fetchIndexData(index);
                    if (data) {
                        setIndexData(prevData => ({ ...prevData, [index]: data }));
                    }
                }
            }
        };

        fetchData();
    }, [selectedIndices]);

    const handleIndexSelection = (event) => {
        const selectedIndex = event.target.value;
        if (!selectedIndices.includes(selectedIndex)) {
            setSelectedIndices([...selectedIndices, selectedIndex]);
        }
    };

    const removeIndex = (index) => {
        setSelectedIndices(selectedIndices.filter(i => i !== index));
        setIndexData(prevData => {
            const newData = { ...prevData };
            delete newData[index];
            return newData;
        });
    };

    const chartOptions = useMemo(() => {
        const formatMarketCap = (value) => {
            return (value / 1e9).toFixed(2);
        };

        const calculateMarketCapToIndex = (marketCapData, indexData) => {
            const marketCapMap = new Map(marketCapData.map(item => [item.date, item.avg_market_cap]));
            return indexData.map(item => {
                const marketCap = marketCapMap.get(item.date);
                if (marketCap && item.close) {
                    return [
                        new Date(item.date).getTime(),
                        parseFloat((marketCap / item.close).toFixed(2))
                    ];
                }
                return null;
            }).filter(item => item !== null);
        };

        return {
            rangeSelector: {
                enabled: true,
                buttons: [{
                    type: 'month',
                    count: 1,
                    text: '1m'
                }, {
                    type: 'month',
                    count: 3,
                    text: '3m'
                }, {
                    type: 'month',
                    count: 6,
                    text: '6m'
                }, {
                    type: 'ytd',
                    text: 'YTD'
                }, {
                    type: 'year',
                    count: 1,
                    text: '1y'
                }, {
                    type: 'all',
                    text: 'All'
                }],
                selected: 5
            },
            title: {
                text: 'Historical Avg Market Cap, Index Values, Daily Changes, and Market Cap / Index'
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    format: '{value:%Y-%m-%d}'
                }
            },
            yAxis: [{
                title: {
                    text: 'Market Cap (Billion USD)',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                labels: {
                    format: '${value:,.2f}B',
                    style: {
                        color: Highcharts.getOptions().colors[0]
                    }
                },
                opposite: false
            }, {
                title: {
                    text: 'Index Value',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                labels: {
                    format: '{value:,.0f}',
                    style: {
                        color: Highcharts.getOptions().colors[1]
                    }
                },
                opposite: true
            }, {
                title: {
                    text: 'Market Cap / Index',
                    style: {
                        color: Highcharts.getOptions().colors[3]
                    }
                },
                labels: {
                    style: {
                        color: Highcharts.getOptions().colors[3]
                    }
                },
                opposite: true
            }],
            tooltip: {
                shared: true
            },
            legend: {
                enabled: true,
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                floating: false,
                backgroundColor: (Highcharts.theme && Highcharts.theme.legendBackgroundColor) || 'rgba(255,255,255,0.25)'
            },
            series: [
                {
                    name: 'Avg Market Cap',
                    type: 'line',
                    yAxis: 0,
                    data: marketCapData.map(item => [
                        new Date(item.date).getTime(),
                        parseFloat(formatMarketCap(item.avg_market_cap))
                    ]),
                    tooltip: {
                        valueDecimals: 2,
                        valueSuffix: ' B'
                    }
                },
                // {
                //     name: 'Market Cap Daily Change',
                //     type: 'column',
                //     yAxis: 2,
                //     data: calculateDailyChange(marketCapData),
                //     tooltip: {
                //         valueDecimals: 2,
                //         valueSuffix: '%'
                //     }
                // },
                ...Object.entries(indexData).flatMap(([name, data], index) => [
                    {
                        name: name,
                        type: 'line',
                        yAxis: 1,
                        data: data.map(item => [
                            new Date(item.date).getTime(),
                            item.close
                        ]),
                        tooltip: {
                            valueDecimals: 2
                        }
                    },
                    {
                        name: `Market Cap / ${name}`,
                        type: 'line',
                        yAxis: 2,
                        data: calculateMarketCapToIndex(marketCapData, data),
                        tooltip: {
                            valueDecimals: 2
                        }
                    }
                ])
            ]
        };
    }, [marketCapData, indexData]);

    return (
        <Box>
            <FormControl sx={{ m: 1, minWidth: 120 }}>
                <Select
                    value=""
                    onChange={handleIndexSelection}
                    displayEmpty
                    sx={{
                        fontSize: '0.875rem',
                        '& .MuiSelect-select': {
                            padding: '0.5rem 1rem'
                        }
                    }}
                >
                    <MenuItem value="" disabled>Add Index</MenuItem>
                    {['NASDAQ', 'SP500', 'Russell'].map(index => (
                        <MenuItem key={index} value={index} disabled={selectedIndices.includes(index)}>
                            {index}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {selectedIndices.map(index => (
                <Button key={index} onClick={() => removeIndex(index)} variant="outlined" sx={{ m: 1 }}>
                    Remove {index}
                </Button>
            ))}
            <HighchartsReact highcharts={Highcharts} options={chartOptions} constructorType={'stockChart'} />
        </Box>
    );
};

export default MarketCapChart;