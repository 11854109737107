import React, { useState, useEffect, useRef, useCallback } from 'react';
import { AppBar, Toolbar, TextField, Button, Box, List, ListItem, ListItemIcon, ListItemText, Paper, Typography, Chip, CircularProgress, Alert } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useStock } from './StockContext';
import { fetchSimilarSearchQuery, fetchSimilarCorpList } from './api';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import UserProfile from './components/UserProfile';
import { useTranslation } from 'react-i18next';
import { useAuth } from './AuthContext';


const Header = () => {
    const { t } = useTranslation();
    const { handleSearch, loading, searchMode, setSelectedStock, isNavCollapsed, showError } = useStock();
    const [inputQuery, setInputQuery] = useState('');
    const [similarQueries, setSimilarQueries] = useState([]);
    const [similarCorps, setSimilarCorps] = useState([]);
    const [isLoadingSimilar, setIsLoadingSimilar] = useState(false);
    const searchInputRef = useRef(null);
    const navigate = useNavigate();
    const cache = useRef({});
    const { isAuthenticated } = useAuth(); // 새로운 AuthContext에서 인증 상태 가져오기

    // const handleSearchClick = () => {
    //     handleSearch(searchMode, inputQuery);
    //     setSimilarQueries([]);
    //     setSimilarCorps([]);
    // };

    const handleSimilarQueryClick = (query) => {
        setInputQuery(query);
        setSimilarQueries([]);
        setSimilarCorps([]);
        handleSearch(searchMode, query);
    };


    const handleSimilarCorpClick = (query) => {
        setSimilarQueries([]);
        setSimilarCorps([]);
        setSelectedStock(query)
        navigate(`stock/${query.symbol}`)
    };
    const handleOutsideClick = (event) => {
        if (searchInputRef.current && !searchInputRef.current.contains(event.target)) {
            setSimilarQueries([]);
            setSimilarCorps([]);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const debouncedFetchSimilarData = useCallback(
        debounce(async (query) => {
            if (query.length > 1) {
                setIsLoadingSimilar(true);
                try {
                    if (cache.current[query]) {
                        setSimilarQueries(cache.current[query].queries);
                        setSimilarCorps(cache.current[query].corps);
                    } else {
                        let queriesData, corpsData;
                        if (query.length > 1) {
                            [queriesData, corpsData] = await Promise.all([
                                fetchSimilarSearchQuery(query),
                                fetchSimilarCorpList(query)
                            ]);

                            // query를 좌우 공백 제거 후 소문자로 변환
                            const strippedQuery = query.trim().toLowerCase();
                            // queriesData의 각 항목도 좌우 공백 제거 후 소문자로 변환하여 비교
                            const isQueryInData = queriesData.some(q => q.trim().toLowerCase() === strippedQuery);

                            // query가 queriesData에 없을 때만 추가
                            const newQueries = isQueryInData ? queriesData : [...queriesData, query];
                            // setSimilarQueries(newQueries)
                            setSimilarQueries([query]);
                            setSimilarCorps(corpsData);
                            cache.current[query] = { queries: newQueries, corps: corpsData };
                        }
                    }

                } catch (error) {
                    showError(`Error fetching similar data: ${error.message}`);
                } finally {
                    setIsLoadingSimilar(false);
                }
            } else {
                setSimilarQueries([]);
                setSimilarCorps([]);
                setIsLoadingSimilar(false);
            }
        }, 200),
        []
    );


    const handleFocus = () => {
        if (inputQuery.length > 1) {
            debouncedFetchSimilarData(inputQuery);
        }
    };

    useEffect(() => {
        debouncedFetchSimilarData(inputQuery);
    }, [inputQuery, debouncedFetchSimilarData]);

    const handleInputChange = (e) => {
        setInputQuery(e.target.value);
    };


    const LoginButton = () => {
        const navigate = useNavigate();

        const handleLogin = () => {
            navigate('/login');
        };

        return (
            <Button variant="contained" onClick={handleLogin}>
                Login
            </Button>
        );
    };

    return (
        <AppBar
            position="fixed"
            elevation={1}
            sx={{
                backgroundColor: 'white',
                left: isNavCollapsed ? '60px' : '220px',
                width: `calc(100% - ${isNavCollapsed ? '60px' : '220px'})`,
                zIndex: 1100,
            }}
        >
            <Toolbar sx={{ justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }} ref={searchInputRef}>
                    <SearchIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                    <TextField
                        value={inputQuery}
                        onChange={handleInputChange}
                        placeholder="Search Stock or Stocks related to your idea..."
                        variant="outlined"
                        onFocus={handleFocus}  // 이 줄을 추가합니다
                        size="small"
                        autoComplete="off"
                        sx={{
                            width: '500px', // 원하는 너비로 조정하세요
                            marginRight: '10px',
                            backgroundColor: 'white',
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#4a90e2',
                                },
                                '&:hover fieldset': {
                                    borderColor: '#3a78c2',
                                },
                            },
                        }}
                    />
                    {!loading && (isLoadingSimilar ? (
                        <Paper elevation={3} sx={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '20px',
                        }}>
                            <CircularProgress />
                        </Paper>
                    ) : (similarQueries.length > 0 || similarCorps.length > 0) && (
                        <Paper elevation={3} sx={{
                            position: 'absolute',
                            top: '100%',
                            left: 0,
                            width: '100%',
                            zIndex: 1,
                            maxHeight: '400px',
                            overflowY: 'auto',
                        }}>
                            <List>
                                {similarCorps.map((corp, index) => (
                                    <ListItem
                                        key={`corp-${index}`}
                                        button
                                        onClick={() => handleSimilarCorpClick(corp)}
                                    >
                                        <ListItemIcon sx={{ marginRight: 2 }}>
                                            <Chip
                                                label={t("Stock Detail")}
                                                size="small"
                                                color="primary"
                                                variant="filled"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${corp.name} (${corp.exchangeShortName}:${corp.symbol})`}
                                            secondary={t("detail stock descripton")}
                                        />
                                        <Typography variant="body2">

                                        </Typography>
                                    </ListItem>
                                ))}
                                {similarQueries.map((query, index) => (
                                    <ListItem
                                        key={`query-${index}`}
                                        button
                                        onClick={() => handleSimilarQueryClick(query)}
                                    >
                                        <ListItemIcon sx={{ marginRight: 2 }}>
                                            <Chip
                                                label={t("Related Stocks")}
                                                size="small"
                                                color="secondary"
                                                variant="filled"
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={query}
                                            secondary={t("relate stock descripton")}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    ))}
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                        {isAuthenticated ? <UserProfile /> : <LoginButton />}
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
