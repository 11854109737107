import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Box, Typography, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useStock } from './StockContext';
import LanguageSwitcher from './components/LanguageSwitcher';
const Navigation = () => {
    const [hoveredItem, setHoveredItem] = useState(null);
    const { isNavCollapsed, setIsNavCollapsed } = useStock();

    const navStyle = {
        position: 'fixed',
        left: 0,
        top: 0,
        width: isNavCollapsed ? '60px' : '220px',
        height: '100vh',
        backgroundColor: '#ffffff',
        boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        padding: '20px 0',
        fontFamily: 'Arial, sans-serif',
        overflowY: 'auto',
        zIndex: 1000,
        transition: 'width 0.3s',
        display: 'flex',
        flexDirection: 'column',
    };

    const ulStyle = {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    };

    const liStyle = {
        marginBottom: '10px',
    };

    const linkStyle = (isActive, isHovered) => ({
        display: 'flex',
        alignItems: 'center',
        padding: '10px',
        color: isActive || isHovered ? '#0066cc' : '#333',
        textDecoration: 'none',
        transition: 'background-color 0.3s, color 0.3s',
        borderLeft: '4px solid',
        borderLeftColor: isActive ? '#0066cc' : 'transparent',
        fontWeight: 500,
        fontSize: '14px',
        backgroundColor: isActive || isHovered ? '#e6f2ff' : 'transparent',
        justifyContent: isNavCollapsed ? 'center' : 'flex-start',
    });

    const emojiStyle = {
        marginRight: isNavCollapsed ? 0 : '10px',
        fontSize: '1.2em',
    };

    const menuItems = [
        { path: '/watchlist', label: 'Watchlist', emoji: '👀' },
        { path: '/idea-discovery', label: 'Stock Idea Discovery', emoji: '🔍' },
        { path: '/idea-analysis', label: 'Idea Analysis & Related Stocks', emoji: '📊' },
        { path: 'https://forms.gle/ui7G2EL5r9zKKZ9M9', label: 'Feedback', emoji: '📝', external: true },
    ];

    return (
        <nav style={navStyle}>
            <IconButton
                onClick={() => setIsNavCollapsed(!isNavCollapsed)}
                sx={{ position: 'absolute', right: '10px', top: '10px', zIndex: 1100 }}
            >
                {isNavCollapsed ? <MenuIcon /> : <ChevronLeftIcon />}
            </IconButton>
            {!isNavCollapsed && (
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                    <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Typography
                            variant="h2"
                            sx={{
                                fontFamily: "'Sacramento', cursive",
                                fontSize: '3rem',
                                cursor: 'pointer', // 마우스 오버 시 커서 변경을 위해 추가
                            }}
                        >
                            Philo
                        </Typography>
                    </Link>
                </Box>
            )}
            <ul style={{ ...ulStyle, flexGrow: 1, marginTop: isNavCollapsed ? '50px' : '0' }}>
                {menuItems.map((item, index) => (
                    <li key={index} style={liStyle}>
                        {item.external ? (
                            <a
                                href={item.path}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={linkStyle(false, hoveredItem === index)}
                                onMouseEnter={() => setHoveredItem(index)}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <span style={emojiStyle} role="img" aria-label={item.label}>
                                    {item.emoji}
                                </span>
                                {!isNavCollapsed && item.label}
                            </a>
                        ) : (
                            <NavLink
                                to={item.path}
                                style={({ isActive }) => linkStyle(isActive, hoveredItem === index)}
                                onMouseEnter={() => setHoveredItem(index)}
                                onMouseLeave={() => setHoveredItem(null)}
                            >
                                <span style={emojiStyle} role="img" aria-label={item.label}>
                                    {item.emoji}
                                </span>
                                {!isNavCollapsed && item.label}
                            </NavLink>
                        )}
                    </li>
                ))}
            </ul>
            <Box sx={{
                mt: 'auto',
                mb: 5,
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                {!isNavCollapsed ? <LanguageSwitcher /> : <></>}
            </Box>
        </nav>
    );
};

export default Navigation;