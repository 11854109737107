import React, { useState, useEffect } from 'react';
import {
    Box, TextField, Button, Typography, Grid, Card, CardContent,
    Link, Pagination, CircularProgress, Paper, List, ListItem, ListItemText, Divider
} from '@mui/material';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { fetchStockNews } from '../api';

const serverUrl = process.env.REACT_APP_SERVER_URL

const NewsSearch = ({ symbol }) => {
    const { t } = useTranslation();

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [news, setNews] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [summary, setSummary] = useState('');
    const [summaryLoading, setSummaryLoading] = useState(false);
    const itemsPerPage = 10;


    useEffect(() => {
        if (symbol) {
            const end = new Date();
            const start = new Date();
            start.setFullYear(start.getFullYear() - 1);
            setStartDate(start.toISOString().split('T')[0]);
            setEndDate(end.toISOString().split('T')[0]);
            handleSearch(start.toISOString().split('T')[0], end.toISOString().split('T')[0]);
            //fetchSummary();
        }
    }, [symbol]);


    const handleSearch = async (start = startDate, end = endDate) => {
        setLoading(true);
        try {
            const data = await fetchStockNews(symbol, start, end)
            setNews(data);
            setPage(1);
        } catch (error) {
            console.error('Error fetching news:', error);

            throw error
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const paginatedNews = news.slice((page - 1) * itemsPerPage, page * itemsPerPage);

    return (
        <Box sx={{ mt: 2 }}>
            {/* <Paper elevation={3} sx={{ p: 2, mb: 2, backgroundColor: '#f5f5f5' }}>
                {summaryLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <CircularProgress size={24} />
                        <Typography variant='subtitle2' sx={{ ml: 2 }}> AI is Summarizing PR...</Typography>
                    </Box>
                ) : (
                    <ReactMarkdown variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                        {summary}
                    </ReactMarkdown>
                )}
            </Paper> */}

            <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                <Grid item xs={4}>
                    <TextField
                        label="Start"
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="End"
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        size="small"
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" onClick={() => handleSearch()} size="small" fullWidth>
                        Search
                    </Button>
                </Grid>
            </Grid>

            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>

                    <List>
                        {paginatedNews.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <ListItem alignItems="flex-start">
                                    <ListItemText
                                        primary={
                                            <Link
                                                href={item.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                color="inherit"
                                                underline="hover"
                                            >
                                                <Typography variant="subtitle1" component="div">
                                                    {item.title}
                                                </Typography>
                                            </Link>
                                        }
                                        secondary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                    sx={{ display: 'inline' }}
                                                >
                                                    {item.site} -
                                                </Typography>
                                                {" " + new Date(item.publisheddate_ts).toLocaleDateString()}
                                            </>
                                        }
                                    />
                                </ListItem>
                                {index < paginatedNews.length - 1 && <Divider component="li" />}
                            </React.Fragment>
                        ))}
                    </List>
                    {news.length > itemsPerPage && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <Pagination
                                count={Math.ceil(news.length / itemsPerPage)}
                                page={page}
                                onChange={handlePageChange}
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

export default NewsSearch;