import React, { useState, useEffect } from 'react';
import { Avatar, IconButton, Menu, MenuItem } from '@mui/material';
import { useAuth } from '../AuthContext';
import { supabase } from '../supabaseClient';

const UserProfile = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [nickname, setNickname] = useState('');
    const { user, logout } = useAuth();

    useEffect(() => {
        if (user) {
            fetchUserProfile();
        }
    }, [user]);

    const fetchUserProfile = async () => {
        setNickname(user.email);
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logout();
        handleClose();
    };

    return (
        <>
            <IconButton
                size="small"
                onClick={handleClick}
                sx={{ marginRight: 1 }}
            >
                <Avatar sx={{ width: 32, height: 32 }}>
                    {nickname ? nickname[0].toUpperCase() : 'U'}
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    );
};

export default UserProfile;