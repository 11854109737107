import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Box, Typography, Chip, Divider, Paper, Popover, Skeleton } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useStock } from '../StockContext';
import { styled } from '@mui/material/styles';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const StyledTable = styled('table')({
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: '1rem',
    '& th, & td': {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left',
    },
    '& th': {
        backgroundColor: '#f2f2f2',
        fontWeight: 'bold',
    },
    '& tr:nth-of-type(even)': {
        backgroundColor: '#f9f9f9',
    },
    '& tr:hover': {
        backgroundColor: '#f5f5f5',
    },
});

const ReferenceSpan = styled('span')(({ theme, disabled }) => ({
    color: disabled ? theme.palette.text.disabled : theme.palette.primary.main,
    cursor: disabled ? 'default' : 'pointer',
    '&:hover': {
        textDecoration: disabled ? 'none' : 'underline',
    },
}));

const SourcePaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
    maxWidth: '400px',
    maxHeight: '300px',
    overflow: 'auto',
    boxShadow: theme.shadows[3],
}));

const AIAnswerComponent = ({ original_query, search_queries }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [isFirstResponse, setIsFirstResponse] = useState(true);
    const { showError, completeContent, setCompleteContent, sources, setSources, searchedQueryValue, content, setContent } = useStock();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openSourceId, setOpenSourceId] = useState(null);
    const [isStreamingComplete, setIsStreamingComplete] = useState(false);
    const abortControllerRef = useRef(null);


    const contentRef = useRef('');

    const appendContent = useCallback((newContent) => {
        contentRef.current += newContent;
        setContent(contentRef.current);
    }, []);

    const appendSource = useCallback((newSource) => {
        setSources(prevSources => [...prevSources, newSource]);
    }, []);

    useEffect(() => {
        fetchOverview();

        return () => {
            // 컴포넌트가 언마운트되거나 useEffect가 다시 실행될 때 fetch 요청 취소
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [original_query]);

    const uniqueSources = useMemo(() => {
        const uniqueLinks = new Set();
        return sources.filter(source => {
            if (!uniqueLinks.has(source.link)) {
                uniqueLinks.add(source.link);
                return true;
            }
            return false;
        });
    }, [sources]);


    useEffect(() => {
        if (!loading && content === completeContent) {
            setIsStreamingComplete(true);
        }
    }, [loading, content, completeContent]);

    const fetchOverview = async () => {
        setLoading(true);
        setSources([]);
        setContent('');
        contentRef.current = '';
        setIsFirstResponse(true);

        const currentLanguage = i18next.language;
        const url = `${serverUrl}/api/ai/summarize-search`;

        // 새 AbortController 생성
        abortControllerRef.current = new AbortController();
        const signal = abortControllerRef.current.signal;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    original_query: original_query,
                    search_queries: search_queries,
                    language: currentLanguage,
                }),
                signal, // fetch 요청에 signal 추가
            });

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            while (true) {
                const { done, value } = await reader.read();
                if (done) break;

                const chunk = decoder.decode(value, { stream: true });
                const lines = chunk.split('\n\n').filter(line => line.trim() !== '');

                for (const line of lines) {
                    const line_data = line.replace('data:', '');
                    try {
                        const data = JSON.parse(line_data);
                        if (data.type === 'references') {
                            appendSource(data.data);
                            console.log(data.data)
                            setIsFirstResponse(false);
                        } else if (data.type === 'content') {
                            appendContent(data.data);
                        }
                    } catch (error) {
                        console.error(error);
                        console.error(line_data);
                        showError(`Error parsing JSON: ${error.message}`);
                    }
                }
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
            } else {
                console.error(error);
                showError(`Failed to fetch data: ${error.message}`);
                appendContent(` (${error.message || 'Unknown error'})`);
            }
        } finally {
            setLoading(false);
            console.log(contentRef.current);
            setCompleteContent(contentRef.current);
        }
    }



    const handleSourceClick = (event, refNumber) => {
        if (!isStreamingComplete) return; // 스트리밍이 완료되지 않았으면 클릭 무시
        setAnchorEl(event.currentTarget);
        setOpenSourceId(openSourceId === refNumber ? null : refNumber);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
        setOpenSourceId(null);
    };

    const processContent = (content) => {
        if (typeof content !== 'string') {
            console.warn('Received non-string content:', content);
            return content;
        }

        const referenceRegex = /\[(\d+)\]([.,;:]?)(?=\s|$)/g;
        let lastIndex = 0;
        const elements = [];

        content.replace(referenceRegex, (match, refNumber, punctuation, index) => {
            if (lastIndex !== index) {
                elements.push(content.slice(lastIndex, index));
            }
            const num = parseInt(refNumber);
            if (num >= 0 && num <= 15) {
                const source = sources.find(s => s.chunk_id === num);
                elements.push(
                    <span key={index}>
                        <ReferenceSpan
                            key={index}
                            onClick={(e) => handleSourceClick(e, refNumber)}
                            disabled={!isStreamingComplete}
                        >
                            [{refNumber}]
                        </ReferenceSpan>
                        {punctuation}
                        <Popover
                            open={openSourceId === refNumber}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <SourcePaper>
                                <Typography variant="h6">
                                    <a href={source?.link} target="_blank" rel="noopener noreferrer">
                                        {source?.title}
                                    </a>
                                </Typography>
                                <Typography variant="body2">Date: {source?.date}</Typography>
                                <Typography variant="body2">Link: <a href={source?.link} target="_blank" rel="noopener noreferrer">{source?.link}</a></Typography>
                                <Typography variant="body1" style={{ marginTop: '10px' }}>{source?.content}</Typography>
                            </SourcePaper>
                        </Popover>
                    </span>
                );
            } else {
                elements.push(match);
            }
            lastIndex = index + match.length;
        });

        if (lastIndex < content.length) {
            elements.push(content.slice(lastIndex));
        }

        return elements;
    };

    const wrapTextWithReferences = (props) => {
        const { children } = props;
        if (typeof children === 'string') {
            return processContent(children);
        }
        if (Array.isArray(children)) {
            return children.map((child, index) =>
                typeof child === 'string' ? (
                    <React.Fragment key={index}>{processContent(child)}</React.Fragment>
                ) : (
                    child
                )
            );
        }
        return children;
    };

    const components = {
        table: StyledTable,
        p: (props) => <p>{wrapTextWithReferences(props)}</p>,
        li: (props) => <li>{wrapTextWithReferences(props)}</li>,
        td: (props) => <td>{wrapTextWithReferences(props)}</td>,
        th: (props) => <th>{wrapTextWithReferences(props)}</th>,
        h1: (props) => <h1>{wrapTextWithReferences(props)}</h1>,
        h2: (props) => <h2>{wrapTextWithReferences(props)}</h2>,
        h3: (props) => <h3>{wrapTextWithReferences(props)}</h3>,
        h4: (props) => <h4>{wrapTextWithReferences(props)}</h4>,
        h5: (props) => <h5>{wrapTextWithReferences(props)}</h5>,
        h6: (props) => <h6>{wrapTextWithReferences(props)}</h6>,
        strong: (props) => <strong>{wrapTextWithReferences(props)}</strong>,
        em: (props) => <em>{wrapTextWithReferences(props)}</em>,
        blockquote: (props) => <blockquote>{wrapTextWithReferences(props)}</blockquote>,
        code: (props) => <code>{wrapTextWithReferences(props)}</code>,
        // 필요한 다른 컴포넌트들도 여기에 추가할 수 있습니다.
    };

    const safeContent = content || '';


    const LoadingSkeleton = () => (
        <Box sx={{ width: '100%' }}>
            <Skeleton variant="text" width="60%" height={40} sx={{ mb: 2 }} />
            <Skeleton variant="rectangular" height={100} sx={{ mb: 2 }} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="70%" />
            <Skeleton variant="text" width="75%" />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
                {[1, 2, 3].map((_, index) => (
                    <Skeleton key={index} variant="rectangular" width={100} height={32} />
                ))}
            </Box>
        </Box>
    );

    return (
        <>
            {loading && isFirstResponse ? (
                <LoadingSkeleton />

            ) : (
                <>
                    <Box sx={{ mb: 2 }}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                            {uniqueSources.map((source, index) => (
                                <Chip
                                    key={index}
                                    label={source.title.slice(0, 40) + '...'}
                                    component="a"
                                    href={source.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    clickable
                                    color="primary"
                                    size="small"
                                    icon={<LaunchIcon fontSize="small" />}
                                    sx={{ m: 0.5 }}
                                />
                            ))}
                        </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    <Box sx={{ mb: 3 }}>
                        <Box sx={{ pl: 2 }}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} components={components}>
                                {safeContent}
                            </ReactMarkdown>
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};


export default React.memo(AIAnswerComponent);